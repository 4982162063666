import checkIcon from "../../../litigation-financing/assets/create-case/check.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputAdornment,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  CIN_LENGTH,
  GSTIN_LENGTH,
  LLP_LENGTH,
  PAN_LENGTH,
  PIN_CODE_LENGTH,
  ZIP_CODE_LENGTH,
} from "../../../litigation-financing/lib/constants/create-case";
import { INPUT_FIELDS_NAME } from "../../../litigation-financing/lib/constants/validation";
import { validateInput } from "../../../common/utils/validator/ValidationUtil";
import { PATTERNS } from "../../../common/Constants";

const QSInputBox = ({
  color,
  height = "40px",
  label,
  type = "text",
  setValue,
  value,
  helperText,
  placeholder,
  id,
  disable = false,
  required = false,
  maxLength,
  apiLoader = false,
  error,
  showRequired = true,
  showCheckIcon = true,
  disableFuture = true,
  disablePast = false,
  onKeyDown,
}) => {
  const [iconShow, setIconShow] = useState(false);
  const [isMobvalid, setIsMobvalid] = useState(true);
  const [active, setActive] = useState(false);
  const mobile = useRef();
  const [num, setNum] = useState();
  const setValues = (e, regEx) => {
    if (regEx) {
      if (
        e.target.name === INPUT_FIELDS_NAME.mobile_number ||
        e.target.name === INPUT_FIELDS_NAME.aadhar ||
        e.target.name === INPUT_FIELDS_NAME.pincode
      ) {
        const regex = /^[0-9\b]+$/;
        if (regex.test(e.target.value)) {
          if (e.target.name === INPUT_FIELDS_NAME.pincode) {
            setNum(e.target.value);
          } else {
            setNum(e.target.value);
            setIsMobvalid(validateInput(e.target.value, regEx));
            setIconShow(validateInput(e.target.value, regEx));
            setActive(false);
          }
        } else {
          setNum("");
        }
      } else if (e.target.name === INPUT_FIELDS_NAME.zipcode) {
        if (validateInput(e.target.value, regEx)) {
          setNum(e.target.value);
        } else {
          if (num.length > 1) {
            setNum(num);
          } else {
            setNum("");
          }
        }
      } else {
        setIsMobvalid(validateInput(e.target.value, regEx));
        setIconShow(validateInput(e.target.value, regEx));
        setActive(false);
      }
    }
    if (
      id === INPUT_FIELDS_NAME.address ||
      id === INPUT_FIELDS_NAME.firm_name ||
      id === INPUT_FIELDS_NAME.city ||
      id === INPUT_FIELDS_NAME.state ||
      id === INPUT_FIELDS_NAME.pan_number
    ) {
      setNum(e.target.value);
    }
    if (e.target.name === "claim_value") {
      setNum(e.target.value);
    } else {
      if (e.target.value !== "") {
        setIsMobvalid(true);
        setActive(false);
      } else {
        setActive(false);
        setIconShow(false);
      }
    }
  };
  const handleChange = (e) => {
    if (type === INPUT_FIELDS_NAME.email) {
      setValues(e, PATTERNS.EMAIL);
    } else if (e.target.name === INPUT_FIELDS_NAME.mobile_number) {
      setValues(e, PATTERNS.MOBILE);
    } else if (e.target.name === INPUT_FIELDS_NAME.aadhar) {
      setValues(e, PATTERNS.AADHAAR);
    } else if (e.target.name === INPUT_FIELDS_NAME.pincode) {
      setValues(e, PATTERNS.PINCODE);
    } else if (e.target.name === INPUT_FIELDS_NAME.zipcode) {
      setValues(e, PATTERNS.ZIPCODE);
    } else {
      setValues(e);
    }
  };
  useEffect(() => {
    if (value) {
      if (
        id === INPUT_FIELDS_NAME.cin ||
        id === INPUT_FIELDS_NAME.pan_number ||
        id === INPUT_FIELDS_NAME.gstin ||
        id === INPUT_FIELDS_NAME.claim_value ||
        id === INPUT_FIELDS_NAME.address ||
        id === INPUT_FIELDS_NAME.firm_name ||
        id === INPUT_FIELDS_NAME.city ||
        id === INPUT_FIELDS_NAME.state ||
        id === INPUT_FIELDS_NAME.pincode
      ) {
        if (error) {
          setIsMobvalid(true);
          setIconShow(false);
        } else if (id === INPUT_FIELDS_NAME.claim_value) {
          setNum(value);
        } else if (id === INPUT_FIELDS_NAME.cin) {
          if (value.length === LLP_LENGTH || value.length === CIN_LENGTH) {
            setIsMobvalid(true);
            setIconShow(true);
          } else {
            setIsMobvalid(false);
            setIconShow(false);
          }
        } else if (id === INPUT_FIELDS_NAME.pan_number) {
          if (value.length === PAN_LENGTH) {
            setNum(value);
            setIsMobvalid(true);
            // setIconShow(true);
          } else {
            setIsMobvalid(false);
            setIconShow(false);
          }
        } else if (id === INPUT_FIELDS_NAME.gstin) {
          if (value.length === GSTIN_LENGTH) {
            setIsMobvalid(true);
            setIconShow(true);
          } else {
            setIsMobvalid(false);
            setIconShow(false);
          }
        } else if (id === INPUT_FIELDS_NAME.pincode) {
          if (value.length === PIN_CODE_LENGTH) {
            setIsMobvalid(true);
            setIconShow(true);
          }
        } else if (
          id === INPUT_FIELDS_NAME.address ||
          id === INPUT_FIELDS_NAME.firm_name ||
          id === INPUT_FIELDS_NAME.city ||
          id === INPUT_FIELDS_NAME.state
        ) {
          if (showCheckIcon) {
            setNum(value);
            setIsMobvalid(true);
            setIconShow(true);
          } else {
            setNum(value);
          }
        }
      }
    } else {
      if (
        id === INPUT_FIELDS_NAME.address ||
        id === INPUT_FIELDS_NAME.firm_name ||
        id === INPUT_FIELDS_NAME.city ||
        id === INPUT_FIELDS_NAME.state ||
        id === INPUT_FIELDS_NAME.pan_number
      ) {
        setIconShow(false);
        setNum("");
      } else {
        setIconShow(false);
      }
    }
  }, [value]);
  useEffect(() => {
    if (helperText) {
      setIsMobvalid(false);
    }
  }, [helperText]);
  return (
    <Grid
      sx={{
        width: "-webkit-fill-available",
        minHeight: "78px",
        maxHeight: "78px",
        // height: "120px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {iconShow ? (
          <FormLabel
            sx={{
              textAlign: "left",
              color: isMobvalid && showCheckIcon && iconShow && "#32936F",
              fontSize: "14px",
              mb: 1,
            }}
            htmlFor={id}
          >
            {required && showRequired ? <>{label}*</> : <>{label}</>}
          </FormLabel>
        ) : (
          <FormLabel
            sx={{
              textAlign: "left",
              color: helperText ? "#d32f2f" : color ? "#fff" : "#151515",
              fontSize: "14px",
              mb: 1,
            }}
            htmlFor={id}
          >
            {required && showRequired ? <>{label}*</> : <>{label}</>}
          </FormLabel>
        )}

        {type === "date" ? (
          <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <DatePicker
                // label={label}
                openTo="day"
                variant="outlined"
                size="small"
                placeholder={placeholder}
                value={value ? value : null}
                disableFuture={disableFuture}
                disablePast={disablePast}
                disabled={disable}
                onChange={setValue}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    style={{
                      background: disable ? "#F2F4F5" : "#FFFFFF",
                    }}
                    autoComplete="new-password"
                    name={id}
                    required
                    {...params}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,

                        borderColor: helperText ? "red" : "#323232",
                      },

                      "& .MuiFormHelperText-root.Mui-error": {
                        m: 0,
                        backgroundColor: color ? "#003B41" : "#fff", // Ensure background is transparent
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </LocalizationProvider>
        ) : (
          <>
            <TextField
              autoComplete="new-password"
              //   label={label}
              name={id}
              // variant="outlined"
              size="small"
              InputLabelProps={value && { shrink: value }}
              type={type}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: 2,
                  borderColor: "#323232",
                },

                "& .MuiFormHelperText-root.Mui-error": {
                  m: 0,
                  backgroundColor: color ? "#003B41" : "#fff", // Ensure background is transparent
                },
                "& .MuiInputBase-root": {
                  // width: "215px",
                  height: { height },
                },
              }}
              style={{
                // marginTop: "0.5rem",
                marginBottom: "0.5rem",
                background: disable ? "#F2F4F5" : "#FFFFFF",
                borderRadius: "8px",
              }}
              defaultValue={value}
              value={num}
              inputRef={mobile}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {" "}
                    {!apiLoader &&
                      !error &&
                      isMobvalid &&
                      iconShow &&
                      showCheckIcon && <img src={checkIcon} alt="" />}
                    {apiLoader && showCheckIcon && (
                      <CircularProgress size={25} sx={{ color: "blue" }} />
                    )}
                    {error && showCheckIcon && !apiLoader && (
                      <CancelIcon size={25} sx={{ color: "#F57E77" }} />
                    )}
                  </InputAdornment>
                ),
                onKeyDown: onKeyDown,
              }}
              error={!isMobvalid || error}
              color={
                isMobvalid && iconShow && showCheckIcon ? "success" : "primary"
              }
              onBlur={setValue}
              onChange={(e) => handleChange(e)}
              helperText={
                (!isMobvalid || error) && helperText && `${helperText}`
              }
              disabled={disable}
              required={required}
              inputProps={maxLength ? maxLength : { value: num }}
              placeholder={placeholder}
            />
          </>
        )}
        {type === "date" && (
          <Typography
            sx={{ color: "#d32f2f", fontSize: "0.75rem", textAlign: "left" }}
          >
            {helperText}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};
export default QSInputBox;
