import React, { useContext, createContext, useState } from "react";
import axios from "axios";
import { apiErrorToast } from "../common/Toasts";
import { addDays } from "date-fns";

const CommonContext = createContext();
export const CommonContextProvider = ({ children }) => {
  const [invNumber, setInvNumber] = useState();
  const [invPrefix, setInvPrefix] = useState();
  const [fixedInvFields, setFixedInvFields] = useState({
    isDueDateVisible: true,
    isSignatureVisible: true,
    isTncVisible: true,
    isNotesVisible: true,
  });
  const [invoiceFields, setInvoiceFields] = useState([]);
  const [invDraft, setInvDraft] = useState({});
  const [content, setContent] = useState("");
  const [note, setNote] = useState([]);
  const [noteEditor, setNoteEditor] = useState();
  const [openNote, setOpenNote] = useState(false);
  // get note
  const getNotesData = async (url) => {
    try {
      const resp = await axios(url);
      setNote(resp.data.results);
    } catch (error) {
      apiErrorToast(error);
    }
  };
  //
  const [selectedFile, setSelectedFile] = useState();
  //anjali's hooks
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  //
  const [toggledropdown, setToggleDropdown] = useState(false);

  // Ankur
  const [prefData, setPrefData] = useState();
  return (
    <CommonContext.Provider
      value={{
        content,
        setContent,
        note,
        getNotesData,
        setNote,
        invNumber,
        invPrefix,
        fixedInvFields,
        invoiceFields,
        setInvNumber,
        setInvPrefix,
        setFixedInvFields,
        setInvoiceFields,
        invDraft,
        setInvDraft,
        // new image file url avinash
        selectedFile,
        setSelectedFile,
        //
        date,
        setDate,
        // ankur
        prefData,
        setPrefData,
        //
        setToggleDropdown,
        toggledropdown,
        setNoteEditor,
        noteEditor,
        openNote,
        setOpenNote,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

const useCommonContext = () => {
  return useContext(CommonContext);
};

export default useCommonContext;
