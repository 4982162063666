export const textStyle12px = {
  fontSize: "12px",
  color: "rgba(0, 0, 0, 0.6)",
};

export const textStyle14px = {
  fontSize: "14px",
  fontWeight: 500,
  color: "rgba(0, 0, 0, 0.4)",
};

export const bold600 = {
  fontSize: "20px",
  color: "#151D48",
  fontWeight: 600,
};

export const bold700 = {
  fontSize: "20px",
  color: "#151D48",
  fontWeight: 700,
};

export const bold500 = {
  fontSize: "16px",
  color: "#71758F",
  fontWeight: 500,
};
export const AnalyticLegendFont12 = {
  fontSize: "12px",
  fontWeight: 400,
  color: "#4F4F4F",
  textAlign: "left",
};

export const TableHeader = {
  fontSize: "12px",
  fontWeight: 600,
  color: "#4F5E74",
};

export const NdaText16 = {
  fontSize: "16px",
  fontWeight: "600",
  color: "#151D48",
};

export const NdaBoxText = {
  fontSize: "14px",
  fontWeight: 700,
  color: "#34ABF4",
};

export const NdaSelectedBox = {
  width: "46%",
  height: "50px",
  background: "rgba(52, 171, 244, 0.2)",
  border: "1px solid #34ABF3",
  borderRadius: 2.5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

export const NdaBox = {
  width: "46%",
  height: "50px",
  border: "1px solid #34ABF3",
  borderRadius: 2.5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    background: "rgba(52, 171, 244, 0.2)",
  },
};

export const draftTableHeader = {
  fontSize: "16px",
  fontWeight: 700,
  width: "300px",
  color: "#4F5E74",
};

export const QSdraftTableHeader = {
  fontSize: "16px",
  fontWeight: 700,
  width: "300px",
  color: "#003B41",
  background: "#F6F6F6",
};

export const draftTableRow = {
  fontSize: "16px",
  fontWeight: 600,
  color: "#202224",
};
export const styleLinkColor = {
  color: "rgba(52, 171, 244, 1)",
  textDecoration: "none",
  width: "90%",
  margin: "auto",
  textAlign: "center",
};
export const ButtonStyle = {
  padding: "10px",
  border: " 1px solid #C3D3E2",
  color: "#0F3659",
  height: "40px",
  fontSize: "14px",
  fontWeight: 600,
  borderRadius: "8px",
  cursor: "pointer",
  textDecoration: "none",
};
