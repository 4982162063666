import { Box, Button, Grid, Typography } from "@mui/material";
import { bold600 } from "../../../litigation-financing/lib/constants/style";
import { currency } from "../../../common/Constants";
import { useEffect, useState } from "react";

import { CClient } from "../../../litigation-financing/lib/common/common";
import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import { apiErrorToast } from "../../../common/Toasts";
import BankIcon from "../../../litigation-financing/assets/cards/bank.svg";

export const NBFCAddBank = () => {
  const [addBank, setAddBank] = useState(false);
  const [loader, setLoader] = useState(false);
  const [bankDetail, setBankDetail] = useState();

  //   const getBankDetail = async () => {
  //     setLoader(true);
  //     try {
  //       const res = await CClient(`${BusinessApiEndPoint.QS_GET_BANK_DATA}`);
  //       if (res.ok) {
  //         const data = await res.json();
  //         setLoader(false);
  //         setBankDetail(data.results[0]);
  //       } else {
  //         setLoader(false);
  //         const data = await res.json();
  //         apiErrorToast(data.message);
  //       }
  //     } catch (error) {
  //       setLoader(false);
  //       console.error(error);
  //     }
  //   };

  //   useEffect(() => {
  //     getBankDetail();
  //     return () => {};
  //   }, []);
  return (
    <Grid
      sx={{
        p: 3,
        minHeight: "225px",
        height: "auto",
        display: "flex",

        textAlign: "left",
        // width: { xs: "100%", sm: "49%", lg: "20%" },
        minWidth: { xs: "100%", md: "280px" },
        borderRadius: 2.5,
        position: "relative",
        flexGrow: 2,
        background: "blur",
        justifyContent: "center",

        mx: "auto",
      }}
      className="shadow-sm"
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <img
          src={require("../../assets/common/working.svg").default}
          width={"150px"}
        />
        <Typography sx={bold600}>Coming soon</Typography>
      </Box>
    </Grid>
  );
};
