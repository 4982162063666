import { BASE_URL } from "../../../networks/ApiEndPoints";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Typography } from "@mui/material";
/**
 * Wrapper around fetch to make api calls
 * @param {string} url
 * @param {RequestInit} options
 * @returns {Promise} returns fetch with default options and url set
 */
export const CClient = async (url, options = {}) => {
  const endpoint = BASE_URL;
  const token = localStorage.getItem("baccess_token");
  if (!options?.headers?.["Authorization"] && token) {
    options.headers = { ...options.headers, Authorization: `Bearer ${token}` };
  }
  const finalUrl = url.startsWith("http") ? url : endpoint + url;

  return fetch(finalUrl, options);
};

export const minValueSet = (data, value, style) => {
  return data ? (
    <>
      {data.length > value + 3 ? (
        <>
          <Tooltip title={data} placement="top" arrow>
            <Typography sx={style}> {data.slice(0, value)}...</Typography>
          </Tooltip>
        </>
      ) : (
        data
      )}
    </>
  ) : (
    "-"
  );
};
