import { Box, Button, Grid, Typography } from "@mui/material";
import { bold600 } from "../../../litigation-financing/lib/constants/style";
import { currency } from "../../../common/Constants";
import { useEffect, useState } from "react";
import { QSAddBankAccount } from "./merchant-add-bank-account-modal";
import { CClient } from "../../../litigation-financing/lib/common/common";
import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import { apiErrorToast } from "../../../common/Toasts";
import BankIcon from "../../../litigation-financing/assets/cards/bank.svg";

export const MerchantAddBank = ({ bankDetail, setBankDetail }) => {
  const [addBank, setAddBank] = useState(false);
  const [loader, setLoader] = useState(false);

  const getBankDetail = async () => {
    setLoader(true);
    try {
      const res = await CClient(`${BusinessApiEndPoint.QS_GET_BANK_DATA}`);
      if (res.ok) {
        const data = await res.json();
        setLoader(false);
        setBankDetail(data.results[0]);
      } else {
        setLoader(false);
        const data = await res.json();
        apiErrorToast(data.message);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getBankDetail();
    return () => {};
  }, []);
  return (
    <Grid
      sx={{
        p: 3,
        minHeight: "178px",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        minWidth: { xs: "100%", md: "280px" },
        borderRadius: 2.5,
        justifyContent: "space-between",
        position: "relative",
        flexGrow: 2,
      }}
      className="shadow-sm"
    >
      <Typography sx={bold600}>Bank Account</Typography>

      {bankDetail ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              width: { xs: "100%", lg: "252px" },
              height: "90px",
              background: "#FAFAFA",
              borderRadius: 2.5,
              display: "flex",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "50px",
              }}
            >
              <Typography
                sx={{
                  color: "#1E2029",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {bankDetail?.bank_name}
              </Typography>
              <Typography
                sx={{
                  color: "#1E2029",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                **** **** ****{" "}
                {bankDetail?.acc_no?.substring(bankDetail?.acc_no.length - 4)}
              </Typography>
            </Box>

            <img src={BankIcon} alt="" />
          </Box>
        </Box>
      ) : (
        <>
          <Typography sx={{ opacity: 0.6, fontSize: "14px", color: "#003B41" }}>
            Setup your bank account to start receiving payments
          </Typography>
          <Button
            variant="outlined"
            sx={{
              width: "160px",
              height: "40px",
              //   background: "#17C0AF",
              fontSize: "14px",
              fontWeight: 600,
              color: "#17C0AF",
              borderRadius: 2.5,
              "&.MuiButton-outlined": {
                border: "2px solid #17C0AF",
                color: "#17C0AF",
              },
            }}
            onClick={() => setAddBank(true)}
            // disabled={data?.is_requested || disable}
          >
            Add Account
          </Button>
          <img
            src={require("../../assets/merchants/bank-account.svg").default}
            alt="Bank"
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
            }}
          />
        </>
      )}
      {addBank && <QSAddBankAccount open={addBank} setOpen={setAddBank} />}
    </Grid>
  );
};
