import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { patchJsonData, postJsonData } from "../networks/ApiController";
import ApiEndpoints from "../networks/ApiEndPoints";
import { useNavigate } from "react-router-dom";
// import {
//   DEMO_BASE_URL,
//   PROD_BASE_URL,
// } from "../litigation-financing/lib/constants/nda";

export const Toast = Swal.mixin({
  showClass: {
    backdrop: "swal12-noanimation",
    popup: "",
    icon: "",
  },
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
  width: "max-content",
  // background: "#4caf50",
  background: "#fefefe",
  color: "#169816",
  iconColor: "#2fa92f",
});
export const ErrorToast = Swal.mixin({
  showClass: {
    backdrop: "swal12-noanimation",
    popup: "",
    icon: "",
  },
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
  width: "max-content",
  background: "#fefefe",
  color: "#b61717",
  iconColor: "#f24f4f",
});
export const NormalToast = Swal.mixin({
  confirmButtonColor: "#3578EA",
});
export const MySwal = withReactContent(Toast);
export const ErrorSwal = withReactContent(ErrorToast);

// type = 'success' | 'error' | 'warning' | 'info' | 'question'
export const okToast = (title, msg, type) => {
  MySwal.fire(title, msg, type);
};

export const okErrorToast = (title, msg) => {
  ErrorToast.fire(title, msg, "error");
};

export const apiErrorToast = (error, history) => {
  var msg;
  var status =
    error && error.response && error.response.status && error.response.status;
  if (error) {
    if (error.data) {
      error.response = error;
    }
    if (error.response) {
      status = error.response.status;
      if (error.response.data) {
        if (error.response.data.message) {
          if (typeof error.response.data.message === "string") {
            msg = error.response.data.message;
          } else {
            const msgObj = error.response.data.message;
            msg = "";
            for (let i in msgObj) {
              msg += msgObj[i] + "\n";
            }
          }
        } else if (error.response.data.detail) {
          if (typeof error.response.data.detail === "string") {
            msg = error.response.data.detail;
          } else {
            const msgObj = error.response.data.detail;
            msg = "";
            for (let i in msgObj) {
              msg += msgObj[i] + "\n";
            }
          }
        } else if (typeof error.response.data === "object") {
          msg = JSON.stringify(error.response.data);
        } else {
          msg = error.response.data;
        }
      } else {
        msg = JSON.stringify(error.response);
        // msg = "Something went wrong, Please try after sometime";
      }
    } else {
      if (error.message) {
        msg = error.message;
      } else {
        // msg = JSON.stringify(error);
        msg = error;
        // msg = "Something went wrong, Please try after sometime";
      }
    }
  }
  if (status === 401) {
    ErrorSwal.fire({
      title: history ? "Login Required!!" : "Error!",
      text: msg,
      icon: "error", // 'success' | 'error' | 'warning' | 'info' | 'question'
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Login",
      showConfirmButton: history,
      showLoaderOnConfirm: history,
      preConfirm: () => {},
      allowOutsideClick: () => !Swal.isLoading(),
      // backdrop: true,
    }).then((result) => {
      localStorage.clear();
      const location = window.location;
      let baseUrl = location.protocol + "//" + location.host;
      window.open(baseUrl, "_self");
    });
  }
  if (status === 500) {
    ErrorSwal.fire("", "Something went wrong", "error");
  }
  if (status === 404 || status === 406) {
    ErrorSwal.fire("", msg ? msg : "Something went wrong", "error");
  } else {
    ErrorSwal.fire(
      "",
      msg ? msg : `Error can't be identified ${status}`,
      "error"
    );
  }
  return msg;
};

export const okSuccessToast = (title, msg) => {
  Toast.fire(title, msg, "success");
};

export const showCopyDialog = (title, data) => {
  MySwal.fire({
    title: title,
    text: data,
    icon: "success", // 'success' | 'error' | 'warning' | 'info' | 'question'
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Copy",
    showLoaderOnConfirm: true,
    preConfirm: () => {},
    allowOutsideClick: () => !Swal.isLoading(),
    backdrop: true,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: `${title} copied successfully`,
      });
    }
  });
};

export const toastWithTimer = (
  msg,
  timer,
  title = "Details Updated Successfully."
) => {
  let timerInterval;
  Swal.fire({
    title: `<div class="success-color">${title}</div>`,
    // html: "I will close in <b></b> milliseconds.",
    html: msg,
    timer: timer,
    timerProgressBar: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
      // const content = Swal.getHtmlContainer();
      // const $ = content.querySelector.bind(content);
      Swal.showLoading();
      const b = Swal.getHtmlContainer().querySelector("strong");
      timerInterval = setInterval(() => {
        b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
    }
  });
};
export const completeKycPopup = (msg, timer, title = "Congratulations ") => {
  let timerInterval;
  Swal.fire({
    title: `<div class="success-color">${title}</div>`,
    // html: "I will close in <b></b> milliseconds.",
    html: msg,
    timer: timer,
    timerProgressBar: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
      // const content = Swal.getHtmlContainer();
      // const $ = content.querySelector.bind(content);
      Swal.showLoading();
      const b = Swal.getHtmlContainer().querySelector("strong");
      timerInterval = setInterval(() => {
        b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
    }
  });
};
export const toastInvoicePopup = (msg, timer) => {
  let timerInterval;
  Swal.fire({
    title: '<div class="green-color">Please LogIn/SignUp to view Invoice</div>',
    // html: "I will close in <b></b> milliseconds.",
    html: msg,
    timer: timer,
    timerProgressBar: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
      const content = Swal.getHtmlContainer();
      const $ = content.querySelector.bind(content);
      Swal.showLoading();
      const b = Swal.getHtmlContainer().querySelector("strong");
      timerInterval = setInterval(() => {
        b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0) + " seconds";
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
    }
  });
};
export const toastKycPopup = (msg, timer, onTimerOver = () => {}) => {
  let timerInterval;
  Swal.fire({
    title: '<div class="green-color">Please Complete your KYC</div>',
    // html: "I will close in <b></b> milliseconds.",
    html: msg,
    timer: timer,
    showCloseButton: true,
    timerProgressBar: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
      const content = Swal.getHtmlContainer();
      const $ = content.querySelector.bind(content);
      Swal.showLoading();
      const b = Swal.getHtmlContainer().querySelector("strong");
      timerInterval = setInterval(() => {
        b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0) + " seconds";
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      onTimerOver();
    }
  });
};

export const SignPreviewPopup = (pdf, invoiceId, mdrSigned) => {
  // const navigate = useNavigate();
  {
    mdrSigned
      ? Swal.fire({
          title: "MSA Agreement",
          html: pdf,
          width: "80%",
          height: "80%",
          showCancelButton: false,
          confirmButtonText: "Close",
          confirmButtonColor: "#d33",
        })
      : Swal.fire({
          title: "MSA Agreement",
          html: pdf,
          width: "80%",
          height: "80%",
          // icon: "question",
          showCancelButton: true,
          confirmButtonText: "Accept",
          confirmButtonColor: "#1e845a",
          cancelButtonText: "Decline",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed === true) {
            // Make the API call when the user clicks the "accept" button
            const data = {
              inv_id: invoiceId,
            };
            postJsonData(
              ApiEndpoints.INVOICES_ESIGN,
              data,
              (res) => {
                const data = {
                  invoice: invoiceId,
                  type: "PUBLIC",
                  visible: "TRUE",
                };
                postJsonData(
                  ApiEndpoints.DOC_SHARE,
                  data,
                  // setLinkRequest,
                  (res) => {
                    postJsonData(
                      ApiEndpoints.DOC_SHARE + `${invoiceId}/sendEmailSms/`,
                      data,
                      // setMailRequest,
                      (res) => {
                        okSuccessToast("Mail / SMS sent successfully!");
                        // navigate("/dashboard/invoics");
                        // window.location.reload();
                      },
                      (error) => {
                        apiErrorToast(error);
                      }
                    );
                  },
                  (error) => {
                    apiErrorToast(error);
                  }
                );
                // okSuccessToast("sign successfully!");
                // navigate("/dashboard/invoices");
              },
              (error) => {
                apiErrorToast(error);
              }
            );
          }
        });
  }
};

export const saveForm = (title) => {
  const baseUrl = window.location.origin;
  Swal.fire({
    title: title,
    showCancelButton: true,
    confirmButtonText: "Save",
    customClass: {
      title: "title-class",
      confirmButton: "confirm-button",
      cancelButton: "cancel-button",
    },
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.location.href = `${baseUrl}/business/lf/dashboard/overview`;
    }
  });
};
// export const EsignPopup = (msg, res, invDraft, setInvoiceRequest) => {
//   Swal.fire({
//     title: "Merchant services Agreement",
//     html: msg,
//     showCancelButton: true,
//     confirmButtonText: "E-Sign",
//     confirmButtonColor: "#1e845a",
//     cancelButtonText: "Close",
//     reverseButtons: true,
//     reverseButtons: true,
//   }).then((result) => {
//     if (result.isConfirmed === true) {
//       // Make the API call when the user clicks the "accept" button
//       const options = {
//         environment:
//           process.env.REACT_APP_BASE_URL === PROD_BASE_URL
//             ? "production"
//             : "sandbox",
//         callback: function (t) {
//           if (t.hasOwnProperty("error_code")) {
//             console.error("error");
//           } else {
//             console.error("success");
//             postJsonData(
//               ApiEndpoints.INVOICES,
//               invDraft && invDraft,
//               setInvoiceRequest,
//               (res) => {
//                 const data = {
//                   invoice: res?.data?.id,
//                   type: "PUBLIC",
//                   visible: "TRUE",
//                 };
//                 postJsonData(
//                   ApiEndpoints.DOC_SHARE,
//                   data,
//                   false,
//                   (res) => {
//                     postJsonData(
//                       ApiEndpoints.DOC_SHARE + `${res?.data?.id}/sendEmailSms/`,
//                       data,
//                       false,
//                       (res) => {
//                         okSuccessToast("Mail / SMS sent successfully!");
//                         // navigate("/dashboard/invoices");
//                       },
//                       (error) => {
//                         apiErrorToast(error);
//                       }
//                     );
//                   },
//                   (error) => {
//                     apiErrorToast(error);
//                   }
//                 );
//               },
//               (error) => {
//                 apiErrorToast(error);
//               }
//             );
//           }
//         },
//         // "is_redirection_approach": false,
//         logo: "https://legalpay.in/wp-content/uploads/2021/05/cropped-legalpay-logo.png",
//         // "is_iframe":false,
//         // "redirect_url": "https://www.google.com/",
//         theme: {
//           primaryColor: "#27074E",
//           secondaryColor: "#fff",
//         },
//       };

//       // const triggerDigioSDK = () => {
//       const DigioInit = new window.Digio(options);
//       DigioInit.init();
//       DigioInit.submit(res?.id, res?.identifier, res?.access_token);
//     }
//   });
// };
export const CreditSuccess = (msg) => {
  Swal.fire({
    title: "Congratulations",
    html: msg,
    confirmButtonText: "Done",
    confirmButtonColor: "#1e845a",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed === true) {
      // Make the API call when the user clicks the "accept" button
      window.location.reload();
    }
  });
};

export const DraftCase = () => {
  const baseUrl = window.location.origin;

  Swal.fire({
    icon: "error",
    title: "Oops...",
    confirmButtonText: "Ok",
    allowOutsideClick: false,
    text: "You have exceeed limit to create draft case, please delete an existing draft case to create more.",
    customClass: {
      title: "title-class",
      confirmButton: "confirm-button",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = `${baseUrl}/business/lf/dashboard/draft-cases`;
    }
  });
};

export const backFromForm = () => {
  const baseUrl = window.location.origin;
  Swal.fire({
    title: "Are you sure you want to exit?",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    customClass: {
      title: "title-class",
      confirmButton: "confirm-button",
      cancelButton: "cancel-button",
    },
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.location.href = `${baseUrl}/business/lf/dashboard/draft-cases`;
    }
  });
};

export const catchErrorToast = () => {
  console.error("Something went wrong");
  // Swal.fire({
  //   icon: "error",
  //   title: "Oops...",
  //   text: "Something went wrong Please try again later.",
  // });
};
