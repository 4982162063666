import { Button, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Spinner from "../../../common/Spinner";
import AuthContext from "../../../store/auth-context";
import { MerchantPayment } from "../merchant/merchant-payment";
import { MerchantAddBank } from "../merchant/merchant-add-bank";
import { MerchantUnsettled } from "../merchant/merchant-unsettled";
import { MerchantAddClient } from "../merchant/merchange-add-client";
import InvoicesQS from "../dashboard/invoices";
import InvoiceModal from "../dashboard/invoice/invoice-modal";
import { apiErrorToast } from "../../../common/Toasts";
import { CClient } from "../../../litigation-financing/lib/common/common";
import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import { NBFCPayment } from "../nbfc/nbfc-payment";
import { NBFCUnsettled } from "../nbfc/nbfc-unsettled";
import { NBFCAddBank } from "../nbfc/nbfc-bank-account";
import ClientNbfc from "../nbfc/nbfc-client-table";

const NBFCDashboard = () => {
  const authCtx = useContext(AuthContext);
  const user = authCtx.buser;
  const [loader, setLoader] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [paymentDeta, setPaymentData] = useState();
  const fetchData = async () => {
    setLoader(true);
    try {
      const res = await CClient(`${BusinessApiEndPoint.NBFC_GET_CREDIT_LINE}`);
      if (res.ok) {
        const data = await res.json();
        setLoader(false);

        setPaymentData(data);
      } else {
        setLoader(false);
        const data = await res.json();
        apiErrorToast(data.message);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);
  return (
    <Grid
      container
      style={{ display: "flex", marginTop: "48px", height: "85vh" }}
      md={12}
    >
      {loader && <Spinner loading={loader} circleBrown />}
      <Grid
        sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 3 }}
      >
        <Grid
          sx={{
            px: 4,
            width: "100%",
            display: "flex",
            justifyContent: { md: "space-between" },
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "start" },
            maxHeight: "content-fit",
          }}
        >
          <Typography className="fw-bold" sx={{ fontSize: "24px" }}>
            {" "}
            Welcome,{" "}
            {user?.firstName?.charAt(0).toUpperCase() +
              user?.firstName?.slice(1)}
          </Typography>
        </Grid>
        <Grid
          sx={{
            px: 4,
            width: "100%",
            display: "flex",
            flexWrap: { xs: "wrap", xl: "no-wrap" },
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "start" },
            gap: 3,
          }}
        >
          <NBFCPayment data={paymentDeta} />
          <NBFCUnsettled data={paymentDeta} />
          <NBFCAddBank />
          {/* <MerchantAddClient /> */}
        </Grid>
        <ClientNbfc />
      </Grid>
    </Grid>
  );
};
export default NBFCDashboard;
