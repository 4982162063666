import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Modal,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import crossIcon from "../../../litigation-financing/assets/create-case/cross.svg";
import deleteIcon from "../../../litigation-financing/assets/create-case/delete.svg";
import uploadIcon from "../../assets/create-case/upload.svg";
import { useForm } from "react-hook-form";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  fontFamily: "Poppins",
  p: 2,
  height: "500px",
  // height: { xs: "60vh", md: "72vh" },
  overflowY: "scroll",
};

const QSUploadCaseDocument = ({
  docFormates = ".pdf, .jpeg .png .jpg",
  width = "340px",
  height = "60px",
  multiple = false,
  title = "",
  highlightedTitle = "",
  document,
  setDocument,
  data,
  loader,
  disable,
  show = false,
  error,
}) => {
  const { register } = useForm();
  const navigate = useNavigate();
  const [uploadFile, setUploadFile] = useState(false);
  const [loading, setloading] = useState(true);
  // const [document, setDocument] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const handleImage = async (event) => {
    setUploadFile(true);
    setloading(true);
    const filePathsPromises = [];
    for (let i = 0; i < event.target.files.length; i++) {
      filePathsPromises.push(toBase64(event.target.files[i]));
    }
    const filePaths = await Promise.all(filePathsPromises);
    setDocument(filePaths);
  };

  const toBase64 = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          document_url: reader.result,
          document_name: name,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  return (
    <Grid sx={{ borderRadius: 2.5, mt: 2, minHeight: "auto" }}>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          {!disable && (
            <Box
              sx={{
                height: height,
                position: "relative",
              }}
            >
              {" "}
              <input
                type="file"
                id="front_aadhar"
                {...register("file")}
                onChange={(e) => {
                  handleImage(e);
                }}
                accept=".pdf"
                style={{
                  position: "absolute",
                  width: "inherit",
                  height: "inherit",
                  opacity: 0,
                  cursor: "pointer",
                  left: "0px",
                  top: "0px",
                  zIndex: 10,
                }}
              />
              <span>
                <Box
                  sx={{
                    p: 2,
                    background: "rgba(117, 21, 30, 0.03)",
                    borderRadius: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => disable && window.open(data[0].document_url)}
                >
                  <Box
                    sx={{
                      height: height,
                      width: width,
                      border: "2px dashed rgba(117, 21, 30, 1)",
                      borderRadius: 2,
                      display: "flex",
                      flexDirection: height !== "60px" && "column",
                      alignItems: "center",
                      justifyContent:
                        height === "60px" ? "center" : "space-evenly",
                      p: 1,
                      gap: 1,
                    }}
                  >
                    <img
                      src={uploadIcon}
                      height={height === "60px" ? "18px" : "28px"}
                      width={height === "60px" ? "18px" : "28px"}
                      alt="Upload your documents"
                    />
                    {document[0]?.document_name ? (
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "rgba(117, 21, 30, 1)" }}>
                          {document[0]?.document_name.substring(0, 38)}
                        </span>
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {title}{" "}
                        <span style={{ color: "rgba(117, 21, 30, 1)" }}>
                          {highlightedTitle}
                        </span>
                      </Typography>
                    )}
                    {height !== "60px" && (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "#676767",
                          textAlign: "center",
                        }}
                      >
                        Supported formats: {docFormates}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </span>
              <div className="re-upload">
                <span>
                  {document[0]?.document_name && height === "60px" && (
                    <EditOutlinedIcon
                      fontSize="small"
                      style={{ top: 20, position: "absolute", right: 20 }}
                    />
                  )}
                </span>
              </div>
              {error && (
                <Typography
                  sx={{ color: "red", mb: 2, ml: 2, fontSize: "12px" }}
                >
                  {error}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        {height !== "60px" && document?.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                color: "#676767",
                fontSize: "14px",
                fontWeight: 700,
                my: 2,
                textAlign: "center",
              }}
            >
              Uploaded Document
            </Typography>
            {document?.map((data, index) => {
              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      width: width,
                      height: "36px",
                      borderRadius: 0.5,
                      border: data?.status
                        ? "0.5px solid #F57E77"
                        : "0.5px solid #32936F",
                      display: "flex",
                      mt: 2,
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: 1,
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "none",
                      },
                    }}
                  >
                    {" "}
                    <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                      {data?.document_name.substring(0, 38)}
                    </Typography>{" "}
                    {/* {data?.status === "failed" ? (
                      <img
                        src={crossIcon}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          //   handleDocumentDelete(data?.id);
                          //   fetchData();
                        }}
                        alt="cross"
                      />
                    ) : (
                      <img
                        src={deleteIcon}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          //   handleDocumentDelete(data?.id);
                          //   fetchData();
                        }}
                        alt="delete"
                      />
                    )} */}
                  </Box>
                  {data?.status === "failed" && (
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: 500,
                        color: "#F57E77",
                        textAlign: "left",
                      }}
                    >
                      This file format is not supported. Please delete and
                      upload another file with supported format.
                    </Typography>
                  )}
                </>
              );
            })}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
export default QSUploadCaseDocument;
