import { Box, Grid, Typography } from "@mui/material";
import { bold600 } from "../../../litigation-financing/lib/constants/style";
import QSSliderBar from "../common/qs-slider";
import { currency } from "../../../common/Constants";

export const NBFCPayment = ({ data }) => {
  return (
    <Grid
      sx={{
        p: 3,
        height: "225px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        textAlign: "left",
        width: { xs: "100%", lg: "34%" },
        height: "auto",
        borderRadius: 2.5,
      }}
      className="shadow-sm"
    >
      <Typography sx={bold600}>Credit Line</Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#003B41",
          }}
        >
          Total
        </Typography>
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
          {" "}
          {currency(data?.total_credit_line)}
        </Typography>
      </Box>
      <Box
        sx={{
          my: 1,
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: "grey.500",
        }}
      ></Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#003B41",
            }}
          >
            Used
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, color: "#17C0AF" }}
          >
            {" "}
            {currency(data?.user_credit_line)}
          </Typography>
        </Box>
        <Box
          sx={{
            opacity: 0.6,
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: "#003B41",
            }}
          >
            Available
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, color: "#003B41" }}
          >
            {" "}
            {currency(data?.available_credit_line)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        <QSSliderBar
          marginbottom={-1}
          used_amount={data?.payment_collected}
          total_amount={data?.payment_pending}
        />
      </Box>
    </Grid>
  );
};
