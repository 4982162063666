export const CLAIMANT_STEPS = {
  selectNdaType: 0,
  createNda: 1,
  eSign: 2,
};

export const CLAIMANT_STEPS_PERCENT = {
  selectNdaTypePercent: 0,
  createNdaPercent: 50,
  eSignPercent: 100,
};

export const CREATE_CASE_STEPS = {
  start: 0,
  respondent: 1,
  caseDispute: 2,
  uploadDocs: 3,
};

export const CREATE_CASE_STEPS_PERCENT = {
  startPercent: 0,
  respondentPercent: 33,
  caseDisputePercent: 66,
  uploadDocsPercent: 100,
};

export const CREATE_CASE_ACTIVE_STATE = {
  claimant: "claimant",
  respondent: "respondent",
  "case-details": "case-details",
  "upload-document": "upload-document",
};

export const CIN_LENGTH = 21;
export const LLP_LENGTH = 8;
export const PAN_LENGTH = 10;
export const GSTIN_LENGTH = 15;
export const MOBILE_NUMBER_LENGTH = 10;
export const AADHAR_LENGTH = 12;
export const PIN_CODE_LENGTH = 6;
export const ZIP_CODE_LENGTH = 10;
export const UIN_LENGTH = 15;


