import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./store/auth-context";
import { ClientsContextProvider } from "./store/client-context";
import { CommonContextProvider } from "./store/global-context";
import { Provider } from "react-redux";
import store from "./store/redux-store";
import { SideBarContextProvider } from "./store/sidebar-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SideBarContextProvider>
        <AuthContextProvider>
          <ClientsContextProvider>
            <CommonContextProvider>
              <App />
            </CommonContextProvider>
          </ClientsContextProvider>
        </AuthContextProvider>
      </SideBarContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
