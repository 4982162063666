import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  btoken: "",
  user: "",
  buser: "",
  gst: "",
  isLoggedIn: false,
  isbLoggedIn: false,
  isGstVerified: false,
  setGstVerified: (val) => {},
  setGstUnverified: (val) => {},
  setGst: (gst) => {},
  login: (token) => {},
  blogin: (token) => {},
  saveUser: (user) => {},
  savebUser: (user) => {},
  logout: () => {},
  blogout: () => {},
  savePref: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();
  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredbToken = () => {
  const storedbToken = localStorage.getItem("baccess_token");
  const storedExpirationDate = localStorage.getItem("expirationTime");
  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 6000) {
    localStorage.removeItem("baccess_token");
    localStorage.removeItem("buser");
    localStorage.removeItem("expirationTime");
    return null;
  }

  return {
    token: storedbToken,
    duration: remainingTime,
  };
};
const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("access_token");
  const storedExpirationDate = localStorage.getItem("expirationTime");

  const remainingTime = calculateRemainingTime(storedExpirationDate);
  if (remainingTime <= 6000) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("expirationTime");
    return null;
  }
  return {
    token: storedToken,
    duration: remainingTime,
  };
};
export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  let initialToken;
  if (tokenData) {
    initialToken = tokenData && tokenData.token;
  }
  const initialUser = JSON.parse(localStorage.getItem("user"));
  const [token, setToken] = useState(initialToken);
  const [user, setUser] = useState(initialUser);
  const [gst, setGst] = useState(null);
  const [isGstVerified, setIsGstVerified] = useState(false);
  const userIsLoggedIn = !!token;

  // business.........
  const btokenData = retrieveStoredbToken();
  let initialbToken;
  if (btokenData) {
    initialbToken = btokenData && btokenData.token;
  }
  const initialbUser = JSON.parse(localStorage.getItem("buser"));
  const initialPref = JSON.parse(localStorage.getItem("pref"));
  const [btoken, setBToken] = useState(initialbToken);
  const [buser, setBUser] = useState(initialbUser);
  const [prefData, setPrefData] = useState(initialPref);
  const buserIsLoggedIn = !!btoken;

  const userHandler = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };
  const logOutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("defaultDateFormat");
    localStorage.removeItem("pref");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const setDefaultDateFormat = (format = "DD/MM/YYYY") => {
    localStorage.setItem("defaultDateFormat", format);
  };
  const loginHandler = (token, expirationTime, format) => {
    localStorage.setItem("access_token", token);
    localStorage.setItem("expirationTime", expirationTime);
    setToken(token);
    setDefaultDateFormat();
    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logOutHandler, remainingTime);
  };

  // business..........
  const bloginHandler = (btoken, expirationTime) => {
    localStorage.setItem("baccess_token", btoken);
    localStorage.setItem("expirationTime", expirationTime);
    setBToken(btoken);
    setDefaultDateFormat();
    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(blogOutHandler, remainingTime);
  };
  const buserHandler = (buser) => {
    setBUser(buser);
    localStorage.setItem("buser", JSON.stringify(buser));
  };

  const setPrefLocal = (passedPref) => {
    setPrefData(passedPref);
    localStorage.setItem("pref", JSON.stringify(passedPref));
  };

  const blogOutHandler = useCallback(() => {
    setBToken(null);
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("baccess_token");
    localStorage.removeItem("buser");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logOutHandler, tokenData.duration);
    }

    return () => {};
  }, [tokenData, logOutHandler]);

  useEffect(() => {
    if (btokenData) {
      logoutTimer = setTimeout(blogOutHandler, btokenData.duration);
    }
    return () => {};
  }, [btokenData, blogOutHandler]);

  const gstHandler = (gst) => {
    setGst(gst);
  };

  const gstVerifyHandler = (val) => {
    setIsGstVerified(val);
  };
  const gstUnverifyHandler = (val) => {
    setIsGstVerified(val);
  };

  const contextValue = {
    token: token,
    user: user,
    btoken: btoken,
    buser: buser,
    gst: gst,
    thePref: prefData,
    isLoggedIn: userIsLoggedIn,
    isbLoggedIn: buserIsLoggedIn,

    isGstVerified: isGstVerified,
    setGstVerified: gstVerifyHandler,
    setGstUnverified: gstUnverifyHandler,
    setGst: gstHandler,

    login: loginHandler,
    logout: logOutHandler,
    saveUser: userHandler,
    blogin: bloginHandler,
    blogout: blogOutHandler,
    savebUser: buserHandler,
    savePref: setPrefLocal,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
