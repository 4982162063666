import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const QSModalHeader = ({
  title = "",
  handleClose,
  hideDevider = false,
  hideEllipse = false,
  imgSrc,
  btn,
}) => {
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center relative"
        style={{ width: "100%" }}
      >
        {!hideEllipse && (
          <img
            src={require("../../assets/kyc/ellipse.svg").default}
            width="100%"
          />
        )}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            p: 3,
          }}
        >
          <Box
            className={`d-flex ${
              title ? "justify-content-between" : "justify-content-end"
            } align-items-center`}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                fontWeight: 600,
                fontSize: "18px",
                color: "rgba(21, 29, 72, 1)",
              }}
            >
              {title}
              <span className="ms-2">{btn && btn}</span>
            </Typography>
            <span>
              <CloseIcon className="otp-hover" onClick={handleClose} />
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={imgSrc} />
          </Box>
        </Box>
      </div>
      <Divider
        hidden={hideDevider}
        sx={{ color: "#000", borderBottom: "1px solid black", mt: 1 }}
      />
    </>
  );
};

export default QSModalHeader;
