import React from "react";
import { Box } from "@mui/material";

const QSButton = ({
  type = "primary",
  buttonText = "Continue",
  buttonWidth = "268px",
  height = "44px",
  onClick,
  disabled = false,
}) => {
  return (
    <Box
      sx={{
        width: buttonWidth,
        height: height,
        background: !disabled
          ? type === "primary"
            ? "#17C0AF"
            : "#fff"
          : "rgba(103, 103, 103, 0.5)",
        color: !disabled ? (type === "primary" ? "#fff" : "#17C0AF") : "#fff",
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: !disabled && "pointer",
        border: !disabled && type === "secondary" && "2px solid #17C0AF",
        fontSize: "14px",
        fontWeight: 700,
        "&:hover": {
          boxShadow: !disabled && 4,
          background: !disabled && type === "secondary" && "#17C0AF",
        },
      }}
      onClick={!disabled && onClick}
    >
      {buttonText}
    </Box>
  );
};
export default QSButton;
