import React, { useState } from "react";

const ClientsContext = React.createContext({
  clients: [],
  isClients: false,
  create: (clients) => {},
});

export const ClientsContextProvider = (props) => {
  const [clients, setClients] = useState([]);
  const [showRange, setShowRange] = useState(false);
  const [openDate, setOpenDate] = React.useState(false);
  const [openEditor, setOpenEditor] = React.useState(false);

  const isClientList = !!clients;

  const createClientHandler = (clients) => {
    setClients(clients);
  };

  const contextValue = {
    client: clients,
    isClients: isClientList,
    create: createClientHandler,
    showRange: showRange,
    setShowRange: setShowRange,
    openDate: openDate,
    setOpenDate: setOpenDate,
    openEditor: openEditor,
    setOpenEditor: setOpenEditor,
  };

  return (
    <ClientsContext.Provider value={contextValue}>
      {props.children}
    </ClientsContext.Provider>
  );
};

export default ClientsContext;
