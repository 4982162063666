import { validateInput } from "../../../common/utils/validator/ValidationUtil";
import {
  GSTIN_LENGTH,
  PAN_LENGTH,
} from "../../../litigation-financing/lib/constants/create-case";
let EmailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validateCreateCase = (user_type, formData) => {
  const error = {};
  if (formData.creator_type === "DEBTOR") {
    if (user_type === "individual") {
      if (!formData.bank_statement) {
        error.bank_statement = "Please upload bank statement";
      }
      if (!formData.salary_statement) {
        error.salary_statement = "Please upload sallary statement";
      }
      if (!formData.itr) {
        error.itr = "Please upload bank statement";
      }
      if (!formData.due_amount) {
        error.due_amount = "This field is required";
      }
      return error;
    } else {
      if (!formData.bank_statement) {
        error.bank_statement = "Please upload bank statement";
      }
      if (!formData.corporation_certificate) {
        error.corporation_certificate = "Please upload corporation certificate";
      }
      if (!formData.itr) {
        error.itr = "Please upload previous 3 year ITR";
      }
      if (!formData.balance_sheet) {
        error.balance_sheet = "Please upload balance sheet";
      }
      if (!formData.due_amount) {
        error.due_amount = "This field is required";
      }
      return error;
    }
  } else {
    if (!formData.due_amount) {
      error.due_amount = "This field is required*";
    }
    return error;
  }
};

export const EntityValidation = (formData) => {
  const error = {};
  // if (!formData.user_category) {
  //   error.user_category = "This field is required.";
  // }
  if (!formData.first_name) {
    error.first_name = "This field is required.";
  }
  if (!formData.last_name) {
    error.last_name = "This field is required.";
  }
  if (!formData.email) {
    error.email = "This field is required.";
  }
  if (formData?.firm_name) {
    if (!formData?.party_nature_type) {
      error.party_nature_type = "This field is required.";
    }
  }
  if (formData.email) {
    if (!validateInput(formData.email, EmailReg)) {
      error.email = "Please provide valid email id";
    }
  }
  if (!formData.mobile_number) {
    error.mobile_number = "This field is required.";
  }

  return error;
};
export const FinalStepValidation = (formData) => {
  const error = {};
  if (!formData.debt_proof) {
    error.debt_proof = "This field is required.";
  }
  if (!formData.relevant_proof) {
    error.relevant_proof = "This field is required.";
  }
  if (!formData.due_date) {
    error.due_date = "This field is required.";
  }
  if (!formData.due_amount) {
    error.due_amount = "This field is required";
  }

  return error;
};

export const IndividualValidation = (formData) => {
  const error = {};
  if (!formData.first_name) {
    error.first_name = "This field is required.";
  }
  if (!formData.last_name) {
    error.last_name = "This field is required.";
  }
  if (!formData.email) {
    error.email = "This field is required.";
  }
  if (formData.email) {
    if (!validateInput(formData.email, EmailReg)) {
      error.email = "Please provide valid email id";
    }
  }
  if (!formData.mobile_number) {
    error.mobile_number = "This field is required.";
  }
  return error;
};

export const CreateInvoiceValidation = (formData) => {
  const error = {};

  if (!formData.invoice_date) {
    error.invoice_date = "This field is required.";
  } else {
    const enteredDate = new Date(formData.invoice_date);
    if (isNaN(enteredDate.getTime())) {
      error.invoice_date = "Not a valid date.";
    }
    const futureDate = new Date(formData.invoice_date);
    const currentDate = new Date();
    if (futureDate > currentDate) {
      error.invoice_date = "Invoice date cannot be in the future.";
    }
  }

  if (!formData.due_date) {
    error.due_date = "This field is required.";
  } else {
    const enteredDate = new Date(formData.due_date);
    if (isNaN(enteredDate.getTime())) {
      error.due_date = "Not a valid date.";
    }
    const futureDate = new Date(formData.invoice_date);
    const dueDate = new Date(formData.due_date);
    const currentDate = new Date();
    if (dueDate < futureDate) {
      error.due_date = "Due date cannot be in the past.";
    }
  }
  if (!formData.due_amount) {
    error.due_amount = "This field is required.";
  }

  if (!formData.opposite_party[0].firm_name) {
    error.firm_name = "This field is required.";
  }
  if (!formData.invoice_ref_id) {
    error.invoice_ref_id = "This field is required.";
  }

  if (!formData.opposite_party[0].mobile_number) {
    error.mobile_number = "This field is required.";
  }
  if (!formData.opposite_party[0].gstin) {
    error.gstin = "This field is required.";
  }
  const oppositeParty = formData.opposite_party[0];
  if (!oppositeParty.gstin && !oppositeParty.pan_number) {
    error.gstin = "Either GSTIN or PAN is required.";
    error.pan_number = "Either GSTIN or PAN is required.";
  }
  if (oppositeParty.gstin) {
    if (oppositeParty.gstin.length !== GSTIN_LENGTH) {
      error.gstin = "Not a valid GSTIN.";
    } else {
      delete error.pan_number;
    }
  }

  if (oppositeParty.pan_number) {
    if (oppositeParty.pan_number.length !== PAN_LENGTH) {
      error.pan_number = "Not a valid PAN number.";
    } else {
      delete error.gstin;
    }
  }

  if (!formData.opposite_party[0].email) {
    error.email = "This field is required.";
  }
  if (formData.opposite_party[0].email) {
    if (!validateInput(formData.opposite_party[0].email, EmailReg)) {
      error.email = "Please provide valid email id";
    }
  }
  return error;
};
export const QSContactForm = (formData) => {
  const error = {};
  if (!formData.first_name) {
    error.first_name = "This field is required";
  }
  if (!formData.last_name) {
    error.last_name = "This field is required";
  }
  if (!formData.email) {
    error.email = "This field is required";
  }
  if (!formData.phone_number) {
    error.phone_number = "This field is required";
  }
  if (!formData.message) {
    error.message = "This field is required";
  }
  return error;
};
export const QSContactUs = (formData) => {
  const error = {};
  if (!formData.first_name) {
    error.first_name = "This field is required";
  }
  if (!formData.last_name) {
    error.last_name = "This field is required";
  }
  if (!formData.email) {
    error.email = "This field is required";
  }
  if (!formData.phone_number) {
    error.phone_number = "This field is required";
  }
  if (!formData.business_name) {
    error.business_name = "This field is required";
  }
  if (formData.agree === "false") {
    error.agree = "This field is required";
  }
  return error;
};
export const QSKYCValidation = (formData) => {
  const error = {};
  if (!formData.firstName) {
    error.firstName = "This field is required";
  }
  if (!formData.pan) {
    error.pan = "This field is required";
  }
  if (!formData.email) {
    error.email = "This field is required";
  }
  if (!formData.credit_required) {
    error.credit_required = "This field is required";
  }
  if (formData?.credit_required) {
    if (
      formData.credit_required < 50000 ||
      formData.credit_required > 2500000
    ) {
      error.credit_required = "Enter amount b/w 50K to 25L";
    }
  }
  if (!formData.dob) {
    error.dob = "This field is required";
  }
  if (formData.email) {
    if (!validateInput(formData.email, EmailReg)) {
      error.email = "Please provide valid email id";
    }
  }
  if (formData.gstin && formData.gstin.length !== GSTIN_LENGTH) {
    error.gstin = "Please provide valid gstin";
  }
  return error;
};
export const MerchantDetailsValidation = (formData) => {
  const error = {};
  if (!formData.firstName) {
    error.firstName = "This field is required";
  }
  if (!formData.entity_category) {
    error.entity_category = "This field is required";
  }
  if (!formData.email) {
    error.email = "This field is required";
  }
  if (formData.email) {
    if (!validateInput(formData.email, EmailReg)) {
      error.email = "Please provide valid email id";
    }
  }
  if (!formData.gstin) {
    error.gstin = "This field is required.";
  }

  return error;
};
export const BusinessFormValidation = (formData) => {
  const error = {};
  if (!formData.name) {
    error.name = "This field is required";
  }

  if (!formData.loan_requirement) {
    error.loan_requirement = "This field is required";
  }
  if (formData.email) {
    if (!validateInput(formData.email, EmailReg)) {
      error.email = "Please provide valid email id";
    }
  }
  if (!formData.email) {
    error.email = "This field is required.";
  }
  if (!formData.gst_number) {
    error.gst_number = "This field is required.";
  }

  return error;
};
export const NBFCloginValidation = (formData) => {
  const error = {};
  if (!formData.username) {
    error.username = "This field is required";
  }
  if (!formData.password) {
    error.password = "This field is required";
  }

  return error;
};
export const MerchantKYCvalidation = (userCategory, formdata) => {
  const error = {};
  if (userCategory) {
    if (userCategory === "Proprietorship") {
      if (!formdata.panCard) {
        error.panCard = "Please upload document";
      }
      if (!formdata.AadharFront) {
        error.AadharFront = "Please upload document";
      }
      if (!formdata.BankDetails) {
        error.BankDetails = "Please upload document";
      }
      if (!formdata.RegistrationProof) {
        error.RegistrationProof = "Please upload document";
      }
      if (!formdata.GSTCertificate) {
        error.GSTCertificate = "Please upload document";
      }
    } else if (
      userCategory === "Partnership" ||
      userCategory === "LLP/PRL/PULD"
    ) {
      if (!formdata.panCard) {
        error.panCard = "Please upload document";
      }
      if (!formdata.AadharFront) {
        error.AadharFront = "Please upload document";
      }
      if (!formdata.BankDetails) {
        error.BankDetails = "Please upload document";
      }
      if (!formdata.AuthorisedSignatory) {
        error.AuthorisedSignatory = "Please upload document";
      }
      if (!formdata.PartnershipDeed) {
        error.PartnershipDeed = "Please upload document";
      }
      if (!formdata.GSTCertificate) {
        error.GSTCertificate = "Please upload document";
      }
    }
  } else {
    if (!formdata.panCard) {
      error.panCard = "Please upload document";
    }
    if (!formdata.AadharFront) {
      error.AadharFront = "Please upload document";
    }
    if (!formdata.BankDetails) {
      error.BankDetails = "Please upload document";
    }
    if (!formdata.GSTCertificate) {
      error.GSTCertificate = "Please upload document";
    }
  }
  return error;
};
export const ValidateAddBackount = (formData) => {
  const error = {};
  if (!formData?.acc_no) {
    error.acc_no = "This field is required";
  }
  if (!formData?.acc_type) {
    error.acc_type = "This field is required";
  }
  if (!formData?.bank_ifsc) {
    error.bank_ifsc = "This field is required";
  }
  return error;
};
export const ValidateClientData = (formData) => {
  const error = {};

  if (!formData?.email) {
    error.email = "This field is required";
  }
  if (formData?.firm_name && !formData?.gstin) {
    error.gstin = "This field is required";
  }

  if (!formData?.firm_name && formData?.gstin) {
    error.firm_name = "This field is required";
  }
  if (!formData?.mobile_number) {
    error.mobile_number = "This field is required";
  }

  return error;
};
export const ValidateMerchantData = (formData) => {
  const error = {};
  if (!formData?.email) {
    error.email = "This field is required";
  }
  if (!formData?.gstin) {
    error.gstin = "This field is required";
  }

  if (!formData?.firm_name) {
    error.firm_name = "This field is required";
  }
  if (!formData?.mobile_number) {
    error.mobile_number = "This field is required";
  }
  return error;
};
export const validatePanforMutualFund = (formData) => {
  const error = {};
  if (!formData.pan) {
    error.pan = "This field is required";
  }

  if (!formData.dob) {
    error.dob = "This field is required";
  }
  return error;
};

export const ValidateDisbursedDetail = (formData) => {
  const error = {};
  if (!formData?.utr) {
    error.utr = "This field is required";
  }
  if (!formData?.disbursement_date) {
    error.disbursement_date = "This field is required";
  }
  return error;
};
export const ValidateRepaymentDetail = (formData) => {
  const error = {};
  if (!formData?.utr) {
    error.utr = "This field is required";
  }
  if (!formData?.repayment_date) {
    error.repayment_date = "This field is required";
  }
  return error;
};

export const validateChequeDetail = (formData) => {
  const error = {};
  if (!formData?.cheque_number) {
    error.cheque_number = "This field is required";
  }
  if (!formData?.cheque_amount) {
    error.cheque_amount = "This field is required";
  }
  if (!formData?.bank_ifsc) {
    error.bank_ifsc = "This field is required";
  }
  if (!formData?.branch_name) {
    error.branch_name = "This field is required";
  }
  return error;
};

export const QSCommonContactUs = (formData, isPan = false) => {
  const error = {};
  if (!formData.first_name) {
    error.first_name = "This field is required";
  }
  if (!formData.last_name) {
    error.last_name = "This field is required";
  }
  if (!formData.email) {
    error.email = "This field is required";
  }
  if (!formData.phone_number) {
    error.phone_number = "This field is required";
  }
  if (!formData.business_name) {
    error.business_name = "This field is required";
  }
  if (formData.agree === "false") {
    error.agree = "This field is required";
  }
  if (!formData.pan) {
    error.pan = "This field is required";
  }
  // if (isPan) {
  //   if (!formData.pan) {
  //     error.pan = "This field is required";
  //   }
  //   if (!formData.address) {
  //     error.address = "This field is required";
  //   }
  // }
  if (!formData.agree) {
    error.agree = "This field is required";
  }
  return error;
};
