import {
  Box,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { NdaText16 } from "../../../../litigation-financing/lib/constants/style";
import { useEffect, useState } from "react";
import QSInputBox from "../../common/qs-input-box";
import {
  GSTIN_LENGTH,
  MOBILE_NUMBER_LENGTH,
  PAN_LENGTH,
} from "../../../../litigation-financing/lib/constants/create-case";
import { yyyymmdd } from "../../../../common/utils/DateTime";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import QSPhoneCodeDropdown from "../../common/qs-phone-input";
import QSButton from "../../common/qs-button";
import { BASE_URL } from "../../../../networks/ApiEndPoints";
import BusinessApiEndPoint from "../../../../networks/BusinessApiEndPoint";
import { CClient } from "../../../../litigation-financing/lib/common/common";
import { CreateInvoiceValidation } from "../../../lib/constant/validation";
import { apiErrorToast } from "../../../../common/Toasts";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import Spinner from "../../../../common/Spinner";
import SweetAlert from "../../common/sweetalert";

const SingleInvoice = ({ handleClose }) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [inputName, setInputName] = useState("gstin");
  const [formData, setFormData] = useState({
    invoice_date: "",
    due_date: "",
    due_amount: "",
    opposite_party: [
      {
        email: "",
        mobile_number: "",
        firm_name: "",
        gstin: "",
        phone_country_code: "+91",
      },
    ],
    invoice_ref_id: "",
  });
  const [error, setError] = useState({});
  const [mobileError, setMobileError] = useState();
  const [currencyFormate, setCurrencyFormate] = useState("");
  const [dueAmount, setDueAmount] = useState();
  const [gstError, setGstError] = useState(false);
  const [gstLoader, setGstLoader] = useState(false);
  const [show, setShow] = useState(false);
  const postInvDate = async () => {
    setError({});
    const validationError = CreateInvoiceValidation(formData);
    setError(validationError);
    if (Object.keys(validationError).length) {
      return;
    }
    setLoader(true);
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_ADD_INVOICE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (res.ok) {
        setLoader(false);
        const data = await res.json();
        // Swal.fire({
        //   icon: "success",
        //   text: "Invoices created successfully",
        //   showConfirmButton: true,
        //   showCloseButton: true,
        //   confirmButtonColor: "#17C0AF",
        // }).then(() => {
        //   handleClose();
        //   window.location.reload();
        // });

        setShow(true);
      } else {
        const data = await res.json();
        Swal.fire({
          icon: "error",
          text: data.message,
          showConfirmButton: false,
          showCloseButton: true,
        });
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const currencyFormatter = (amt) => {
    let x = amt;
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    setCurrencyFormate(res);
  };

  const handleChangeForPartyDetail = (event) => {
    setError({});
    if (event?.target?.name === "mobile_number") {
      const { name, value } = event?.target;
      let countryCode = value.split(" ")[0];
      let mobileNumber = value.split(" ").slice(1).join(" ");
      if (countryCode === "+91") {
        if (mobileNumber.length - 1 === MOBILE_NUMBER_LENGTH) {
          setFormData((prevData) => ({
            ...prevData,
            opposite_party: prevData.opposite_party.map((respondent) => ({
              ...respondent,
              ["phone_country_code"]: countryCode,
              ["mobile_number"]: mobileNumber.split("-").join(""),
            })),
          }));

          setMobileError({});
        } else if (mobileNumber) {
          setFormData((prevData) => ({
            ...prevData,
            opposite_party: prevData.opposite_party.map((respondent) => ({
              ...respondent,
              ["phone_country_code"]: countryCode,
              ["mobile_number"]: mobileNumber.split("-").join(""),
            })),
          }));

          setMobileError({ mobile_number: "Invalid mobile number" });
        }
      } else {
        setFormData((prevData) => ({
          ...prevData,
          opposite_party: prevData.opposite_party.map((respondent) => ({
            ...respondent,
            ["phone_country_code"]: countryCode,
            ["mobile_number"]: mobileNumber.split("-").join(""),
          })),
        }));
      }
    }

    if (event.target?.name === "gstin" || event.target?.name === "pan_number") {
      if (event?.target?.value?.length <= PAN_LENGTH) {
        setGstLoader(false);
        setInputName("pan_number");
        delete formData.opposite_party[0].gstin;
        setFormData((prevData) => ({
          ...prevData,
          opposite_party: prevData.opposite_party.map((respondent) => ({
            ...respondent,
            pan_number: event?.target?.value?.toUpperCase(),
          })),
        }));
      } else {
        setInputName("gstin");
        setGstLoader(false);
        delete formData.opposite_party[0].pan_number;
        setFormData((prevData) => ({
          ...prevData,
          opposite_party: prevData.opposite_party.map((respondent) => ({
            ...respondent,
            gstin: event.target.value,
          })),
        }));
      }
    }
    if (
      event?.target?.name !== "gstin" &&
      event?.target?.name !== "pan_number" &&
      event?.target?.name !== "mobile_number" &&
      event?.target?.name
    ) {
      const { name, value } = event?.target;
      setFormData((prevData) => ({
        ...prevData,
        opposite_party: prevData.opposite_party.map((respondent) => ({
          ...respondent,
          [name]: value,
        })),
      }));
    }
  };
  const handleChange = (event) => {
    setError({});
    if (event?.target?.name === "due_amount") {
      const { name, value } = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value?.split(",").join(""),
      }));
      setDueAmount(Number(value.split(",").join("")));
    }

    if (
      event?.target?.name !== "gstin" &&
      event?.target?.name !== "mobile_number" &&
      event?.target?.name !== "due_amount" &&
      event?.target?.name
    ) {
      const { name, value } = event?.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  useEffect(() => {
    dueAmount && currencyFormatter(dueAmount);
    dueAmount === 0 && setCurrencyFormate("");
  }, [dueAmount]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "70vh" }}>
      <Box
        sx={{
          overflowY: "auto",
        }}
      >
        <Spinner loading={loader} circleBrown />
        <Typography sx={NdaText16}>Invoice Details </Typography>

        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0, md: 1 },
            }}
          >
            <QSInputBox
              label={"Invoice Ref ID"}
              placeholder={"Invoice ID"}
              id={"invoice_ref_id"}
              required
              type={"text"}
              value={formData.invoice_ref_id && formData.invoice_ref_id}
              helperText={error?.invoice_ref_id}
              setValue={(e) => handleChange(e)}
              disable={loader}
            />
            <QSInputBox
              label={"Invoice Date"}
              placeholder={"Invoice Date"}
              required
              id={"invoice_date"}
              type={"date"}
              helperText={error?.invoice_date}
              value={formData?.invoice_date && yyyymmdd(formData?.invoice_date)}
              setValue={(event) => {
                setError({});
                setFormData((prevData) => ({
                  ...prevData,
                  ["invoice_date"]: yyyymmdd(event?.$d),
                }));
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0, md: 1 },
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxHeight: "78px",
                minHeight: "78px",
              }}
            >
              <FormLabel
                sx={{
                  textAlign: "left",
                  color: "#5E6366",
                  fontSize: "14px",
                  mb: 1,
                  color: error?.due_amount && !dueAmount && "#d32f2f",
                }}
                htmlFor={"amount"}
              >
                Due Amount*
              </FormLabel>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 2,
                  },
                  "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
                    m: 0,
                  },
                  "&.MuiFormHelperText-root.Mui-error": {
                    color: "red",
                  },
                }}
                style={{
                  marginBottom: "0.5rem",
                  background: "#FFFFFF",
                  borderRadius: "8px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon
                        sx={{ color: "#5E6366", fontSize: "16px" }}
                      />
                    </InputAdornment>
                  ),
                }}
                name="due_amount"
                value={currencyFormate ? currencyFormate : ""}
                onChange={(e) => handleChange(e)}
                type="text"
                size="small"
                helperText={!dueAmount && error.due_amount}
                error={!dueAmount && error.due_amount}
                placeholder="0"
              />
            </Box>
            <QSInputBox
              label={"Due Date"}
              placeholder={"Due Date"}
              id={"due_date"}
              type={"date"}
              required
              helperText={error?.due_date}
              value={formData?.due_date && yyyymmdd(formData?.due_date)}
              setValue={(event) => {
                setError({});
                setFormData((prevData) => ({
                  ...prevData,
                  ["due_date"]: yyyymmdd(event?.$d),
                }));
              }}
              disableFuture={false}
              // disablePast
            />
          </Box>
        </Box>
        <Typography sx={NdaText16}>Party&apos;s Details </Typography>
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0, md: 1 },
            }}
          >
            {" "}
            <QSInputBox
              label={"Name"}
              placeholder={"Name"}
              id={"firm_name"}
              required
              type={"text"}
              value={
                formData.opposite_party[0].firm_name &&
                formData.opposite_party[0].firm_name
              }
              helperText={error?.firm_name}
              setValue={(e) => handleChangeForPartyDetail(e)}
              disable={loader}
            />
            <QSInputBox
              label={"GSTIN (PAN in case GSTIN is not available)"}
              helperText={gstError || error?.gstin}
              placeholder={"GSTIN / Pan"}
              maxLength={{
                style: { textTransform: "uppercase" },
                maxLength: GSTIN_LENGTH,
              }}
              required
              apiLoader={gstLoader}
              error={gstError || error?.gstin || error?.pan_number}
              id={inputName}
              type={"text"}
              value={
                inputName === "gstin"
                  ? formData?.opposite_party[0].gstin
                  : formData.opposite_party[0].pan_number
              }
              setValue={(e) => {
                handleChangeForPartyDetail(e);
              }}
              disable={loader}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0, md: 1 },
              mt: { xs: 2 },
            }}
          >
            <QSInputBox
              label={"Email Address"}
              placeholder={"test@email.com"}
              id={"email"}
              required
              type={"email"}
              value={formData.opposite_party[0].email}
              helperText={error?.email}
              setValue={(e) => handleChangeForPartyDetail(e)}
              disable={loader}
            />
            <QSPhoneCodeDropdown
              value={formData?.opposite_party[0].phone_country_code}
              label={"Phone Number*"}
              mobileNumber={formData.opposite_party[0].mobile_number}
              setCode={(e) => handleChangeForPartyDetail(e)}
              isValid={mobileError?.mobile_number || error?.mobile_number}
              disable={loader}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          bottom: 0,
          background: "#fff",
          zIndex: 1,
          p: 2,
        }}
      >
        <QSButton
          onClick={() => {
            postInvDate();
          }}
          disabled={loader}
        />
      </Box>
      <Box>{show && <SweetAlert setUserDetails={() => {}} />}</Box>
    </Box>
  );
};
export default SingleInvoice;
