import {
  Grid,
  Box,
  Typography,
  IconButton,
  InputBase,
  CircularProgress,
  Button,
  Link,
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
  MenuItem,
  Grow,
  Modal,
  Radio,
  Tabs,
  Tab,
  TableContainer,
} from "@mui/material";
import Spinner from "../../../common/Spinner";
import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";

import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import { BASE_URL } from "../../../networks/ApiEndPoints";
import { CClient } from "../../../litigation-financing/lib/common/common";
import goodIcon from "../../assets/nbfc/GOOD.png";
import badIcon from "../../assets/nbfc/BAD.png";
import avgIcon from "../../assets/nbfc/AVG.png";
import ntcIcon from "../../assets/nbfc/NTC.png";
import {
  apiErrorToast,
  catchErrorToast,
  okSuccessToast,
} from "../../../common/Toasts";
import { NBFCModalClientDetails } from "./nbfc-modal-clients-details";
import ClientPaymentNbfc from "./nbfc-payment-modal";
import Swal from "sweetalert2";

const options = ["Freeze Client", "Approve Client"];

const CreditRequest = ({ open, setOpen, id }) => {
  const [loader, setLoader] = useState(false);
  const [clientData, setClientData] = useState({
    basic_detail: [],
    client_details: [],
    doc_details: [],
  });
  const EnvironmentType = process.env.REACT_APP_DEPLOYMENT;
  const [clientPaymentModal, setClientPaymentModal] = useState(false);
  const [esignData, setEsignData] = useState({});
  const [value, setValue] = useState("Clients Details");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCreditRequest = async () => {
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.NBFC_INCREASE_LIMIT}${id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: "NBFC APPROVED" }),
        }
      );

      if (res.ok) {
        const data = await res.json();
        Swal.fire({
          icon: "success",
          title: "APPROVED",
          confirmButtonText: "Done",
          confirmButtonColor: "#17C0AF",
          reverseButtons: true,
        }).then((result) => {
          window.location.reload();
        });
      } else {
        const data = await res.json();
        Swal.fire({
          icon: "error",
          text: "data.message",
          confirmButtonColor: "#17C0AF",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleRequest = async () => {
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.NBFC_INCREASE_LIMIT}${id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: "REJECTED" }),
        }
      );

      if (res.ok) {
        const data = await res.json();
        Swal.fire({
          icon: "Rejected",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonText: "Done",
          confirmButtonColor: "#1e845a",
          reverseButtons: true,
        }).then((result) => {
          window.location.reload();
        });
      } else {
        const data = await res.json();
        Swal.fire({
          icon: "error",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#17C0AF",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", md: "624px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: { xs: "600px", md: "auto" },
    overflowY: "scroll",
    borderRadius: 2,
    px: { xs: 1, md: 4 },
    pb: 4,
    maxHeight: "90vh",
  };
  const EsignModal = async (id) => {
    setLoader(true);
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_NBFC_AGREEMENT}`
      );
      if (res.ok) {
        const data = await res.json();
        setEsignData(data);
      } else {
        setLoader(false);
        apiErrorToast("Something went wrong");
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  useEffect(() => {
    const options = {
      environment: EnvironmentType === "prod" ? "production" : "sandbox",
      callback: function (t) {
        if (t.hasOwnProperty("error_code")) {
          setLoader(false);
          catchErrorToast();
        } else {
          handleCreditRequest();
        }
      },
      theme: {
        primaryColor: "#17C0AF",
        secondaryColor: "#fff",
      },
    };
    if (esignData?.id) {
      const DigioInit = new window.Digio(options);
      DigioInit.init();
      DigioInit.submit(
        esignData?.id,
        esignData?.identifier,
        esignData?.access_token
      );
    }
    return () => {};
  }, [esignData]);
  const handleCloseModal = () => {
    setOpen(false);
  };
  const fetchData = async () => {
    setLoader(true);
    try {
      const res = await CClient(
        `${BusinessApiEndPoint.NBFC_CREDIT_DETAILS}?id=${id}`
      );
      if (res.ok) {
        const data = await res.json();
        setLoader(false);
        setClientData(data);
      } else {
        setLoader(false);
        const data = await res.json();
        apiErrorToast(data.message);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  useEffect(() => {
    id && fetchData();
  }, [id]);

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Box
            className={`d-flex justify-content-between align-items-center pt-4`}
            sx={{
              px: 1,
              pt: 2,
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: 999,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                fontWeight: 600,
                fontSize: "18px",
                color: "#003B41",
              }}
            >
              Credit Request
            </Typography>
            <span>
              <CloseIcon className="otp-hover" onClick={handleCloseModal} />
            </span>
          </Box>

          {loader && <Spinner loading={loader} circleBrown />}
          {
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "75vh",
                gap: 2,
                marginTop: "24px",
                width: "100%",
                px: { md: 4 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 2,
                    p: "20px",
                  }}
                  className="shadow-sm"
                >
                  {clientData?.basic_detail[0]?.credit_score === "good" && (
                    <img src={goodIcon} alt="good" />
                  )}
                  {clientData?.basic_detail[0]?.credit_score === "bad" && (
                    <img src={badIcon} alt="bad" />
                  )}
                  {clientData?.basic_detail[0]?.credit_score === "average" && (
                    <img src={avgIcon} alt="avg" />
                  )}
                  {(clientData?.basic_detail[0]?.credit_score === "ntc" ||
                    !clientData?.basic_detail[0]?.credit_score) && (
                    <img src={ntcIcon} alt="avg" />
                  )}
                  {/* <img src={goodIcon} alt="good" /> */}
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      sx={{
                        color: "#1F242F",
                        fontSize: "18px",
                        fontWeight: 600,
                      }}
                    >
                      {clientData?.client_details[0]?.first_name
                        ? clientData?.client_details[0]?.first_name?.substring(
                            0,
                            35
                          )
                        : "-"}{" "}
                      {clientData?.client_details[0]?.last_name
                        ? clientData?.client_details[0]?.last_name?.substring(
                            0,
                            35
                          )
                        : "-"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#003B41",
                        fontSize: "14px",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      GSTIN :
                      <span style={{ fontWeight: 500, opacity: "60%" }}>
                        &nbsp;
                        {clientData?.client_details[0]?.userBusiness[0]?.gst_in}
                      </span>{" "}
                    </Typography>
                  </Box>
                  {/* <Box
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#003B41",
                      height: "22px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background:
                        (clientData?.basic_detail[0]?.status === "APPROVED" &&
                          "#2BD96026") ||
                        (clientData?.basic_detail[0]?.status === "FREEZE" &&
                          "#D92B3C1A"),
                      color:
                        (clientData?.basic_detail[0]?.status === "APPROVED" &&
                          "#2BD960") ||
                        (clientData?.basic_detail[0]?.status === "FREEZE" &&
                          "#D92B3C"),
                      borderRadius: 2,
                      px: 1,
                    }}
                  >
                    {clientData?.basic_detail[0]?.status}
                  </Box> */}
                  <Box
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#003B41",
                      minHeight: "22px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#D92B3C1A",
                      color: "#D92B3C",
                      borderRadius: 2,
                      px: 1,
                    }}
                  >
                    {new Date(
                      clientData?.basic_detail[0]?.last_modified_at
                    ).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    background: "#fff",
                    maxHeight: "42vh",
                    minHeight: "42vh",
                  }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="wrapped label tabs example"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#17C0AF",
                        },
                      }}
                      sx={{
                        "& .Mui-selected": {
                          color: "#17C0AF !important",
                          fontWeight: 600,
                        },
                      }}
                    >
                      <Tab
                        value="Clients Details"
                        label="Clients Details"
                        sx={{ color: "#003B41", fontWeight: 600 }}
                      />
                      <Tab
                        value="Documents"
                        label="Documents"
                        sx={{ color: "#003B41", fontWeight: 600 }}
                      />
                    </Tabs>
                  </Box>
                  {value === "Documents" && (
                    <NBFCModalClientDetails
                      data={clientData && clientData}
                      value="Documents"
                    />
                  )}

                  {value === "Clients Details" && (
                    <NBFCModalClientDetails
                      data={clientData && clientData}
                      value="Clients Details"
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  my: 2,
                  justifyContent: "center",
                  alignItems: "center",
                  pb: 2,
                }}
              >
                <Button
                  sx={{
                    height: "40px",
                    width: "90%",
                    background: "#17C0AF",
                    fontSize: "14px",
                    borderRadius: "8px",
                    fontWeight: 600,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#17C0AF",
                      color: "#fff",
                    },
                  }}
                  onClick={() => {
                    // setClientPaymentModal(true);
                    handleCreditRequest();
                    // EsignModal();
                  }}
                >
                  Accept
                </Button>
                <Button
                  sx={{
                    height: "40px",
                    width: "90%",
                    background: "#fff",
                    fontSize: "14px",
                    borderRadius: "8px",
                    fontWeight: 600,
                    color: "#17C0AF",
                    border: 1,
                    borderColor: "#17C0AF",
                    "&:hover": {
                      backgroundColor: "#17C0AF",
                      color: "#fff",
                    },
                  }}
                  onClick={() =>
                    Swal.fire({
                      title: "Are you sure?",
                      showConfirmButton: true,
                      showDenyButton: true,
                      showCloseButton: true,
                      confirmButtonText: "YES",
                      confirmButtonColor: "#17C0AF",
                    }).then((result) => {
                      if (result.isConfirmed === true) {
                        // Make the API call when the user clicks the "accept" button
                        handleRequest();
                      }
                    })
                  }
                >
                  Reject
                </Button>
              </Box>
            </Box>
          }
          {clientPaymentModal && (
            <ClientPaymentNbfc
              setOpen={setClientPaymentModal}
              open={clientPaymentModal}
              id={id}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};
export default CreditRequest;
