import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QSInputBox from "../common/qs-input-box";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { CClient } from "../../../litigation-financing/lib/common/common";
import { BASE_URL } from "../../../networks/ApiEndPoints";
import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import { ValidateAddBackount } from "../../lib/constant/validation";
import { apiErrorToast } from "../../../common/Toasts";
import { validateInput } from "../../../common/utils/validator/ValidationUtil";
import { PATTERNS } from "../../../common/Constants";
import { faBedPulse } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Spinner from "../../../common/Spinner";

export const QSAddBankAccount = ({ open = true, setOpen }) => {
  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "80%", md: "700px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: "auto",
    overflowY: "scroll",
    borderRadius: 2,
    px: 3,
    py: 2,
  };
  const branchRef = useRef("");
  const bankRef = useRef("");
  const [loader, setLoader] = useState(false);
  const [isIfscValid, setIsIfscValid] = useState(true);
  const [ifscDetails, setIfscDetails] = useState();
  const [formData, setFormData] = useState({
    acc_no: "",
    acc_type: "",
    bank_name: "",
    bank_ifsc: "",
    bank_branch: "",
  });
  const [error, setError] = useState({});

  const addBankAccount = async () => {
    setError({});
    const validationError = ValidateAddBackount(formData);
    setError(validationError);
    if (Object.keys(validationError).length) {
      return;
    }
    setLoader(true);
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_ADD_BANK}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (res.ok) {
        setLoader(false);
        const data = await res.json();
        Swal.fire({
          icon: "success",
          text: "Bank added successfully",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#17C0AF",
        }).then(() => {
          window.location.reload();
        });
      } else {
        const data = await res.json();
        Swal.fire({
          icon: "error",
          text: data.message,
          showConfirmButton: false,
          showCloseButton: true,
        });
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setError({});
    const { name, value } = event?.target;
    if (name === "bank_ifsc") {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (value.length === 11) {
        setIsIfscValid(false);
        setIsIfscValid(validateInput(value, PATTERNS.IFSC));
      } else {
        setIsIfscValid(false);
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  useEffect(() => {
    if (isIfscValid && formData.bank_ifsc.length === 11) {
      setLoader(true);
      axios
        .get(`https://ifsc.razorpay.com/${formData.bank_ifsc}`)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              setLoader(false);
              setFormData((prevData) => ({
                ...prevData,
                bank_name: res.data.BANK,
                bank_branch: res.data.BRANCH,
              }));
              setIfscDetails(res.data);
            }, 500);
          } else {
            setIsIfscValid(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          apiErrorToast(error);
        });
    }
    return () => {};
  }, [isIfscValid, formData?.bank_ifsc]);
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #003B4126",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontWeight: 600,
              fontSize: "18px",
              color: "rgba(21, 29, 72, 1)",
            }}
          >
            Add Bank Account
          </Typography>
          <CloseIcon onClick={() => setOpen(false)} className="otp-hover" />
        </Box>
        {loader && <Spinner loading={loader} circleBrown />}
        <Box
          sx={{
            my: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0, md: 1 },
              width: "100%",
            }}
          >
            <QSInputBox
              label={"Account Number"}
              placeholder={"Account Number"}
              id={"acc_no"}
              required
              type={"text"}
              value={formData?.acc_no && formData.acc_no}
              helperText={error?.acc_no}
              setValue={(e) => handleChange(e)}
            />
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <FormLabel
                sx={{
                  textAlign: "left",
                  fontSize: "14px",
                  mb: 1,
                  color: error?.acc_type && !formData?.acc_type && "#d32f2f",
                }}
                htmlFor={"demo-simple-select"}
              >
                Account Type*
              </FormLabel>
              <Select
                placeholder="Choose from the dropdown"
                sx={{
                  textAlign: "start",
                  boxShadow: "none",
                  borderRadius: 2,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 2,
                    borderColor:
                      error?.acc_type && !formData?.acc_type && "red",
                  },
                }}
                id="demo-simple-select"
                size="small"
                value={formData?.acc_type ? formData?.acc_type : ""}
                onChange={(event) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    ["acc_type"]: event?.target.value,
                  }))
                }
              >
                <MenuItem dense value="SAVING">
                  Saving
                </MenuItem>
                <MenuItem dense value="CURRENT">
                  Current
                </MenuItem>{" "}
              </Select>
              {!formData?.acc_type && (
                <span style={{ color: "#d32f2f", fontSize: "12px" }}>
                  {error?.acc_type}
                </span>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0, md: 1 },
              width: "100%",
            }}
          >
            <QSInputBox
              label={"IFSC"}
              placeholder={"IFSC"}
              id={"bank_ifsc"}
              maxLength={{
                style: { textTransform: "uppercase" },
                maxLength: 11,
              }}
              required
              type={"text"}
              value={formData?.bank_ifsc && formData.bank_ifsc}
              helperText={error?.bank_ifsc}
              setValue={(e) => handleChange(e)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0, md: 1 },
              width: "100%",
              mt: 1,
            }}
          >
            <Grid item md={6} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                {loader && <Spinner />}
                <FormLabel
                  sx={{
                    textAlign: "left",
                    color: "#151515",
                    fontSize: "14px",
                    mb: 1,
                  }}
                >
                  Branch Name
                </FormLabel>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      borderColor: "#323232",
                    },
                    "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
                      m: 0,
                    },
                  }}
                  style={{
                    // marginTop: "0.5rem",
                    background: "#F2F4F5",
                    borderRadius: "8px",
                  }}
                  id="bank_branch"
                  size="small"
                  disabled
                  value={
                    ifscDetails && ifscDetails.BRANCH ? ifscDetails.BRANCH : ""
                  }
                  focused={ifscDetails && ifscDetails.BRANCH}
                  inputRef={branchRef}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                {loader && <Spinner />}
                <FormLabel
                  sx={{
                    textAlign: "left",
                    color: "#151515",
                    fontSize: "14px",
                    mb: 1,
                  }}
                >
                  Bank Name
                </FormLabel>

                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      borderColor: "#323232",
                    },
                    "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
                      m: 0,
                    },
                  }}
                  style={{
                    // marginTop: "0.5rem",
                    background: "#F2F4F5",
                    borderRadius: "8px",
                  }}
                  id="bank_name"
                  size="small"
                  disabled
                  value={
                    ifscDetails && ifscDetails.BANK ? ifscDetails.BANK : ""
                  }
                  focused={ifscDetails && ifscDetails.BANK}
                  inputRef={bankRef}
                  required
                />
              </FormControl>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            pb: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{
              height: "40px",
              background: "#17C0AF",
              fontSize: "14px",
              borderRadius: "8px",
              fontWeight: 600,
              color: "#fff",
              "&:hover": {
                backgroundColor: "#17C0AF",
                color: "#fff",
              },
            }}
            disabled={!isIfscValid}
            onClick={() => isIfscValid && addBankAccount()}
          >
            Add Bank
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
