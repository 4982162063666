import React from "react";
import { CircularProgress } from "@mui/material";
import CustomSpinner from "./CustomSpinner";
// import { white } from '@mui/material/colors';

const Spinner = ({
  loading = true,
  dots = false,
  circle = false,
  dotCircle = false,
  dotCircleWhite = false,
  dotBnW = false,
  circleBlue = false,
  circleBrown = false,
}) => {
  return (
    <div
      hidden={!loading}
      className="position-absolute text-primary fw-bolder fs-4"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "99%",
        height: "100%",
        zIndex: 1000,
        top: 0,
        position: "fixed",
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "#ffffff10",
        borderRadius: "4px",
        backdropFilter: "blur(3px)",
        color: "#fff",
      }}
    >
      {dots && <CustomSpinner />}
      {circle && (
        <CircularProgress
          size={24}
          sx={{
            color: "#000",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
            zIndex: "10",
          }}
        />
      )}
      {dotCircle && <div div class="dot"></div>}
      {dotCircleWhite && <div div class="dot-white"></div>}
      {dotBnW && <div div class="dot-bnw"></div>}
      {circleBlue && <div div class="circle-blue"></div>}
      {circleBrown && <div div class="circle-brown"></div>}
    </div>
  );
};

export default Spinner;
