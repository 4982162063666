import {
  Box,
  Checkbox,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import QSInputBox from "../common/qs-input-box";
import QSPhoneCodeDropdown from "../common/qs-phone-input";
import QSButton from "../common/qs-button";
import { useState } from "react";
import {
  GSTIN_LENGTH,
  MOBILE_NUMBER_LENGTH,
} from "../../../litigation-financing/lib/constants/create-case";
import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import { BASE_URL } from "../../../networks/ApiEndPoints";
import { CClient } from "../../../litigation-financing/lib/common/common";
import { EntityValidation } from "../../lib/constant/validation";
import { apiErrorToast } from "../../../common/Toasts";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { CheckBox } from "@mui/icons-material";
const DebtorEntity = ({ caseId, setCaseStep, draftData, setOpen }) => {
  const [gstError, setGstError] = useState(false);
  const [gstLoader, setGstLoader] = useState(false);
  const [mobileError, setMobileError] = useState();
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [isEntity, setIsEntity] = useState(false);
  const [formData, setFormData] = useState({
    first_name: draftData?.first_name ? draftData?.first_name : "",
    last_name: draftData?.last_name ? draftData?.last_name : "",
    firm_name: draftData?.firm_name ? draftData?.firm_name : "",
    party_nature_type: draftData?.party_nature_type
      ? draftData?.party_nature_type
      : "",
    gstin: draftData?.gstin ? draftData?.gstin : "",
    mobile_number: draftData?.mobile_number ? draftData?.mobile_number : "",
    phone_country_code: draftData?.phone_country_code
      ? draftData.phone_country_code
      : 91,
    email: draftData?.email ? draftData.email : "",
    case_id: caseId,
  });
  const verifyGstIn = async (number) => {
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_VERIFY_GSTIN}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ gst_no: number }),
        }
      );

      if (res.ok) {
        setGstLoader(false);
        const data = await res.json();
        setFormData({
          ...formData,
          firm_name: data.gstdetails.corporate_name,
        });
        setGstError(false);
      } else {
        const data = await res.json();
        setGstLoader(false);
        setGstError(
          Array.isArray(data?.message) ? data?.message[0] : data?.message
        );
        setFormData({
          ...formData,
          firm_name: "",
        });
      }
    } catch (error) {
      setGstLoader(false);
      console.error(error);
    }
  };

  const submitOppositParty = async () => {
    try {
      setError({});
      if (!formData?.firm_name) {
        delete formData?.firm_name;
        delete formData?.party_nature_type;
      }
      const validationError = EntityValidation(formData);
      setError(validationError);
      if (Object.keys(validationError).length) {
        return;
      }
      setLoader(true);
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_OPPOSITE_PARTY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (res.ok) {
        const data = await res.json();
        setLoader(false);
        setOpen(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Case created successfully",
          text: "Your case under review",
          showConfirmButton: true,
          showCloseButton: true,
        }).then(() => navigate("/business/qs/dashboard/all-cases"));
      } else {
        const data = await res.json();
        setLoader(false);
        apiErrorToast(
          Array.isArray(data?.message) ? data?.message[0] : data?.message
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "mobile_number") {
      let countryCode = value.split(" ")[0];
      let mobileNumber = value.split(" ").slice(1).join(" ");
      if (countryCode === "+91") {
        if (mobileNumber.length - 1 === MOBILE_NUMBER_LENGTH) {
          setFormData({
            ...formData,
            ["phone_country_code"]: countryCode,
            ["mobile_number"]: mobileNumber.split("-").join(""),
          });

          setMobileError({});
        } else if (mobileNumber) {
          setFormData({
            ...formData,
            ["phone_country_code"]: countryCode,
            ["mobile_number"]: mobileNumber.split("-").join(""),
          });

          setMobileError({ mobile_number: "Invalid mobile number" });
        }
      } else {
        setFormData({
          ...formData,
          ["phone_country_code"]: countryCode,
          ["mobile_number"]: mobileNumber,
        });
      }
    }
    if (name !== "gstin" && name !== "mobile_number" && name) {
      const { name, value } = event?.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    if (name === undefined) {
      setFormData((prevData) => ({ ...prevData, party_nature_type: value }));
    }
    if (name === "gstin") {
      if (event.target?.name === "gstin") {
        if (event.target.value.length === GSTIN_LENGTH) {
          setGstLoader(true);
          verifyGstIn(event.target.value);
        }
      } else {
        if (event.target.value) {
          setGstError(true);
          setFormData({
            ...formData,
            gstin: "",
            firm_name: "",
          });
        } else {
          setFormData({
            ...formData,
            gstin: "",
          });
          setGstError(false);
        }
      }
    }
  };
  return (
    <Grid container>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            fontWeight: 600,
            fontSize: "18px",
            color: "rgba(21, 29, 72, 1)",
          }}
        >
          Creditor&apos;s Details
        </Typography>
        {/* <span>
          <SaveOutlinedIcon
            sx={{
              "&:hover": {
                color: "#75151E",
              },
              cursor: "pointer",
            }}
          />
        </span> */}
      </Box>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
          <QSInputBox
            label={"First Name"}
            placeholder={"First Name"}
            id={"first_name"}
            required
            type={"text"}
            value={formData.first_name && formData.first_name}
            helperText={error?.first_name}
            setValue={(e) => handleChange(e)}
            disable={loader}
          />
          <QSInputBox
            label={"Last Name"}
            placeholder={"Last Name"}
            id={"last_name"}
            required
            type={"text"}
            value={formData.last_name && formData.last_name}
            helperText={error?.last_name}
            disable={loader}
            setValue={(e) => handleChange(e)}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
          <QSInputBox
            label={"Email Address"}
            placeholder={"test@email.com"}
            id={"email"}
            required
            type={"email"}
            value={formData.email}
            helperText={error?.email}
            setValue={(e) => handleChange(e)}
            disable={loader}
          />
          <QSPhoneCodeDropdown
            value={formData?.phone_country_code}
            label={"Phone Number*"}
            mobileNumber={formData.mobile_number}
            setCode={(e) => handleChange(e)}
            isValid={mobileError?.mobile_number || error?.mobile_number}
            disable={loader}
          />
        </Box>
        <Typography
          sx={{ my: 2, fontSize: "16px", color: "rgba(21, 29, 72, 1)" }}
        >
          Is your creditor an entity?{" "}
          <Checkbox
            onChange={(e) => setIsEntity(e.target.checked)}
            type="checkbox"
            name="entity"
            sx={{
              p: 0,
              m: 0,
              color: "#75151E",
              "&.Mui-checked": {
                color: "#75151E",
              },
            }}
          />
        </Typography>
        {isEntity && (
          <>
            {" "}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}
            >
              <QSInputBox
                label={"Entity Name"}
                placeholder={"Entity Name"}
                id={"firm_name"}
                type={"text"}
                value={formData?.firm_name ? formData?.firm_name : ""}
                helperText={error?.firm_name}
                setValue={(e) => handleChange(e)}
                disable={loader}
              />

              <Box
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                <FormLabel
                  sx={{
                    textAlign: "left",
                    fontSize: "14px",
                    mb: 1,
                  }}
                  htmlFor={"demo-simple-select"}
                >
                  Entity Type
                </FormLabel>
                <Select
                  placeholder="Choose from the dropdown"
                  sx={{
                    textAlign: "start",
                    boxShadow: "none",
                    borderRadius: 2,
                  }}
                  id="demo-simple-select"
                  size="small"
                  value={
                    formData?.party_nature_type
                      ? formData?.party_nature_type
                      : ""
                  }
                  required
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem dense value="Proprietorship">
                    Proprietorship
                  </MenuItem>
                  <MenuItem dense value="Partnership">
                    Partnership
                  </MenuItem>{" "}
                  <MenuItem dense value="Private_Ltd">
                    Private Limited
                  </MenuItem>
                  <MenuItem dense value="Ltd">
                    Limited
                  </MenuItem>
                  <MenuItem dense value="LLP/PRL/PULD">
                    Limited Liability Partnership
                  </MenuItem>
                </Select>
                <span style={{ color: "#d32f2f", fontSize: "12px" }}>
                  {error?.party_nature_type}
                </span>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 3,
                width: "50%",
              }}
            >
              {/* <QSInputBox
            label={"Person of Contact"}
            placeholder={"Person of Contact"}
            id={"nominee"}
            required
            type={"text"}
            value={formData.nominee}
            helperText={error?.nominee}
            setValue={(e) => handleChange(e)}
            disable={loader}
          /> */}
              <QSInputBox
                label={"GSTIN (If available)"}
                helperText={gstError || error?.gstin}
                placeholder={"GSTIN No"}
                maxLength={{
                  style: { textTransform: "uppercase" },
                  maxLength: GSTIN_LENGTH,
                }}
                apiLoader={gstLoader}
                error={gstError || error?.gstin}
                id={"gstin"}
                type={"text"}
                value={formData?.gstin && formData.gstin}
                setValue={(e) => handleChange(e)}
                disable={loader}
              />
            </Box>{" "}
          </>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <QSButton
            onClick={() => {
              submitOppositParty(formData);
            }}
            buttonText="Submit"
            disabled={loader}
          />
        </Box>
      </Box>
    </Grid>
  );
};
export default DebtorEntity;
