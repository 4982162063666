import Moment from "moment";

var options = {
  hour: "numeric",
  hour12: false,
  minute: "numeric",
  //   weekday: "long",
  //   year: "numeric",
  month: "short",
  day: "numeric",
  second: "numeric",
};

var options2 = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

var timeOptions = {
  hour: "hour",
  minute: "minute",
  second: "second",
};

export const rawDate = () => {
  const date = new Date();
  return date;
};
export const dateToday = () => {
  let dateToday = new Date();
  let date = dateToday.getDate();
  let month = dateToday.getMonth() + 1;
  let year = dateToday.getFullYear();
  let separator = "-";
  let txnDate = `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
  return txnDate;
};

export const myDate = (dateObj) => {
  return new Date(dateObj).toLocaleDateString("en-US", options);
};

export const myDate2 = (dateObj) => {
  return new Date(dateObj)
    .toLocaleDateString("en-US", options2)
    .replace("/", "_")
    .replace(" ", "_")
    .replace(",", "_");
};

export const myDate7 = (dateObj) => {
  return new Date(dateObj).toLocaleDateString("en-US", options2);
};

export const myDate4 = (dateObj) => {
  if (dateObj) {
    const d = new Date(dateObj);
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  } else {
    return "";
  }
};

export const myDateDDMMyy = (dateObj) => {
  if (dateObj) {
    const d = new Date(dateObj);
    return d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
  } else {
    return "";
  }
};

export const myDate3 = (dateObj) => {
  return new Date(new Date(dateObj));
};

export const myDate5 = (dateObj) => {
  const d = new Date(dateObj);
  return d.getDate() + "/" + (d.getMonth() + 1);
};

export const changeDateFormatTo = (date) => {
  const [yy, mm, dd] = date.split(/-/g);
  return `${dd}/${mm}/${yy}`;
};

export const dateToFrom = (date = []) => {
  if (date.length > 0) {
    let d = [];
    for (let i = 0; i < date.length; i++) {
      d[i] = new Date(date[0]);
    }
    return (
      d[0].getFullYear() +
      "-" +
      (d[0].getMonth() + 1) +
      "-" +
      d[0].getDate() +
      "_" +
      d[0].getFullYear() +
      "-" +
      (d[0].getMonth() + 1) +
      "-" +
      d[0].getDate()
    );
  } else {
    return "";
  }
};
export const datemonthYear = (dateObj) => {
  return new Date(dateObj)
    .toLocaleDateString("en-US", options2)
    .replace("/", " ")
    .replace(" ", " ")
    .replace(",", " ");
};

export const ddmmyyyy = (dateObj) => {
  const d = new Date(dateObj);
  return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
};
export const time = (dateObj) => {
  const d = new Date(dateObj);
  return d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
};
export const yyyymdd = (dateObj) => {
  const d = new Date(dateObj);
  return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
};

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export const dateDifference = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const yyyymmdd = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const customDateFormat = (dObj) => {
  const d = new Date(dObj);
  // let month = d.getMonth() + 1;
  // let day = d.getDate();
  // let year = d.getFullYear();
  // if (month.length < 2) month = "0" + month;
  // if (day.length < 2) day = "0" + day;

  const defaultFormat = localStorage.getItem("defaultDateFormat");
  let stringDate = "";
  if (defaultFormat) {
    stringDate = Moment(d).format(defaultFormat);
  } else {
    stringDate = Moment(d).format("DD/MM/YYYY");
  }
  return stringDate;
};

export const string2Date = (dateString) => {
  // const d = new Date(dateObj);
  // let month = d.getMonth() + 1;
  // let day = d.getDate();
  // let year = d.getFullYear();
  // if (month.length < 2) month = "0" + month;
  // if (day.length < 2) day = "0" + day;

  const defaultFormat = localStorage.getItem("defaultDateFormat");

  if (defaultFormat) {
    return Moment(dateString, defaultFormat);
  } else {
    return Moment(dateString, "DD/MM/YYYY");
  }
};

export const customDate = (dateString, type) => {
  return dateString
    ? new Date(dateString)?.toLocaleDateString("en-IN", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    : "-";
};

export const convertDataInYYYY = (date) => {
  let d = date.split("/").reverse().join("-");
  return d;
};
