import { Slider } from "@mui/material";
import { currency } from "../../../common/Constants";
const QSSliderBar = ({
  used_amount,
  total_amount,
  marginbottom,
  showToolTip = false,
  showThumb = false,
}) => {
  const value = (used_amount / total_amount) * 100;
  return (
    <Slider
      value={value ? value : 0}
      disableSwap="false"
      valueLabelDisplay={showToolTip ? "on" : "off"}
      valueLabelFormat={`${currency(used_amount)} Available`}
      focused="false"
      sx={{
        height: 8,
        my: 1,
        mb: marginbottom,
        color: "#B9B9B9",
        cursor: "default",
        pointerEvents: "none",
        "& .MuiSlider-thumb": {
          borderRadius: "15px",
          border: "5px solid white",
          color: "#17C0AF",
        },
        "& .MuiSlider-track": {
          //   boxShadow: "0px 4px 0px #17C0AF",
          color: "#17C0AF",
        },
        ".MuiSlider-valueLabel": {
          borderRadius: "43px",
          color: "#003B41",
          background: "#fff",
          boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
          p: "12px",
          fontWeight: 600,
        },
      }}
    />
  );
};
export default QSSliderBar;
