import { Box, FormLabel, MenuItem, Select, Typography } from "@mui/material";
import { NdaText16 } from "../../../../litigation-financing/lib/constants/style";
import downloadIcon from "../../../assets/dashboard/download.svg";
import uploadIcon from "../../../assets/create-case/upload.svg";
import crossIcon from "../../../../litigation-financing/assets/create-case/cross.svg";

import { useState } from "react";
import { useNavigate } from "react-router";
import QSButton from "../../common/qs-button";
import { BASE_URL } from "../../../../networks/ApiEndPoints";
import BusinessApiEndPoint from "../../../../networks/BusinessApiEndPoint";
import { CClient } from "../../../../litigation-financing/lib/common/common";
import { apiErrorToast, okSuccessToast } from "../../../../common/Toasts";
import Swal from "sweetalert2";
import Spinner from "../../../../common/Spinner";
import DownloadIcon from "@mui/icons-material/Download";
import { BulkInvoiceView } from "./bulk-invoice-view";
const BulkInvoices = ({ handleClose }) => {
  const [document, setDocument] = useState([]);
  const navigate = useNavigate();
  const [uploadFile, setUploadFile] = useState(false);
  const [loading, setloading] = useState(false);
  const [invoiceData, setInvoicedata] = useState({});
  const [open, setOpen] = useState(false);
  const handleImage = async (event) => {
    setUploadFile(true);
    const filePathsPromises = [];
    for (let i = 0; i < event.target.files.length; i++) {
      filePathsPromises.push(toBase64(event.target.files[i]));
    }
    const filePaths = await Promise.all(filePathsPromises);
    setDocument(filePaths);
  };

  const toBase64 = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          document_url: reader.result,
          document_name: name,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  const uploadBulkCase = async () => {
    setloading(true);
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_BULK_UPLOAD}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            file_name: document[0]?.document_name,
            excel_file: document[0]?.document_url,
          }),
        }
      );

      if (res.ok) {
        setloading(false);
        const data = await res.json();
        setInvoicedata(data);
        setOpen(true);
        // Swal.fire({
        //   icon: "success",
        //   text: "Invoices created successfully",
        //   showConfirmButton: true,
        //   confirmButtonColor: "#17C0AF",
        //   showCloseButton: true,
        // }).then(() => {
        //   handleClose();
        //   window.location.reload();
        // });
      } else {
        const data = await res.json();
        Swal.fire({
          icon: "error",
          text: data.message,
          showConfirmButton: false,
          showCloseButton: true,
        });
        setloading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = () => {
    setDocument([]);
    Swal.fire({
      icon: "success",
      title: "Invoice excel deleted successfully",
      showConfirmButton: false,
      timer: 2500,
      showCloseButton: true,
    });
  };

  const sampleExcel = async () => {
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_SAMPLE_EXCEL}`
      );
      if (res.ok) {
        const data = await res.json();
        Swal.fire({
          icon: "success",
          text: "Sample excel successfully downloaded",
          showConfirmButton: false,
          timer: 2500,
          showCloseButton: true,
        });
        window.open(data);
      } else {
        const data = await res.json();
        Swal.fire({
          icon: "error",
          text: data.message,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box>
      <Spinner loading={loading} circleBrown />
      <Box sx={{ py: 1.5, display: "flex", justifyContent: "space-between" }}>
        <Typography sx={NdaText16}>Upload Invoice</Typography>
        <Box
          className="d-flex gap-1"
          sx={{ cursor: "pointer" }}
          onClick={() => sampleExcel()}
        >
          <DownloadIcon sx={{ color: "#17C0AF" }} />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#17C0AF",
            }}
          >
            Sample Excel
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: "176px",
          position: "relative",
          my: 2,
        }}
      >
        {" "}
        <input
          type="file"
          id=""
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={(e) => {
            handleImage(e);
          }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
            left: "0px",
            top: "0px",
            zIndex: 10,
          }}
        />
        <span>
          <Box
            sx={{
              p: 2,
              background: "#14afa11a",
              borderRadius: 2,
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                height: "176px",
                width: "100%",
                border: "2px dashed #17C0AF",
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
                gap: 1,
              }}
            >
              <img
                src={uploadIcon}
                height={"28px"}
                width={"28px"}
                alt="Upload your documents"
              />
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                Upload <span style={{ color: "#17C0AF" }}>Invoice Excel</span>
              </Typography>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  color: "#676767",
                  textAlign: "center",
                }}
              >
                Supported formats: CSV, XLSX
              </Typography>
            </Box>
          </Box>
        </span>
        {/* <div className="re-upload">
          <span>
            {document[0]?.document_name && (
              <EditOutlinedIcon
                fontSize="small"
                style={{ top: 20, position: "absolute", right: 20 }}
              />
            )}
          </span>
        </div> */}
        {/* {error && (
          <Typography sx={{ color: "red", mb: 2, ml: 2, fontSize: "12px" }}>
            {error}
          </Typography>
        )} */}
      </Box>
      {document?.length > 0 && (
        <Box sx={{ mt: 6 }}>
          <Typography
            sx={{
              color: "#9E9E9E",
              fontSize: "14px",
              fontWeight: 700,
              my: 2,
              textAlign: "center",
            }}
          >
            Uploading file
          </Typography>
          {document?.map((data, index) => {
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    height: "36px",
                    borderRadius: 0.5,
                    border: data?.status
                      ? "0.5px solid #F57E77"
                      : "0.5px solid #ECECEC",
                    display: "flex",
                    mt: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {data?.document_name.substring(0, 38)}
                  </Typography>{" "}
                  <img
                    src={crossIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDelete();
                    }}
                    alt="cross"
                  />
                  {/* {data?.status === "failed" ? (
                  <img
                    src={crossIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      //   handleDocumentDelete(data?.id);
                      //   fetchData();
                    }}
                    alt="cross"
                  />
                ) : (
                  <img
                    src={deleteIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      //   handleDocumentDelete(data?.id);
                      //   fetchData();
                    }}
                    alt="delete"
                  />
                )} */}
                </Box>
                {data?.status === "failed" && (
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: 500,
                      color: "#F57E77",
                      textAlign: "left",
                    }}
                  >
                    This file format is not supported. Please delete and upload
                    another file with supported format.
                  </Typography>
                )}
              </>
            );
          })}
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mt: document.length > 0 ? 5 : 10,
        }}
      >
        <QSButton
          onClick={() => {
            uploadBulkCase();
          }}
          disabled={!document.length || loading}
        />
      </Box>
      {open && (
        <BulkInvoiceView open={open} setOpen={setOpen} data={invoiceData} />
      )}
    </Box>
  );
};
export default BulkInvoices;
