import {
  Grid,
  Typography,
  Box,
  ListItem,
  List,
  Table,
  TableBody,
  Button,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import Spinner from "../../../common/Spinner";
import { BASE_URL } from "../../../networks/ApiEndPoints";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { apiErrorToast, okSuccessToast } from "../../../common/Toasts";
import { CClient } from "../../../litigation-financing/lib/common/common";
import {
  draftTableHeader,
  draftTableRow,
} from "../../../litigation-financing/lib/constants/style";
import goodIcon from "../../assets/nbfc/GOOD.png";
import badIcon from "../../assets/nbfc/BAD.png";
import avgIcon from "../../assets/nbfc/AVG.png";
import ntcIcon from "../../assets/nbfc/NTC.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import QSCreateCase from "../create-case/create-case";
import InvoiceModal from "../dashboard/invoice/invoice-modal";
import CreditRequest from "./nbfc-credit-modal";
const CASES_PAGE_SIZE = 100;
const ClientNbfc = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCases, setTotalCases] = useState();
  const [cases, setCases] = useState([]);
  const [creditModal, setCreditModal] = useState(false);
  const inputRef = useRef(null);
  const [crossIconShow, setCrossIconShow] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [draftCaseId, setDraftCaseId] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [status, setStatus] = useState("");
  const [roleText, setRoleText] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [isOpen, setIsOPen] = useState(false);
  const [caseID, setCaseId] = useState("");
  const [id, setId] = useState("");
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState([]);
  const [invoiceType, setInvoiceType] = useState("All");
  const [open, setOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  //   const handleChange = (e) => {
  //     setCrossIconShow(true);
  //     if (e.key == "Enter") {
  //       if (e.target.value === "") {
  //         setSearchLoader(false);
  //         setCrossIconShow(false);
  //       } else {
  //         setCrossIconShow(true);
  //         setSearchLoader(true);
  //         setValue(e.target.value);
  //       }
  //     } else if (e.key == "Backspace") {
  //       if (inputRef.current.value.length <= 1) {
  //         setCrossIconShow(false);
  //         setValue("");
  //       }
  //     }
  //   };

  const handleCancelEdit = () => {
    setCrossIconShow(false);
    setSearchLoader(false);
    inputRef.current.value = "";
    setValue("");
  };
  const getEndPointWithParam = (url) => {
    if (value) {
      url += `&search=${value}`;
    }
    return url;
  };
  const fetchData = async (pageIndex) => {
    try {
      setSearchLoader(false);
      setIsLoading(true);
      let pageNumber = pageIndex > 1 ? 1 : pageIndex;
      let original_url = `${
        BusinessApiEndPoint.NBFC_INCREASE_LIMIT
      }?page=${pageNumber}&page_size=${CASES_PAGE_SIZE}&status=${"WAITING"}`;
      let url = getEndPointWithParam(original_url);
      const res = await CClient(url);
      if (res.ok) {
        setIsLoading(false);
        const data = await res.json();
        const cases = data.results.map(
          ({
            id,
            client_id,
            last_modified_at,
            merchant_name,
            credit_score,
            mobile_number,

            //   claim_value,
            //   last_modified_at,
            //   respondent,
          }) => {
            const requestDate = new Date(last_modified_at).toLocaleDateString(
              "en-GB",
              {
                day: "numeric",
                month: "long",
                year: "numeric",
              }
            );

            return {
              id: id,
              clientID: client_id,
              requestDate: requestDate,
              credit_score: credit_score,
              // claimant: claimant[0]?.firm_name,
              merchant_name: merchant_name,
              mobile_number: mobile_number,
            };
          }
        );
        setPageCount(Math.ceil(data.count / CASES_PAGE_SIZE));
        setCases(cases);
        setTotalCases(data.count);
      } else {
        console.error("All clients table failed to load");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const handleRequest = async (request, id) => {
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.NBFC_INCREASE_LIMIT}${id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: request }),
        }
      );

      if (res.ok) {
        setIsLoading(false);
        okSuccessToast("client request successful");
        fetchData();
      } else {
        setIsLoading(false);
        const data = await res.json();
        apiErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  //   const handleCaseDelete = async (id) => {
  //     try {
  //       const res = await CClient(
  //         `${BASE_URL}${BusinessApiEndPoint.QS_INVOICES}${id}/`,
  //         {
  //           method: "DELETE",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (res.ok) {
  //         setIsLoading(false);
  //         okSuccessToast("Invoice deleted successfully");
  //         fetchData();
  //       } else {
  //         setIsLoading(false);
  //         const data = await res.json();
  //         apiErrorToast("Something went wrong");
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  const isSelected = (id) => {
    return selected.indexOf(id) !== -1;
  };

  const nextPage = () => {
    setPageIndex(pageIndex + 1);
    fetchData(pageIndex);
  };

  const previousPage = () => {
    setPageIndex(pageIndex - 1);
    fetchData(pageIndex);
  };

  useEffect(() => {
    fetchData(pageIndex);
  }, [value, invoiceType]);
  return (
    <>
      <Grid sx={{ width: "100%", p: 3 }}>
        <Grid
          sx={{
            my: 2,
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 1,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid #003B4126",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: "20px",
                fontWeight: 600,
                textAlign: "left",
                my: 1,
              }}
            >
              Pending Requests
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            {/* <Box
              sx={{ display: "flex", alignItems: "center" }}
              className="d-sm-none"
            >
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Filter By Status
              </Typography>
              &nbsp;
              <FormControl sx={{ width: "150px" }}>
                <Select
                  sx={{
                    textAlign: "start",
                    boxShadow: "none",
                    height: "30px",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17C0AF",
                    },
                  }}
                  id="demo-simple-select"
                  size="small"
                  value={invoiceType}
                  onChange={(e) => setInvoiceType(e.target.value)}
                >
                  <MenuItem dense value="All">
                    All
                  </MenuItem>
                  <MenuItem dense value="Approved">
                    Approved
                  </MenuItem>
                  <MenuItem dense value="Pending">
                    Pending
                  </MenuItem>
                  <MenuItem dense value="Payment_disbursed">
                    Settled
                  </MenuItem>
                  <MenuItem dense value="Reject">
                    Denied
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                width: "483px",
                height: "48px",
                borderRadius: "10px",
                display: { xs: "none", sm: "flex" },
                gap: 2,
                alignItems: "center",
                p: "5px",
                color: "#003B41",
                border: "1px solid #003B4126",
              }}
            >
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: invoiceType === "All" && "#17C0AF33",
                }}
                onClick={() => setInvoiceType("All")}
                className={invoiceType === "All" && "shadow-sm"}
              >
                All
              </Box>
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: invoiceType === "Approved" && "#17C0AF33",
                }}
                onClick={() => setInvoiceType("Approved")}
                className={invoiceType === "Approved" && "shadow-sm"}
              >
                Approved
              </Box>
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: invoiceType === "Pending" && "#17C0AF33",
                }}
                className={invoiceType === "Pending" && "shadow-sm"}
                onClick={() => setInvoiceType("Pending")}
              >
                Pending
              </Box>
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background:
                    invoiceType === "Payment_disbursed" && "#17C0AF33",
                }}
                className={invoiceType === "Payment_disbursed" && "shadow-sm"}
                onClick={() => setInvoiceType("Payment_disbursed")}
              >
                Settled
              </Box>
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: invoiceType === "Reject" && "#17C0AF33",
                }}
                className={invoiceType === "Reject" && "shadow-sm"}
                onClick={() => setInvoiceType("Reject")}
              >
                Denied
              </Box>
            </Box> */}
          </Box>
        </Grid>
        {cases?.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Showing
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  &nbsp;
                  {`${pageIndex ? pageIndex * CASES_PAGE_SIZE + 1 : 1}-${
                    totalCases > (pageIndex + 1) * CASES_PAGE_SIZE
                      ? (pageIndex + 1) * CASES_PAGE_SIZE
                      : totalCases
                  }/${totalCases}`}
                  &nbsp;Invoices
                </span>
                {(pageIndex > 0 || totalCases === 100) && (
                  <Box sx={{ gap: 1 }}>
                    <KeyboardArrowLeftIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => pageIndex > 1 && previousPage()}
                    />
                    <KeyboardArrowRightIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => totalCases === 100 && nextPage()}
                    />
                  </Box>
                )}
              </Box>
              {/* {(invoiceType === "Reject" || invoiceType === "Pending") && (
                <Box
                  sx={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    background: "#F6F6F6",
                  }}
                  onClick={() => {
                    selected.length > 0 && handleBulkCaseDelete();
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </Box>
              )} */}
            </Box>
            <Box sx={{ width: "100%", overflowX: "auto" }}>
              <Table
                sx={{ borderRadius: 10 }}
                size="small"
                aria-label="a dense table"
                className="rounded-top"
                id="caseDetail"
              >
                <Spinner loading={isLoading} circleBrown />
                <TableHead>
                  <TableRow
                    sx={{
                      background: "rgba(246, 246, 246, 1)",
                      height: "50px",
                    }}
                  >
                    {/* <TableCell align="center" sx={{}}>
                      <Checkbox
                        checked={
                          cases.length > 0 && selected.length === cases.length
                        }
                        indeterminate={
                          selected.length > 0 && selected.length < cases.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all invoices",
                        }}
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: "#17C0AF",
                          },
                          [`&, &.MuiCheckbox-indeterminate`]: {
                            color: "#17C0AF",
                          },
                        }}
                        disableRipple
                      />
                    </TableCell> */}
                    <TableCell align="center" sx={draftTableHeader}>
                      CLIENT ID
                    </TableCell>
                    <TableCell align="center" sx={draftTableHeader}>
                      REQUEST DATE
                    </TableCell>
                    <TableCell align="center" sx={draftTableHeader}>
                      CLIENT'S NAME
                    </TableCell>
                    <TableCell align="center" sx={draftTableHeader}>
                      MOBILE NUMBER{" "}
                    </TableCell>

                    <TableCell align="center" sx={draftTableHeader}>
                      CREDIT SCORE
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    maxHeight: "200px",
                    overflow: "auto",
                  }}
                >
                  {cases?.map((row, index) => (
                    <TableRow
                      sx={{
                        height: "72.8px",
                        // "&:hover": {
                        //   background: "#F8F2F4",
                        // },
                        cursor: "pointer",
                      }}
                      role="checkbox"
                      aria-checked={isSelected(row.id)}
                      tabIndex={-1}
                      key={row.id}
                      selected={isSelected(row.id)}
                      onClick={() => {
                        setCreditModal(true);
                        setId(row.id);
                      }}
                    >
                      {/* <TableCell align="center" sx={{}}>
                        <Checkbox
                          checked={isSelected(row.id)}
                          sx={{
                            [`&, &.${checkboxClasses.checked}`]: {
                              color: "#17C0AF",
                            },
                          }}
                          disableRipple
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell> */}
                      <TableCell
                        align="center"
                        sx={draftTableRow}
                        style={{ color: "#17C0AF" }}
                      >
                        {row?.clientID}
                      </TableCell>

                      <TableCell align="center" sx={draftTableRow}>
                        &nbsp;&nbsp;&nbsp;
                        {row?.requestDate ? row?.requestDate : "-"}
                      </TableCell>
                      <TableCell align="center" sx={draftTableRow}>
                        &nbsp;&nbsp;&nbsp;{row?.merchant_name}
                      </TableCell>
                      <TableCell align="center" sx={draftTableRow}>
                        &nbsp;&nbsp;&nbsp;{row?.mobile_number}
                      </TableCell>

                      <TableCell align="center">
                        <Box>
                          {row?.credit_score === "good" && (
                            <img src={goodIcon} alt="good" />
                          )}
                          {row?.credit_score === "bad" && (
                            <img src={badIcon} alt="bad" />
                          )}
                          {row?.credit_score === "average" && (
                            <img src={avgIcon} alt="avg" />
                          )}
                          {(row?.credit_score === "ntc" ||
                            !row?.credit_score) && (
                            <img src={ntcIcon} alt="avg" />
                          )}
                        </Box>
                      </TableCell>

                      {/* <TableCell
                        align="center"
                        sx={{
                          textAlign: "center",
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                          height: "72.8px",
                          alignItems: "center",
                        }}
                      >
                        <>
                          <ClearIcon
                            sx={{ color: "red", cursor: "pointer" }}
                            onClick={() => {
                              handleRequest("REJECTED", row.id);
                            }}
                          />
                          <DoneIcon
                            sx={{ color: "green", cursor: "pointer" }}
                            onClick={() => {
                              handleRequest("APPROVED", row.id);
                            }}
                          />
                          
                        </>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../../litigation-financing/assets/common/frame.png")}
              height="368px"
              width="493px"
              class="img-fluid"
              alt="Your Invoices"
            />
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 500,
                color: "#44435C",
                pt: 2,
              }}
            >
              You don't have any Client's right now.
            </Typography>
          </Box>
        )}
        {open && <QSCreateCase setOpen={setOpen} open={open} />}
        {invoiceModalOpen && (
          <InvoiceModal setOpen={setInvoiceModalOpen} open={invoiceModalOpen} />
        )}
        {creditModal && id && (
          <CreditRequest setOpen={setCreditModal} open={creditModal} id={id} />
        )}
      </Grid>
    </>
  );
};
export default ClientNbfc;
