import { Box, Slider, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const QSCaseStep = ({ step = 1, stepLength = 2 }) => {
  const [stepPercent, setStepPercent] = useState("33");

  useEffect(() => {
    step === 1 && setStepPercent(50);
    step === 2 && setStepPercent(100);
  }, [step]);

  return (
    <Box sx={{ mt: -3 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: -1 }}>
        {" "}
        <Typography
          sx={{ fontWeight: 500, color: "#17C0AF", fontSize: "14px" }}
        >
          Step {step}
        </Typography>
        <Typography
          sx={{ fontWeight: 500, color: "#003B41", fontSize: "14px" }}
        >
          &nbsp;of {stepLength}
        </Typography>{" "}
      </Box>
      <Slider
        value={stepPercent ? stepPercent : 0}
        valueLabelDisplay="off"
        disableSwap="false"
        focused="false"
        sx={{
          height: "6px",
          my: 1,
          color: "#F8F2F4",
          cursor: "default",
          pointerEvents: "none",

          "& .MuiSlider-thumb": {
            display: "none",
          },
          "& .MuiSlider-track": {
            height: "12px",
            color: "#17C0AF",
          },
        }}
      />
    </Box>
  );
};
export default QSCaseStep;
