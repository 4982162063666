import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { lazy, Suspense, useContext, useEffect } from "react";
import AuthContext from "./store/auth-context";
import "./sentryInit";
import ReactGA from "react-ga4";
// import {
//   PRIVATE_ROUTES,
//   QUICK_SATTLE_ROUTES,
//   QUICK_SETTLE_NBFC,
// } from "./litigation-financing/routes";
import "react-phone-input-2/lib/material.css";
// import { PRIVATE_ROUTES_LAWYER } from "./litigation-financing-lawyer/route";
import NBFCDashboard from "./quick-sattle/components/quick-settle-new/nbfc-dashboard";
import mixpanel from "mixpanel-browser";
const QuickSettleLoginPage = lazy(() =>
  import("./quick-sattle/components/quick-settle-new/quick-settle-login")
);
const QSDebtorSignupOption = lazy(() =>
  import(
    "./quick-sattle/components/quick-settle-new/qs-debtor/qs-debetor-signup"
  )
);
const QSMutualFund = lazy(() =>
  import(
    "./quick-sattle/components/quick-settle-new/qs-debtor/mutual-fund-list"
  )
);

const BlogPage = lazy(() =>
  import("./quick-sattle/components/landing-page/blog/index")
);

const QuickSettleLandingPage = lazy(() =>
  import("./quick-sattle/components/landing-page/landing-page")
);
const QSNavBar = lazy(() =>
  import("./quick-sattle/components/landing-page/layout")
);
const QuickSettleMerchantLoginPage = lazy(() =>
  import(
    "./quick-sattle/components/quick-settle-new/quick-settle-merchant-login"
  )
);
const QuickSettleNbfcLoginPage = lazy(() =>
  import("./quick-sattle/components/quick-settle-new/nbfc-login")
);
const QSPreApproved = lazy(() =>
  import("./quick-sattle/components/quick-settle-new/qs-pre-approved")
);
const NBFCClients = lazy(() =>
  import("./quick-sattle/components/nbfc/nbfc-clients")
);
const NBFCMobileClientDetail = lazy(() =>
  import("./quick-sattle/components/nbfc/nbfc-client/nbfc-mobile-client-detail")
);
const QSApplicationStatus = lazy(() =>
  import("./quick-sattle/components/quick-settle-new/qs-application-status")
);
const QSHomePage = lazy(() =>
  import("./quick-sattle/components/quick-settle-new/qs-home")
);
const QSMerchantPage = lazy(() =>
  import("./quick-sattle/components/quick-settle-new/qs-merchant")
);
const QSClientDashboard = lazy(() =>
  import("./quick-sattle/components/merchant/dashboard/cllient-dashboard")
);
const QSMoClientDetail = lazy(() =>
  import("./quick-sattle/components/merchant/dashboard/mob-client-detail")
);
const QSMerchantVerifyPage = lazy(() =>
  import("./quick-sattle/components/merchant/merchant-business-verification")
);
const ErrorPage = lazy(() => import("./views/pages/ErrorPage"));
// const BlandingPage = lazy(() =>
//   import("./views/business-landingpage/BlandingPage")
// );
// const LawyerLandingPage = lazy(() => import("./views/pages/LawyerLandingPage"));
const Merchant = lazy(() =>
  import("./quick-sattle/components/landing-page/merchant")
);

const PrivacyPolicy = lazy(() =>
  import("./quick-sattle/components/landing-page/privacy-policy")
);
const TermsConditions = lazy(() =>
  import("./quick-sattle/components/landing-page/terms-conditions")
);
const ConatactUs = lazy(() =>
  import("./quick-sattle/components/landing-page/contact")
);
const BlogDetails = lazy(() =>
  import("./quick-sattle/components/landing-page/blog/blog-details")
);
const GrievanceRedressalPolicy = lazy(() =>
  import("./quick-sattle/components/landing-page/grievance-redressal-policy")
);
const QuickSettleBuyerPage = lazy(() =>
  import("./quick-sattle/components/landing-page/buyer-page")
);
const QsMerchantPage = lazy(() =>
  import("./quick-sattle/components/landing-page/qs-merchant/merchant-page.jsx")
);
const Thankyou = lazy(() =>
  import("./quick-sattle/components/landing-page/qs-thankyou/thankyou.jsx")
);
const ThankyouMerchant = lazy(() =>
  import(
    "./quick-sattle/components/landing-page/thankyou-merchant/thankyoumerchant.jsx"
  )
);
const BuisnessLoan = lazy(() =>
  import(
    "./quick-sattle/components/landing-page/business-loan/businessLoan.jsx"
  )
);
// const PaymentMethod = lazy(() =>
//   import("./views/business-dashboard/BNPL_payment/PaymentMethod")
// );
// const PayingThrough = lazy(() =>
//   import("./views/business-dashboard/BNPL_payment/PayingThrough")
// );
// const LawyersDashboard = lazy(() =>
//   import("./views/pages/services/LawyersDashboard")
// );
// const MiniDrawer = lazy(() => import("./views/components/AppSideBar"));
// const Collections = lazy(() => import("./views/pages/Collections"));
// const Settings = lazy(() => import("./views/pages/Settings"));
// const ProposalService = lazy(() =>
//   import("./views/pages/services/ProposalService")
// );
// const NdaService = lazy(() => import("./views/pages/services/NdaService"));
// const EngagementService = lazy(() =>
//   import("./views/pages/services/EngagementService")
// );
// const InvoicingService = lazy(() =>
//   import("./views/pages/services/InvoicingService")
// );
// const Payments = lazy(() => import("./views/pages/Payments"));
// const MakeInvoice = lazy(() => import("./views/pages/services/MakeInvoice"));
// const MakeProposal = lazy(() => import("./views/pages/services/MakeProposal"));
// const InvoiceTable = lazy(() => import("./views/pages/services/InvoiceTable"));
// const BankSettings = lazy(() => import("./views/pages/settings/BankSettings"));
// const MyProfile = lazy(() => import("./views/pages/settings/MyProfile"));
// const Preferences = lazy(() => import("./views/pages/settings/Preferences"));
// const ClientManagement = lazy(() =>
//   import("./views/pages/clients/ClientManagement")
// );
const BusinessMiniDrawer = lazy(() => import("./views/components/BappSideBar"));
// const BusinessDashboard = lazy(() =>
//   import("./views/business-dashboard/BusinessDashboard")
// );
// const BusinessInvoicing = lazy(() =>
//   import("./views/business-dashboard/BusinessInvoicing")
// );
// const BusinessLawyers = lazy(() =>
//   import("./views/business-dashboard/businesslawyers/BusinessLawyers")
// );
// const BusinessReports = lazy(() =>
//   import("./views/business-dashboard/BusinessReports")
// );
// const ExpandedInvoice = lazy(() =>
//   import("./views/pages/services/ExpandedInvoice")
// );
// const NewSignUpPage = lazy(() => import("./views/pages/NewSignUpPage"));
// const NewLoginPage = lazy(() => import("./views/pages/NewLoginPage"));
// const ExpandedCollections = lazy(() =>
//   import("./views/pages/collections_components/ExpandedCollections")
// );
// const BexpandedInvoice = lazy(() =>
//   import("./views/business-dashboard/component/BexpandedInvoice")
// );
// const BusinessPayments = lazy(() =>
//   import("./views/business-dashboard/businessPayments/BusinessPayments")
// );
// const BexpandedPayment = lazy(() =>
//   import("./views/business-dashboard/businessPayments/BexpandedPayment")
// );
// const BusinessSettings = lazy(() =>
//   import("./views/business-dashboard/businessSetting/BusinessSetting")
// );
// const ForgotPassword = lazy(() => import("./views/pages/ForgotPassword"));
// const BusinessLogin = lazy(() =>
//   import("./views/business-dashboard/business_login/BusinessLogin")
// );
// const BusinessSignup = lazy(() =>
//   import("./views/business-dashboard/business_login/BusinessSignup")
// );
// const BusinessforgotPswd = lazy(() =>
//   import("./views/business-dashboard/business_login/BusinessforgotPswd")
// );
// const SharedInvoice = lazy(() => import("./views/pages/SharedInvoice"));
// const EditInvoice = lazy(() => import("./views/pages/services/EditInvoice"));
// Initialize Mixpanel
function App() {
  mixpanel?.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });
  mixpanel?.track("An event");
  const authCtx = useContext(AuthContext);
  // isLawyer logged in
  const isLoggedIn = authCtx.isLoggedIn;
  // isBusiness logged in
  const isbLoggedIn = authCtx.isbLoggedIn;
  const user = authCtx.buser;
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_TRACK_ID);
  const renderLoader = () => <p>Loading...</p>;

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={renderLoader}>
          <Routes>
            {/* individual routing */}
            {/* <Route path="/" element={<LawyerLandingPage />} /> */}
            {/* <Route path="/" element={<QSNavBar />}>
              <Route path="/" element={<QuickSettleLandingPage />} />
              <Route path="/merchant" element={<Merchant />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/contact" element={<ConatactUs />} />

              <Route
                path="/grievance-redressal-policy"
                element={<GrievanceRedressalPolicy />}
              />
              <Route path="/blogs" element={<BlogPage />} />
              <Route path="/post/:id" element={<BlogDetails />} />
            </Route> */}
            <Route path="/buyer" element={<QuickSettleBuyerPage />} />
            <Route path="/merchants" element={<QsMerchantPage />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/thankyoumerchant" element={<ThankyouMerchant />} />
            <Route path="/businessloans" element={<BuisnessLoan />} />
            {/* <Route path="/login" element={<LoginPage />} /> */}
            {/* <Route path="/:businessLanding" element={<BlandingPage />} /> */}
            {/* <Route path="/:lawyer" element={<LawyerLandingPage />} /> */}

            {/* lawyer */}
            {/* <Route path="/sign-up" element={<NewSignUpPage />} />
            <Route path="/lawyer-login" element={<NewLoginPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/doc-share/:id" element={<SharedInvoice />} /> */}
            {/* 404 page not found handling */}
            {/* <Route path="*" element={<BlandingPage />}></Route> */}
            {/* <Route path="*" element={<NewLoginPage />}></Route> */}

            {/* {isLoggedIn && (
              <Route path="/dashboard" element={<MiniDrawer />}>
                {PRIVATE_ROUTES_LAWYER}
                <Route path="proposal" element={<ProposalService />} />
                <Route path="make/proposal" element={<MakeProposal />} />
                <Route path="invoices/make" element={<MakeInvoice />} />
                <Route path="edit/invoice/:id" element={<EditInvoice />} />
                <Route path="invoices" element={<InvoiceTable />} />
                <Route path="lawyer-dashboard" element={<LawyersDashboard />} />
                <Route
                  path="invoices/:invoice_number/:id"
                  element={<ExpandedInvoice />}
                />
                <Route
                  path="collections/:collection_number/:inv_id/:id"
                  element={<ExpandedCollections />}
                />
                <Route path="nda-service" element={<NdaService />} />
                <Route
                  path="engagement-service"
                  element={<EngagementService />}
                />
                <Route
                  path="invoicing-service"
                  element={<InvoicingService />}
                />
                <Route path="payments" element={<Payments />} />
                <Route
                  path="client-management"
                  element={<ClientManagement />}
                />
                <Route path="collections" element={<Collections />} />
                <Route path="settings" element={<Settings />} />
                <Route path="bank-settings" element={<BankSettings />} />
                <Route path="my-profile" element={<MyProfile />} />
                <Route path="preferences" element={<Preferences />} />
              </Route>
            )} */}

            {/* anjali: business invoice */}
            <Route path="login" element={<QuickSettleLoginPage />} />
            <Route path="signup" element={<QSDebtorSignupOption />} />
            <Route path="mutual-fund" element={<QSMutualFund />} />
            <Route
              path="merchant-login"
              element={<QuickSettleMerchantLoginPage />}
            />
            <Route path="nbfc-login" element={<QuickSettleNbfcLoginPage />} />

            {/* <Route path="business-signup" element={<BusinessSignup />} /> */}
            {/* <Route
              path="business-forgot-password"
              element={<BusinessforgotPswd />}
            /> */}
            {isbLoggedIn && (
              <>
                <Route path="pre-approved" element={<QSPreApproved />} />
                <Route path="status" element={<QSApplicationStatus />} />
                <Route path="merchant-kyc" element={<QSMerchantVerifyPage />} />
                {/* <Route path="/business" element={<BusinessMiniDrawer />}>
                  {PRIVATE_ROUTES}
                  <Route path="dashboard" element={<BusinessDashboard />} />
                  <Route path="invoices" element={<BusinessInvoicing />} />
                  <Route
                    path="invoices/:invoice_number/:id"
                    element={<BexpandedInvoice />}
                  />
                  <Route path="payments" element={<BusinessPayments />} />
                  <Route
                    path="payments/:invoice_number/:inv_id/:id"
                    element={<BexpandedPayment />}
                  />
                  <Route path="lawyers" element={<BusinessLawyers />} />
                  <Route path="reports" element={<BusinessReports />} />
                  <Route path="settings" element={<BusinessSettings />} />
                  <Route path="payment-method" element={<PaymentMethod />} />
                  <Route path="payment-through" element={<PayingThrough />} />
                </Route> */}
                <Route path="/quick-settle" element={<BusinessMiniDrawer />}>
                  {user?.user_type === "Debtor" && (
                    <Route path="home" element={<QSHomePage />} />
                  )}
                  {user?.user_type === "Merchant" && (
                    <Route path="merchant" element={<QSMerchantPage />} />
                  )}
                  {user?.user_type === "Merchant" && (
                    <Route
                      path="client-dashbord"
                      element={<QSClientDashboard />}
                    />
                  )}
                  {user?.user_type === "Merchant" && (
                    <Route
                      path="client-dashbord/:id"
                      element={<QSMoClientDetail />}
                    />
                  )}
                </Route>
                {user?.user_type === "NBFC" && (
                  <Route path="/nbfc" element={<BusinessMiniDrawer />}>
                    <Route path="dashboard" element={<NBFCDashboard />} />
                    <Route path="clients" element={<NBFCClients />} />
                    <Route
                      path="clients/:id"
                      element={<NBFCMobileClientDetail />}
                    />
                  </Route>
                )}
              </>
            )}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}
export default App;
