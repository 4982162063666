import { Box, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloseIcon from "@mui/icons-material/Close";
import QSCaseStep from "../../create-case/qs-stepper";
import {
  QSBoxText,
  QSNotSelectedCase,
  QSSelectedBox,
} from "../../../lib/constant/style";
import { NdaText16 } from "../../../../litigation-financing/lib/constants/style";
import QSButton from "../../common/qs-button";
import SingleInvoice from "./single-invoice";
import BulkInvoices from "./bulk-invoice";

const InvoiceModal = ({ open, setOpen }) => {
  const [caseSteps, setCaseStep] = useState(1);
  const [isSingle, setIsSingle] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [loader, setLoader] = useState(false);
  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "80%", lg: "824px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: { xs: "600px", md: "auto" },
    maxHeight: "90vh",
    overflowY: "scroll",
    borderRadius: 2,
    px: 4,
    pb: 4,
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Box
          className={`d-flex justify-content-between align-items-center py-4`}
          sx={{
            px: 1,
            pt: 2,
            position: "sticky",
            top: 0,
            background: "#fff",
            zIndex: 999,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontWeight: 600,
              fontSize: "18px",
              color: "#003B41",
            }}
          >
            Add Invoice
          </Typography>
          <span>
            <CloseIcon className="otp-hover" onClick={handleClose} />
          </span>
        </Box>
        <Box sx={{ mt: 3 }}>
          <QSCaseStep step={caseSteps} />
        </Box>
        {caseSteps === 1 && (
          <Box sx={{ py: 1.5 }}>
            <Typography sx={NdaText16} style={{ color: "#003B41" }}>
              Do you wish to add single or bulk invoices{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Box
                sx={isSingle ? QSSelectedBox : QSNotSelectedCase}
                onClick={() => {
                  setCaseStep(2);
                  setIsSingle(true);
                  setIsBulk(false);
                }}
              >
                <Typography sx={QSBoxText}>Single</Typography>
              </Box>
              <Box
                sx={isBulk ? QSSelectedBox : QSNotSelectedCase}
                onClick={() => {
                  setCaseStep(2);
                  setIsSingle(false);
                  setIsBulk(true);
                }}
              >
                <Typography sx={QSBoxText}>Bulk </Typography>
              </Box>
            </Box>
            {(isSingle || isBulk) && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mt: 5,
                }}
              >
                {/* <QSButton
                  onClick={() => {
                    setCaseStep(2);
                  }}
                  disabled={loader}
                /> */}
              </Box>
            )}
          </Box>
        )}
        {caseSteps === 2 && isSingle && (
          <SingleInvoice handleClose={handleClose} />
        )}
        {caseSteps === 2 && isBulk && (
          <BulkInvoices handleClose={handleClose} />
        )}
      </Box>
    </Modal>
  );
};

export default InvoiceModal;
