import {
  Autocomplete,
  Box,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const QSPhoneCodeDropdown = ({
  disable = false,
  label,
  mobileNumber,
  onBlur,
  setCode,
  value,
  isValid,
  isRequired = true,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <FormLabel
        sx={{
          textAlign: "left",
          color: "#5E6366",
          fontSize: "14px",
          mb: 1,
          color: isValid && !mobileNumber && "red",
        }}
        htmlFor={"phone"}
      >
        {label}
      </FormLabel>

      <PhoneInput
        country={"in"}
        countryCodeEditable={false}
        specialLabel={""}
        value={`${value}` + `${mobileNumber}`}
        onBlur={setCode}
        enableSearch={true}
        isValid={!isValid ? true : false}
        inputProps={{
          name: "mobile_number",
          required: isRequired,
          disabled: disable,
          enableSearchField: true,
        }}
        placeholder=""
        containerClass="phone-container-qs"
        inputClass="phone-input-qs"
        dropdownClass="flag-container"
        buttonClass="flag-button"
        dropdownStyle={{
          display: disable ? "none" : "block",
        }}
        containerStyle={{ height: "40px" }}
        inputStyle={{
          background: disable ? "#f2f4f5" : "#fff",
          color: disable ? "#969798" : "#212121",
        }}
        style={{
          fontSize: "12px",
          color: disable ? "#969798" : "#666666",
        }}
      />
      {isValid && !mobileNumber && (
        <Typography
          sx={{
            position: "static",
            fontSize: "12px",
            fontWeight: 400,
            color: "#d32f2f",
            textAlign: "left",
          }}
        >
          {isValid}
        </Typography>
      )}
    </Box>
  );
};
export default QSPhoneCodeDropdown;
