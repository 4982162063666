import { Box, Grid, Typography } from "@mui/material";
import { bold600 } from "../../../litigation-financing/lib/constants/style";
import { currency } from "../../../common/Constants";
export const MerchantUnsettled = ({ data }) => {
  return (
    <Grid
      sx={{
        p: 3,
        minHeight: "178px",
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        // width: { xs: "100%", md: "49%", lg: "20%" },
        minWidth: { xs: "100%", md: "280px" },
        borderRadius: 2.5,
        height: "auto",
        justifyContent: "space-between",
        flexGrow: 2,
      }}
      className="shadow-sm"
    >
      <Typography sx={bold600}>Unsettled Payments</Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <img
          src={
            require("../../../litigation-financing/assets/overview/money.svg")
              .default
          }
          alt="unsettled payment"
        />
        <Typography
          sx={{ fontSize: "30px", fontWeight: 600, color: "#17C0AF" }}
        >
          {" "}
          {currency(data?.payment_unsettled)}
        </Typography>
      </Box>
      <Typography sx={{ opacity: 0.6, fontSize: "14px", color: "#003B41" }}>
        This will be settled by tomorrow
      </Typography>
    </Grid>
  );
};
