import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  InputAdornment,
} from "@mui/material";

import { useEffect, useState } from "react";

import paymentIcon from "../../assets/merchants/printer.svg";
import Icon from "../../assets/merchants/idea.svg";

import CloseIcon from "@mui/icons-material/Close";

import Swal from "sweetalert2";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import { BASE_URL } from "../../../networks/ApiEndPoints";
import { CClient } from "../../../litigation-financing/lib/common/common";

const ClientPaymentNbfc = ({ open, setOpen, id }) => {
  const [amount, setAmount] = useState("");
  const [currencyFormate, setCurrencyFormate] = useState("");
  const style = {
    position: "relative",
    top: {
      xs: "64%",
      xl: "70%",
    },
    left: "50%",
    transform: "translate(-50%, 0)",
    width: { xs: "90%", lg: "624px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: { xs: "450px", md: "auto" },
    overflowY: "scroll",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    px: { xs: "20px", lg: "100px" },
    pb: 4,
    animation: open
      ? {
          xs: "slide-up 0.5s forwards",
          xl: "slide-up-xl 0.5s forwards",
        }
      : "",
  };

  const currencyFormatter = (amt) => {
    let x = amt;
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    setCurrencyFormate(res);
  };
  const handleRequest = async () => {
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.NBFC_INCREASE_LIMIT}${id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: "NBFC APPROVED", amount: amount }),
        }
      );

      if (res.ok) {
        const data = await res.json();
        Swal.fire({
          icon: "success",
          title: "APPROVED",
          confirmButtonText: "Done",
          confirmButtonColor: "#17C0AF",
          reverseButtons: true,
        }).then((result) => {
          window.location.reload();
        });
      } else {
        const data = await res.json();
        Swal.fire({
          icon: "error",
          text: "data.message",
          confirmButtonColor: "#17C0AF",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    amount && currencyFormatter(amount);
    amount === 0 && setCurrencyFormate("");
  }, [amount]);
  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              pt: "30px",
              justifyContent: "space-between",
            }}
          >
            <img
              src={paymentIcon}
              alt="payment-icon"
              style={{ marginTop: "30px" }}
            />
            <span>
              <CloseIcon className="otp-hover" onClick={handleCloseModal} />
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              mt: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                fontWeight: 600,
                fontSize: "28px",
                color: "#003B41",
              }}
            >
              Enter Credit Limit
            </Typography>

            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: 2,
                },
                "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
                  m: 0,
                },
              }}
              style={{
                // marginTop: "0.5rem",
                marginBottom: "3rem",
                background: "#FFFFFF",
                borderRadius: "8px",
              }}
              // InputLabelProps={
              //   formData.due_amount && { shrink: formData.due_amount }
              // }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon size={24} sx={{ color: "#003B41" }} />
                  </InputAdornment>
                ),
              }}
              name="due_amount"
              value={currencyFormate ? currencyFormate : ""}
              onChange={(e) =>
                setAmount(Number(e?.target?.value.split(",").join("")))
              }
              type="text"
              size="small"
              // helperText={error.due_amount}
              placeholder="0"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                height: "50px",
                width: "100%",
                background: "#eaf6fd",
                fontSize: "14px",
                mt: "-20px",
                mb: "10px",
                borderRadius: "8px",
                fontWeight: 600,
                color: "#24ABF4",
              }}
            >
              <img src={Icon} alt="idea-icon" />
              This amount will be total limit of the credit line.
            </Box>
            <Button
              sx={{
                height: "50px",
                width: "100%",
                background: "#17C0AF",
                fontSize: "14px",
                borderRadius: "8px",
                fontWeight: 600,
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#17C0AF",
                  color: "#fff",
                },
              }}
              onClick={() => handleRequest()}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default ClientPaymentNbfc;
