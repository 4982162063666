import {
  Box,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import {
  QSdraftTableHeader,
  draftTableHeader,
  draftTableRow,
} from "../../../../litigation-financing/lib/constants/style";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import QSButton from "../../common/qs-button";
import Swal from "sweetalert2";
import { CClient } from "../../../../litigation-financing/lib/common/common";
import { BASE_URL } from "../../../../networks/ApiEndPoints";
import BusinessApiEndPoint from "../../../../networks/BusinessApiEndPoint";
import Spinner from "../../../../common/Spinner";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
export const BulkInvoiceView = ({ data, open, setOpen }) => {
  const [loading, setloading] = useState(false);
  const [edit, setEdit] = useState({
    isEdit: false,
    id: "",
  });
  const [showType, setShowType] = useState("total_inv_list");
  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "80%", lg: "1300px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: { xs: "600px", md: "auto" },
    overflowY: "scroll",
    maxHeight: "90vh",
    borderRadius: 2,
    px: 4,
    pb: 4,
  };
  const uploadBulkCase = async () => {
    setloading(true);
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_UPLOAD_EDIT_BULK_INVOICE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inv_data_list: data.correct_inv_list,
          }),
        }
      );

      if (res.ok) {
        setloading(false);
        const data = await res.json();
        Swal.fire({
          icon: "success",
          text: "Invoice created successfully",
          showConfirmButton: true,
          confirmButtonColor: "#17C0AF",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      } else {
        const data = await res.json();
        Swal.fire({
          icon: "error",
          text: data.message,
          showConfirmButton: false,
          showCloseButton: true,
        });
        setloading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Spinner loading={loading} circleBrown />

        <Box
          className={`d-flex justify-content-between align-items-center py-4`}
          sx={{
            px: 1,
            position: "sticky",
            top: 2,
            background: "#fff",
            zIndex: 999,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontWeight: 600,
              fontSize: "18px",
              color: "#003B41",
            }}
          >
            <KeyboardBackspaceRoundedIcon
              sx={{
                "&:hover": {
                  color: "#d32f2f",
                  cursor: "pointer",
                },
              }}
              onClick={() => setOpen(false)}
            />
            &nbsp; Upload Invoice
          </Typography>
          <span>
            <CloseIcon className="otp-hover" onClick={() => setOpen(false)} />
          </span>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "76px",
              flexDirection: "column",
              borderRadius: 2.5,
              gap: 1,
              "&:hover": {
                background: "#17C0AF1A",
                cursor: "pointer",
              },
              background: showType === "total_inv_list" && "#17C0AF1A",
            }}
            onClick={() => {
              setShowType("total_inv_list");
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: "#003B41",
              }}
            >
              Total Entries
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#003B41",
              }}
            >
              {data.total_inv_list.length}
            </Typography>
          </Box>
          <Box sx={{ height: "76px", border: "1px dashed #9F9FABB2" }}></Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "76px",
              flexDirection: "column",
              borderRadius: 2.5,
              gap: 1,
              "&:hover": {
                background: "#17C0AF1A",
                cursor: "pointer",
              },
              background: showType === "correct_inv_list" && "#17C0AF1A",
            }}
            onClick={() => {
              setShowType("correct_inv_list");
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: "#003B41",
              }}
            >
              Entries Added
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#003B41",
              }}
            >
              {data.correct_inv_list.length}
            </Typography>
          </Box>

          <Box sx={{ height: "76px", border: "1px dashed #9F9FABB2" }}></Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "76px",
              flexDirection: "column",
              borderRadius: 2.5,
              gap: 1,
              "&:hover": {
                background: "#17C0AF1A",
                cursor: "pointer",
              },
              background: showType === "error_inv_list" && "#17C0AF1A",
            }}
            onClick={() => {
              setShowType("error_inv_list");
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: "#003B41",
              }}
            >
              Entries Failed{" "}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#D92B3C",
              }}
            >
              {data.error_inv_list.length}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            my: 4,
            maxHeight: "400px",
            overflow: "hidden",
          }}
        >
          <TableContainer
            sx={{
              maxHeight: "548px",
              minHeight: "400px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: 7,
              },
              "&::-webkit-scrollbar-track": {
                background: "#F6F6F6",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#003B41",
              },
            }}
            className="rounded-top"
          >
            <Table
              sx={{ borderRadius: 10 }}
              aria-label="sticky table"
              className="rounded-top"
              stickyHeader
            >
              <TableHead className="rounded-top">
                <TableRow
                  sx={{
                    background: "#F6F6F6",
                    height: "50px",
                  }}
                  className="rounded-top"
                >
                  <TableCell align="center" sx={QSdraftTableHeader}>
                    INVOICE
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={QSdraftTableHeader}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    ISSUE DATE
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={QSdraftTableHeader}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    PARTY NAME
                  </TableCell>
                  <TableCell align="center" sx={QSdraftTableHeader}>
                    GSTIN/PAN{" "}
                  </TableCell>
                  <TableCell align="center" sx={QSdraftTableHeader}>
                    DUE DATE
                  </TableCell>

                  <TableCell align="center" sx={QSdraftTableHeader}>
                    AMOUNT
                  </TableCell>
                  <TableCell align="center" sx={QSdraftTableHeader}>
                    EMAIL
                  </TableCell>
                  <TableCell align="center" sx={QSdraftTableHeader}>
                    PHONE
                  </TableCell>
                  {/* <TableCell align="center" sx={QSdraftTableHeader}>
                    ACTION
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  maxHeight: "300px",
                  overflow: "hidden",
                }}
              >
                {data[showType]?.map((row, index) => (
                  <TableRow
                    sx={{
                      height: "64px",
                      background: row?.error ? "#D92B3C1A" : "#14AFA11A",
                      position: "relative",
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={draftTableRow}
                      style={{ color: row?.error ? "#D92B3C" : "#17C0AF" }}
                    >
                      {row?.invoice_id}
                      {row?.error?.invoice_id ? (
                        <Tooltip
                          arrow
                          placement="top"
                          title={row?.error?.invoice_id[0]}
                        >
                          <IconButton>
                            <ErrorOutlineIcon
                              sx={{
                                borderRadius: "50%",
                                color: "#D92B3C",
                                position: "absolute",
                              }}
                              className="shadow"
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell align="center" sx={draftTableRow}>
                      {row?.invoice_date
                        ? new Date(row?.invoice_date).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "short",
                              year: "2-digit",
                            }
                          )
                        : "-"}
                      {row?.error?.invoice_date ? (
                        <Tooltip
                          arrow
                          placement="top"
                          title={row?.error?.invoice_date[0]}
                        >
                          <IconButton>
                            <ErrorOutlineIcon
                              sx={{
                                borderRadius: "50%",
                                color: "#D92B3C",
                                position: "absolute",
                              }}
                              className="shadow"
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="center" sx={draftTableRow}>
                      {row?.firm_name?.length < 15
                        ? row?.firm_name
                        : row?.firm_name.substring(0, 14) + "..."}
                      {row?.error?.firm_name ? (
                        <Tooltip
                          arrow
                          placement="top"
                          title={row?.error?.firm_name[0]}
                        >
                          <IconButton>
                            <ErrorOutlineIcon
                              sx={{
                                borderRadius: "50%",
                                color: "#D92B3C",
                                position: "absolute",
                              }}
                              className="shadow"
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="center" sx={draftTableRow}>
                      {row?.pan_gst ? row?.pan_gst : "-"}&nbsp;
                      {row?.error?.pan_gst ? (
                        <Tooltip
                          arrow
                          placement="top"
                          title={row?.error?.pan_gst[0]}
                        >
                          <IconButton>
                            <ErrorOutlineIcon
                              sx={{
                                borderRadius: "50%",
                                color: "#D92B3C",
                                position: "absolute",
                              }}
                              className="shadow"
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="center" sx={draftTableRow}>
                      {row?.due_date
                        ? new Date(row?.due_date).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "short",
                            year: "2-digit",
                          })
                        : "-"}
                      {row?.error?.due_date ? (
                        <Tooltip
                          arrow
                          placement="top"
                          title={row?.error?.due_date[0]}
                        >
                          <IconButton>
                            <ErrorOutlineIcon
                              sx={{
                                borderRadius: "50%",
                                color: "#D92B3C",
                                position: "absolute",
                              }}
                              className="shadow"
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        sx={{
                          color: "#10B981",
                          fontSize: "20px",
                          fontWeight: 500,
                        }}
                      >
                        {row?.invoice_amt
                          ? row.invoice_amt?.toLocaleString("en-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                          : "-"}
                        {row?.error?.invoice_amt ? (
                          <Tooltip
                            arrow
                            placement="top"
                            title={row?.error?.invoice_amt[0]}
                          >
                            <IconButton>
                              <ErrorOutlineIcon
                                sx={{
                                  borderRadius: "50%",
                                  color: "#D92B3C",
                                  position: "absolute",
                                }}
                                className="shadow"
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.email ? row.email : "-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row?.mobile_number
                        ? "+" +
                          row?.phone_country_code +
                          " " +
                          row.mobile_number
                        : "-"}
                      {row?.error?.mobile_number ? (
                        <Tooltip
                          arrow
                          placement="top"
                          title={row?.error?.mobile_number[0]}
                        >
                          <IconButton>
                            <ErrorOutlineIcon
                              sx={{
                                borderRadius: "50%",
                                color: "#D92B3C",
                                position: "absolute",
                              }}
                              className="shadow"
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    {/* <TableCell align="center">
                      <EditRoundedIcon
                        sx={{
                          fontSize: "18px",
                          "&:hover": {
                            color: "green",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          setEdit({
                            isEdit: true,
                            id: row.id,
                          });
                        }}
                      />
                      <SaveAsRoundedIcon
                        sx={{
                          fontSize: "18px",
                          "&:hover": {
                            color: "green",
                            cursor: "pointer",
                          },
                        }}
                      />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <QSButton
            onClick={() => {
              uploadBulkCase();
            }}
            disabled={!data?.correct_inv_list?.length}
          />
        </Box>
      </Box>
    </Modal>
  );
};
