export const Heading_Text = {
  color: "rgba(0, 0, 0, 1)",
  fontSize: "30px",
  fontWeight: 600,
};

export const Subtitle_Text = {
  color: "rgba(103, 103, 103, 1)",
  fontSize: "16px",
  fontWeight: 500,
};

export const QSSelectedBox = {
  width: "46%",
  height: "50px",
  background: "#14AFA11A",
  border: "1px solid #17C0AF",
  borderRadius: 2.5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

export const QSNotSelectedCase = {
  width: "46%",
  height: "50px",
  border: "1px solid #17C0AF",
  borderRadius: 2.5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    background: "#14AFA11A",
  },
};

export const QSBoxText = {
  fontSize: "14px",
  fontWeight: 700,
  color: "#17C0AF",
};
