import { Grid, Typography } from "@mui/material";
import { bold600 } from "../../../litigation-financing/lib/constants/style";
import { useState } from "react";
import { AddClientModal } from "./merchant-invite-client";

export const MerchantAddClient = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Grid
        sx={{
          p: 3,
          minHeight: "178px",
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          // width: { xs: "100%", sm: "49%", lg: "20%" },
          minWidth: { xs: "100%", md: "280px" },
          borderRadius: 2.5,
          justifyContent: "space-between",
          position: "relative",
          alignItems: "center",
          height: "auto",
          cursor: "pointer",
          flexGrow: 1,
        }}
        className="shadow-sm"
        onClick={() => setOpen(true)}
      >
        <img
          src={require("../../../assets/images/referral.svg").default}
          alt="add"
        />
        <Typography sx={bold600}>Invite Clients</Typography>
        <Typography
          sx={{
            opacity: 0.6,
            fontSize: "14px",
            color: "#003B41",
            textAlign: "center",
          }}
        >
          Invite your Clients to collect payments
        </Typography>
      </Grid>
      {open && <AddClientModal open={open} setOpen={setOpen} />}
    </>
  );
};
