import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  QSdraftTableHeader,
  draftTableRow,
} from "../../../litigation-financing/lib/constants/style";
import { useState } from "react";
import { currency } from "../../../common/Constants";

export const NBFCModalClientDetails = ({ data, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        mt: 2,
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {value === "Clients Details" && (
        <Box
          sx={{
            maxHeight: "30vh",
            minHeight: "34vh",
            width: "100%",
            textAlign: "left",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#F6F6F6",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#003B41",
            },
          }}
        >
          <Box
            sx={{
              py: 2,
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography
              sx={{
                width: "186px",
                fontSize: "16px",
                color: "#17C0AF",

                fontWeight: 400,
                opacity: 0.6,
              }}
            >
              Total Approved
            </Typography>
            <Typography
              sx={{
                maxWidth: { xs: "150px", sm: "220px" },

                fontSize: "16px",

                wordWrap: "break-word",
                color: "#17C0AF",
                fontWeight: 400,
                opacity: 0.9,
              }}
            >
              {data?.basic_detail[0]?.total_approved
                ? currency(data?.basic_detail[0]?.total_approved)
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              py: 2,
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography
              sx={{
                width: "186px",
                fontSize: "16px",
                color: "#003B41",

                fontWeight: 400,
                opacity: 0.6,
              }}
            >
              First Name
            </Typography>
            <Typography
              sx={{
                maxWidth: { xs: "150px", sm: "220px" },

                fontSize: "16px",

                wordWrap: "break-word",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.9,
              }}
            >
              {data?.client_details[0]?.first_name
                ? data?.client_details[0]?.first_name
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              py: 2,
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography
              sx={{
                width: "186px",
                fontSize: "16px",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.6,
              }}
            >
              Last Name
            </Typography>
            <Typography
              sx={{
                maxWidth: { xs: "150px", sm: "220px" },

                fontSize: "16px",

                wordWrap: "break-word",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.9,
              }}
            >
              {data?.client_details[0]?.last_name
                ? data?.client_details[0]?.last_name
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              py: 2,
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography
              sx={{
                width: "186px",
                fontSize: "16px",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.6,
              }}
            >
              Date of Birth
            </Typography>
            <Typography
              sx={{
                maxWidth: { xs: "150px", sm: "220px" },

                fontSize: "16px",

                wordWrap: "break-word",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.9,
              }}
            >
              {data?.client_details[0]?.user_pan[0]?.dob
                ? new Date(
                    data?.client_details[0]?.user_pan[0]?.dob
                  ).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "2-digit",
                  })
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              py: 2,
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography
              sx={{
                width: "186px",
                fontSize: "16px",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.6,
              }}
            >
              Pan
            </Typography>
            <Typography
              sx={{
                maxWidth: { xs: "150px", sm: "220px" },

                fontSize: "16px",

                wordWrap: "break-word",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.9,
              }}
            >
              {data?.client_details[0]?.user_pan[0]?.pan_card_number
                ? data?.client_details[0]?.user_pan[0]?.pan_card_number
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              py: 2,
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography
              sx={{
                width: "186px",
                fontSize: "16px",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.6,
              }}
            >
              Email
            </Typography>
            <Typography
              sx={{
                maxWidth: { xs: "150px", sm: "220px" },
                fontSize: "16px",
                wordWrap: "break-word",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.9,
              }}
            >
              {data?.client_details[0]?.email
                ? data?.client_details[0]?.email
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              py: 2,
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography
              sx={{
                width: "186px",
                fontSize: "16px",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.6,
              }}
            >
              Phone
            </Typography>
            <Typography
              sx={{
                maxWidth: { xs: "150px", sm: "220px" },

                fontSize: "16px",

                wordWrap: "break-word",
                color: "#003B41",
                fontWeight: 400,
                opacity: 0.9,
              }}
            >
              {data?.client_details[0]?.mobile_number
                ? data?.client_details[0]?.phone_country_code +
                  " " +
                  data?.client_details[0]?.mobile_number
                : "-"}
            </Typography>
          </Box>
        </Box>
      )}
      {value === "Documents" && (
        <TableContainer
          sx={{
            maxHeight: "38vh",
            minHeight: "38vh",
            overflow: "auto",
            width: "100%",
            mt: 1,
            "&::-webkit-scrollbar": {
              width: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#F6F6F6",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#003B41",
            },
          }}
          className="rounded-top"
        >
          <Table
            sx={{ borderRadius: 10 }}
            aria-label="sticky table"
            className="rounded-top"
            stickyHeader
          >
            <TableHead className="rounded-top">
              <TableRow
                sx={{
                  background: "#F6F6F6",
                  height: "50px",
                }}
                className="rounded-top"
              >
                <TableCell
                  align="center"
                  sx={QSdraftTableHeader}
                  style={{ maxWidth: "150px" }}
                >
                  #
                </TableCell>
                <TableCell align="left" sx={QSdraftTableHeader}>
                  Document
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                maxHeight: "300px",
                overflow: "hidden",
              }}
            >
              {data?.doc_details?.map((row, index) => (
                <TableRow
                  sx={{
                    height: "64px",
                    position: "relative",
                  }}
                  key={index}
                >
                  <TableCell align="center" sx={draftTableRow}>
                    {index + 1}
                  </TableCell>
                  <TableCell align="left" sx={draftTableRow}>
                    <a
                      href={row?.documentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#17C0AF",
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;{row?.documentType}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
