import {
  Button,
  Card,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import PoppinsRegular from "../../src/assets/fonts/Poppins-Regular.ttf";
import { alpha, styled } from "@mui/material/styles";
import { teal } from "@mui/material/colors";

export const custom_theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          // overflow:scroll;
          src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsRegular}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

export const ThemeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#000000"),
  backgroundColor: "#000000",
  paddingLeft: 60,
  paddingRight: 60,
  fontFamily: "Poppins",
  margin: "5px",
  fontSize: "20px",
  paddingTop: 15,
  textTransform: "capitalize",
  // fontWeight: "bold",
  paddingBottom: 15,
  borderRadius: "25px",
  "&:hover": {
    backgroundColor: "#3d3d3d",
  },
  "&:disabled": {
    color: "black",
    backgroundColor: "#9092a3",
  },
}));
export const GreenButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#000000"),
  backgroundColor: "#00df9a",
  fontFamily: "Poppins",
  margin: "5px",
  paddingLeft: 52.5,
  paddingRight: 52.5,
  textTransform: "capitalize",
  fontSize: "20px",
  paddingTop: 15,
  // fontWeight: "bold",
  paddingBottom: 15,
  borderRadius: "25px",
  "&:hover": {
    backgroundColor: "#008c60",
  },
  "&:disabled": {
    color: "black",
    backgroundColor: "#9092a3",
  },
}));
export const GrayButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#d6d4d4",
  color: "#fff",
  paddingTop: "3px",
  paddingBottom: "3px",
  paddingLeft: "8px",
  paddingRight: "8px",
  border: "1px solid #d6d4d4",
  textTransform: "capitalize",
  "&:disabled": {
    color: "black",
    backgroundColor: "#9092a3",
  },
  "&:hover": {
    color: "#fff",
    backgroundColor: "#c6c7c7",
    border: "1px solid #c6c7c7",
  },
}));
export const DarkGreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#1e845a",
  color: "#fff",
  paddingTop: "3px",
  paddingBottom: "3px",
  paddingLeft: "8px",
  paddingRight: "8px",
  border: "1px solid #1e845a",
  textTransform: "capitalize",
  "&:disabled": {
    color: "black",
    backgroundColor: "#9092a3",
  },
  "&:hover": {
    color: "#fff",
    backgroundColor: "#1e845a",
    border: "1px solid #1e845a",
  },
}));
export const RedButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#C0392B",
  color: "#fff",
  paddingTop: "3px",
  paddingBottom: "3px",
  paddingLeft: "8px",
  paddingRight: "8px",
  border: "1px solid #C0392B",
  textTransform: "capitalize",
  "&:disabled": {
    color: "black",
    backgroundColor: "#9092a3",
  },
  "&:hover": {
    color: "#fff",
    backgroundColor: "#C0392B",
    border: "1px solid #C0392B",
  },
}));
export const WhiteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#000",
  paddingTop: "3px",
  paddingBottom: "3px",
  paddingLeft: "8px",
  paddingRight: "8px",
  textTransform: "capitalize",
  border: "1px solid black",
  "&:disabled": {
    color: "black",
    backgroundColor: "#9092a3",
  },
  "&:hover": {
    color: "#fff",
    backgroundColor: "#1877f2",
    border: "1px solid white",
  },
}));
export const FooterButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#000000",
  "&:hover": {
    color: "#000000",
    backgroundColor: "#f9f9f9",
  },
}));
export const GreenOutline = styled(Button)(({ theme }) => ({
  color: "#1e845a",
  backgroundColor: "#fff",
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 16,
  paddingRight: 16,
  border: "1px dashed #314259",
  "&:hover": {
    backgroundColor: "#d6d6d6",
  },
}));

export const OrangeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#000000"),
  backgroundColor: "#D35400",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 8,
  paddingBottom: 8,
  borderRadius: 8,
  "&:hover": {
    backgroundColor: "#1877f2",
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(1),
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  })
);

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: "#000000",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 8,
  paddingBottom: 8,
  borderRadius: 8,
  border: "1px solid #9092a3",
  "&:hover": {
    backgroundColor: "#f4f4f4",
    color: "#000000",
  },
}));

export const BlackSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: teal[600],
    "&:hover": {
      backgroundColor: alpha(teal[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: teal[600],
  },
}));

export const MyCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 10,
  paddingBottom: 10,
  borderRadius: "18px",
}));

//anjali's buttons
export const BlueButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 10,
  padding: "2px 20px",
  color: "#fff",
  border: "1px solid",
  borderRadius: "10px",
  backgroundColor: "#34ABF4",
  borderColor: "#34ABF4",

  "&:hover": {
    backgroundColor: "#72cbff",
    borderColor: "#72cbff",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#34ABF4",
    borderColor: "#72cbff",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});
