export const INPUT_FIELDS_NAME = {
  mobile_number: "mobile_number",
  aadhar: "aadhar",
  pincode: "pincode",
  zipcode: "zipcode",
  cin: "cin",
  pan_number: "pan_number",
  gstin: "gstin",
  claim_value: "claim_value",
  email: "email",
  firm_name: "firm_name",
  address: "address",
  authorized_personnel_full_name: "authorized_personnel_full_name",
  authorized_personnel_email: "authorized_personnel_email",
  brand_name: "brand_name",
  designation: "designation",
  date_of_incorporation: "date_of_incorporation",
  nin: "nin",
  uin: "uin",
  country: "country",
  first_name: "first_name",
  last_name: "last_name",
  middle_name: "middle_name",
  case: "case",
  phone_country_code: "phone_country_code",
  registration_number: "registration_number",
  registered_jurisdiction: "registered_jurisdiction",
  is_registered_company: "is_registered_company",
  is_outside_india: "is_outside_india",
  litigation_stage: "litigation_stage",
  city: "city",
  state: "state",
};
