// PRODUCTION //
// export const BASE_URL = "https://maxapi.legalpay.in/";

// TEST
// export const BASE_URL = "https://bnpl.legalpay.in/";

// LOCAL //

// export const BASE_URL = "http://192.168.1.92:8000/";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const ApiEndpoints = {
  // aayush
  AUTH: "lawfirm/v1/api/user-authenticate/",
  OTP_VERIFICATION: "lawfirm/v1/api/otp-verify/",
  OTP_RESEND: "lawfirm/v1/api/resend-otp/",
  REGISTER_USER: "lawfirm/v1/api/user-signup/",
  GST_VALIDATE: "lawfirm/v1/validate/GST",
  GET_USER: "lawfirm/v1/api/user-details/",
  PASS_VERIFY: "lawfirm/v1/api/password-verify/",
  OTP_LOGIN: "lawfirm/v1/api/otp-login/",
  CHANGE_PASS: "lawfirm/v1/api/change-password/",
  KYC_PAN: "lawfirm/v1/validate/files/PAN/",
  KYC_AADHAR: "lawfirm/v1/validate/files/Aadhar/",
  KYC_BANK: "lawfirm/v1/validate/bank_account/",
  BUSINESS_PAN: "lawfirm/v1/validate/PAN",
  CIN: "lawfirm/v1/validate/cin/",
  COI: "lawfirm/v1/validate/certificate-upload/",
  DEED: "lawfirm/v1/validate/partnership-deed/",
  GSTIN: "lawfirm/v1/validate/GST",
  REFERAL: "lawfirm/v1/api/referal-notify/",
  FINAL_KYC: "lawfirm/v1/api/user-details/",
  KYC_ADDRESS: "/lawfirm/v1/validate/address-update/",

  // cm
  CLIENTS_SEARCH: "lawfirm/v1/clients/searchByName/",
  TOP_SEARCH: "lawfirm/v1/clients/topSearched/",
  // MARK_COUNT: "lawfirm/v1/clients/markCount/",
  CLIENTS: "lawfirm/v1/clients/",
  ALL_CLIENTS_FIELDS: "lawfirm/v1/clients/allFieldList/",
  BANK_ACCOUNT: "lawfirm/v1/bank-acc/",
  INVOICE_LIST: "lawfirm/v1/invoices-prefix/",
  INVOICE_ITEMS: "lawfirm/v1/invoices-items/",
  INVOICE_FIELDS: "lawfirm/v1/invoices-fields/",
  INVOICE_FIELDS_TYPE: "lawfirm/v1/invoices-fields-types/",
  INVOICES: "lawfirm/v1/invoices/",
  INVOICES_ESIGN: "lawfirm/v1/invoices/Esign/",
  ESIGN_DOCUMENT_UPLOAD: "lawfirm/v1/invoices/uploadesign/",
  UPCOMING_PAYMENTS: "lawfirm/v1/invoices/upcoming-payments/",
  INVOICES_STATUS: "lawfirm/v1/invoicess-statuses/",
  NOTE: "lawfirm/v1/note/",
  TNC: "lawfirm/v1/tnc/",
  MY_PREF: "lawfirm/v1/mypref/",
  UPLOAD_INVOICE: "lawfirm/v1/invoice-upload/",
  // COMMON: "lawfirm/v1/common-options/",
  DATE_FORMATS: "lawfirm/v1/mypref/dateFormats/",
  COMMENTS: "lawfirm/v1/invoice-comments/",
  PAYMENTS: "lawfirm/v1/payment-received/",
  PAYMENTMODE: "lawfirm/v1/payment-mode/",
  WRITEOFF: "lawfirm/v1/invoice-writeoff/",
  DOC_SHARE: "lawfirm/v1/doc-share/",
  E_SIGN: "/lawfirm/v1/invoices/Esign/",
  REMINDER: "lawfirm/v1/invoices-reminder/",
  ACTIVITY: "lawfirm/v1/activity-app/",
  CREATE_ORDER: "lawfirm/v1/orders/",
  CREATE_EMI: "lawfirm/v1/bnpl-pay/",

  // LF-Lawyer
  LF_CASE_DETAIL: "litigation/v1/lawyer/case-details/",
  LF_ALL_CASES: "litigation/v1/lawyer/case-list/",
  LF_CASES_DOCUMENTS: "litigation/v1/lawyer/documents/",
  LF_UPLOAD_DOCUMENTS: "litigation/v1/lawyer/documents/",
  LF_OVERVIEW: "litigation/v1/dashboard/parameter/",
  LF_ANALYTIC: "litigation/v1/dashboard/analytics/",
  LF_PICHART: "litigation/v1/pi-chart/",
  LF_RACTIVITY: "litigation/v1/dashboard/?action=recent-activity",
  LF_TOP_CASES: "litigation/v1/dashboard/",
  LF_ASSOCIATE: "litigation/v1/lawyer/associate/",
  LF_ACTIVITY: "litigation/v1/lawyer/todo-activity/",
  LF_UPLOAD_ATTACHMENT: "litigation/v1/lawyer/todo-activity/upload-attachment/",
  LF_DASHBOARD_VOLUME: "litigation/v1/lawyer/lawyer-volume/",
  LF_ASSIGNMENTS: "litigation/v1/lawyer/assignments/",
  LF_DEADLINE_MISSED: "litigation/v1/lawyer/deadline-missed/",
  LF_HEARING_HISTORY: "litigation/v1/lawyer/all-hearing-history/",
  LF_ASSOCIATES_LIST: "litigation/v1/lawyer/associates-list/",
  LF_ONGOING_ACTIVITY: "litigation/v1/lawyer/ongoing-activity/",
  LF_PROGRESS_REPORT: "litigation/v1/lawyer/progress-report/",
  LF_CREATE_HEARING_HISTORY: "litigation/v1/lawyer/hearing-history/",
  LF_CASE_VOLUME: "litigation/v1/lawyer/case-list/volume/",
  LF_PORTFOLIO_MANAGER: "litigation/v1/lawyer/portfolio-manager/",
  LF_CASE_SUMMARY: "litigation/v1/lawyer/case-summary/",
  LF_CASE_STRATEGY: "litigation/v1/lawyer/case-strategy/",
};
export default ApiEndpoints;
