import { Box, Grid, Typography } from "@mui/material";
import { bold600 } from "../../../litigation-financing/lib/constants/style";
import QSSliderBar from "../common/qs-slider";
import { currency } from "../../../common/Constants";

export const MerchantPayment = ({ data }) => {
  return (
    <Grid
      sx={{
        p: 3,
        height: "178px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        textAlign: "left",
        width: { xs: "100%", lg: "34%" },
        height: "auto",
        borderRadius: 2.5,
      }}
      className="shadow-sm"
    >
      <Typography sx={bold600}>Payments</Typography>
      <QSSliderBar
        used_amount={data?.payment_collected}
        total_amount={data?.payment_pending}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#003B41",
            }}
          >
            Payments collected
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, color: "#17C0AF" }}
          >
            {" "}
            {currency(data?.payment_collected)}
          </Typography>
        </Box>
        <Box
          sx={{
            opacity: 0.6,
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: "#003B41",
            }}
          >
            Payments Due
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, color: "#003B41" }}
          >
            {" "}
            {currency(data?.payment_pending)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};
