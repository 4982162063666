import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Grid, Modal, ThemeProvider } from "@mui/material";
import { custom_theme } from "../../../theme/Theme";

import Spinner from "../../../common/Spinner";
import QSModalHeader from "./qs-modal-header";
import { Heading_Text, Subtitle_Text } from "../../lib/constant/style";
import QSButton from "./qs-button";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "532px",
  bgcolor: "background.paper",
  boxShadow: 24,
  fontFamily: "Poppins",
  height: "auto",
  overflowY: "scroll",
  borderRadius: 2,
};

const SweetAlert = ({ isKycVerified, setUserDetails }) => {
  const [open, setOpen] = React.useState(true);
  const [progess, setProgress] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <Spinner loading={progess} circleBrown />
      <ThemeProvider theme={custom_theme}>
        <Modal open={open}>
          <Box sx={style}>
            <QSModalHeader
              handleClose={() => window.location.reload()}
              hideDevider
              imgSrc={
                require("../../assets/common/common-sweetalert.svg").default
              }
            />

            {
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 4,
                  my: 2,
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={Heading_Text}>Invoice created !</Typography>
                  <Typography sx={Subtitle_Text}>
                    Your invoice has been created successfully
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    my: 1,
                  }}
                >
                  <QSButton
                    buttonText="Done"
                    buttonWidth="200px"
                    onClick={() => {
                      window.location.reload();
                    }}
                  />
                </Box>
              </Box>
            }
          </Box>
        </Modal>
      </ThemeProvider>
    </>
  );
};

export default SweetAlert;
