import {
  Box,
  FormLabel,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import QSModalHeader from "../common/qs-modal-header";
import CloseIcon from "@mui/icons-material/Close";
import QSCaseStep from "./qs-stepper";
import { NdaText16 } from "../../../litigation-financing/lib/constants/style";
import { useContext, useEffect, useState } from "react";
import {
  QSBoxText,
  QSNotSelectedCase,
  QSSelectedBox,
} from "../../lib/constant/style";
import QSUploadCaseDocument from "./upload-case-doc";
import QSButton from "../common/qs-button";
import DebtorIndividual from "./debator-individual";
import CreditorEntity from "./credator-entity";
import CreditorIndividual from "./credator-individual";
import DebtorEntity from "./debator-entity";
import AuthContext from "../../../store/auth-context";
import CreateCaseFinalStep from "./final-step";
import { BASE_URL } from "../../../networks/ApiEndPoints";
import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import { apiErrorToast } from "../../../common/Toasts";
import { CClient } from "../../../litigation-financing/lib/common/common";
import { validateCreateCase } from "../../lib/constant/validation";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const QSCreateCase = ({ setOpen, open, case_id, isDraft = false }) => {
  const [isDebator, setIsDebator] = useState(false);
  const [isCredator, setIsCredator] = useState(false);
  const [entity, setEntity] = useState(false);
  const [individual, setIndividual] = useState(false);
  const [caseSteps, setCaseStep] = useState(1);
  const [bankStatement, setBankStatement] = useState([]);
  const [Itr, setItr] = useState([]);
  const [sallaryState, setsallaryState] = useState([]);
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [corporationDoc, setCarporationDoc] = useState([]);
  const [caseId, setCaseId] = useState("");
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  const [currencyFormate, setCurrencyFormate] = useState("");
  const [dueAmount, setDueAmount] = useState();
  const [draftData, setDrafyData] = useState();
  const authCtx = useContext(AuthContext);
  const user = authCtx.buser;
  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: "600px", lg: "824px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: { xs: "600px", md: "auto" },
    overflowY: "scroll",
    borderRadius: 2,
    px: 4,
    pb: 4,
  };

  const createNewCase = async () => {
    const formData = {
      creator_type: isDebator ? "DEBTOR" : "CREDITOR",
      bank_statement: bankStatement[0]?.document_url,
      itr: Itr[0]?.document_url,
      balance_sheet: balanceSheet[0]?.document_url,
      corporation_certificate: corporationDoc[0]?.document_url,
      salary_statement: sallaryState[0]?.document_url,
      due_amount: dueAmount,
      case_id: caseId,
    };
    if (isDebator && user.user_type === "individual") {
      delete formData.balance_sheet;
      delete formData.corporation_certificate;
    }
    if (isDebator && user.user_type === "business") {
      delete formData.salary_statement;
    }
    if (isCredator) {
      delete formData.bank_statement;
      delete formData.itr;
      delete formData.balance_sheet;
      delete formData.corporation_certificate;
      delete formData.salary_statement;
    }
    try {
      setError({});

      const validationError = validateCreateCase(user.user_type, formData);
      setError(validationError);
      if (Object.keys(validationError).length) {
        return;
      }
      setLoader(true);
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_CREATE_CASE}${
          caseId && `${caseId}/`
        }`,
        {
          method: caseId ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (res.ok) {
        const data = await res.json();
        setCaseId(data.case_id);
        setCaseStep(2);
        setLoader(false);
      } else {
        const data = await res.json();
        apiErrorToast(
          Array.isArray(data?.message) ? data?.message[0] : data?.message
        );
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setCaseStep(2);
  };

  const currencyFormatter = (amt) => {
    let x = amt;
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    setCurrencyFormate(res);
  };
  const handleInputChange = (event) => {
    if (event?.target?.name === "due_amount") {
      const { name, value } = event.target;
      setDueAmount(Number(value.split(",").join("")));
    }
  };

  const getCaseData = async (case_id) => {
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_DRAFT_CASES_DETAIL}/?case_id=${case_id}`
      );
      if (res.ok) {
        const data = await res.json();
        if (data.results.length > 0) {
          setCaseId(data.results[0].case_id);
          isDraft && setCaseStep(2);
          setDrafyData(data.results[0]);
          data.results[0].creator_type === "CREDITOR" && setIsCredator(true);
          data.results[0].creator_type === "DEBTOR" && setIsDebator(true);
          data.results[0].Balance_sheet &&
            setBalanceSheet([
              {
                document_url: data.results[0].Balance_sheet,
                document_name: "Balance Sheet",
              },
            ]);
          data.results[0].Bank_Statement &&
            setBankStatement([
              {
                document_url: data.results[0].Bank_Statement,
                document_name: "Bank Statement",
              },
            ]);
          data.results[0].Certificate_of_Incorporation &&
            setCarporationDoc([
              {
                document_url: data.results[0].Certificate_of_Incorporation,
                document_name: "Certificate of Incorporation",
              },
            ]);
          data.results[0].ITR &&
            setItr([
              {
                document_url: data.results[0].ITR,
                document_name: "ITR",
              },
            ]);
          data.results[0].Salary_Statement &&
            setsallaryState([
              {
                document_url: data.results[0].Salary_Statement,
                document_name: "Sallary Statement",
              },
            ]);
          data.results[0].due_amount &&
            setDueAmount(data.results[0].due_amount);
        }
      } else {
        const data = await res.json();
        apiErrorToast(
          Array.isArray(data?.message) ? data?.message[0] : data?.message
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setError({});
  }, [sallaryState, Itr, corporationDoc, balanceSheet, balanceSheet]);
  useEffect(() => {
    dueAmount && currencyFormatter(dueAmount);
    dueAmount === 0 && setCurrencyFormate("");
  }, [dueAmount]);

  useEffect(() => {
    case_id && isDraft && getCaseData(case_id);
    return () => {};
  }, [case_id, isDraft]);

  useEffect(() => {
    caseId && caseSteps === 1 && !isDraft && getCaseData(caseId);
  }, [caseId, caseSteps]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Box
          className={`d-flex justify-content-between align-items-center py-4`}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontWeight: 600,
              fontSize: "18px",
              color: "rgba(21, 29, 72, 1)",
            }}
          >
            {caseSteps > 1 && (
              <KeyboardBackspaceIcon
                sx={{
                  cursor: "pointer",
                  mr: 1,
                  "&:hover": {
                    color: "#75151E",
                  },
                }}
                onClick={() => {
                  if (caseSteps > 1) {
                    setCaseStep(caseSteps - 1);
                  }
                }}
              />
            )}
            Create New Case
          </Typography>
          <span>
            <CloseIcon className="otp-hover" onClick={handleClose} />
          </span>
        </Box>
        <Box sx={{ mt: 2 }}>
          <QSCaseStep step={caseSteps} />
        </Box>
        {caseSteps === 1 && (
          <Box sx={{ py: 1.5 }}>
            <Typography sx={NdaText16}>
              1. Are you a Debtor or Creditor
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Box
                sx={isDebator ? QSSelectedBox : QSNotSelectedCase}
                onClick={() => {
                  setIsDebator(true);
                  setIsCredator(false);
                }}
              >
                <Typography sx={QSBoxText}>Debtor</Typography>
              </Box>
              <Box
                sx={isCredator ? QSSelectedBox : QSNotSelectedCase}
                onClick={() => {
                  setIsDebator(false);
                  setIsCredator(true);
                }}
              >
                <Typography sx={QSBoxText}>Creditor </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {/* opposite part */}
        {/* {caseSteps === 1 && (
          <Box sx={{ py: 1.5 }}>
            <Typography sx={NdaText16}>
              2. Is opposite party an Entity or Individual?
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Box
                sx={entity ? QSSelectedBox : QSNotSelectedCase}
                onClick={() => {
                  setEntity(true);
                  setIndividual(false);
                }}
              >
                <Typography sx={QSBoxText}>Entity </Typography>
              </Box>
              <Box
                sx={individual ? QSSelectedBox : QSNotSelectedCase}
                onClick={() => {
                  setEntity(false);
                  setIndividual(true);
                }}
              >
                <Typography sx={QSBoxText}>Individual </Typography>
              </Box>
            </Box>
          </Box>
        )} */}
        {caseSteps == 1 && (
          <Box sx={{ display: "flex", flexDirection: "column", px: 2, py: 1 }}>
            <FormLabel
              sx={{
                textAlign: "left",
                color: "rgba(21, 29, 72, 1)",
                fontSize: "14px",
                fontWeight: 600,
                mb: 1,
              }}
              htmlFor={"amount"}
            >
              Due Amount*
            </FormLabel>
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: 2,
                },
                "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
                  m: 0,
                },
                "&.MuiFormHelperText-root.Mui-error": {
                  color: "red",
                },
              }}
              style={{
                // marginTop: "0.5rem",
                marginBottom: "0.5rem",
                background: "#FFFFFF",
                borderRadius: "8px",
              }}
              // InputLabelProps={
              //   dueAmount && { shrink: dueAmount }
              // }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon size={24} sx={{ color: "#75151E" }} />
                  </InputAdornment>
                ),
              }}
              name="due_amount"
              value={currencyFormate ? currencyFormate : ""}
              onChange={(e) => handleInputChange(e)}
              type="text"
              size="small"
              helperText={!dueAmount && error.due_amount}
              error={!dueAmount && error.due_amount}
              placeholder="0"
            />
          </Box>
        )}
        {caseSteps === 1 && isDebator && user?.user_type === "individual" && (
          <Box sx={{ mt: 1 }}>
            <Typography sx={NdaText16}>
              Please Upload the following Documents
            </Typography>
            <Box
              sx={{
                display: "flex",
                maxWidth: "100%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <QSUploadCaseDocument
                highlightedTitle="Previous 3 years ITR"
                document={Itr}
                setDocument={setItr}
                error={error?.itr}
              />
              <QSUploadCaseDocument
                highlightedTitle="Previous 12 months Bank Stament"
                document={bankStatement}
                setDocument={setBankStatement}
                error={error?.bank_statement}
              />
              <QSUploadCaseDocument
                highlightedTitle="Previous 6 months Salary Statement"
                document={sallaryState}
                setDocument={setsallaryState}
                error={error?.salary_statement}
              />
            </Box>
          </Box>
        )}
        {caseSteps === 1 && isDebator && user?.user_type === "business" && (
          <Box sx={{ mt: 1 }}>
            <Typography sx={NdaText16}>
              Please Upload the following Documents
            </Typography>
            <Box
              sx={{
                display: "flex",
                maxWidth: "100%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                flexShrink: 1,
                gap: 2,
              }}
            >
              <QSUploadCaseDocument
                highlightedTitle="Previous 3 years ITR"
                document={Itr}
                setDocument={setItr}
                error={error?.itr}
              />
              <QSUploadCaseDocument
                highlightedTitle="Previous 12 months Bank Stament"
                document={bankStatement}
                setDocument={setBankStatement}
                error={error?.bank_statement}
              />
              <QSUploadCaseDocument
                highlightedTitle="Certificate of Incorporation"
                document={corporationDoc}
                setDocument={setCarporationDoc}
                error={error?.corporation_certificate}
              />
              <QSUploadCaseDocument
                highlightedTitle="Previous 3 years Audited Financial Statements"
                document={balanceSheet}
                setDocument={setBalanceSheet}
                error={error?.balance_sheet}
              />
            </Box>
          </Box>
        )}
        {((caseSteps === 1 && isDebator) ||
          (caseSteps === 1 && isDebator) ||
          (caseSteps === 1 && isCredator)) && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mt: 5,
            }}
          >
            <QSButton
              onClick={() => {
                createNewCase();
              }}
              disabled={loader}
            />
          </Box>
        )}
        {/* {caseSteps === 2 && isDebator && individual && (
          <DebtorIndividual caseId={caseId} setCaseStep={setCaseStep} />
        )} */}
        {caseSteps === 2 && isDebator && (
          <DebtorEntity
            caseId={caseId}
            setCaseStep={setCaseStep}
            draftData={draftData}
            setOpen={setOpen}
          />
        )}
        {/* {caseSteps === 2 && isCredator && individual && (
          <CreditorIndividual caseId={caseId} setCaseStep={setCaseStep} />
        )} */}
        {caseSteps === 2 && isCredator && (
          <CreditorEntity
            caseId={caseId}
            setCaseStep={setCaseStep}
            draftData={draftData}
            setOpen={setOpen}
          />
        )}
        {/* {caseSteps === 3 && (
          <CreateCaseFinalStep caseId={caseId} setOpen={setOpen} />
        )} */}
      </Box>
    </Modal>
  );
};
export default QSCreateCase;
