import {
  Grid,
  Typography,
  Box,
  ListItem,
  List,
  Table,
  TableBody,
  Button,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BusinessApiEndPoint from "../../../networks/BusinessApiEndPoint";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Spinner from "../../../common/Spinner";
import { BASE_URL } from "../../../networks/ApiEndPoints";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { apiErrorToast, okSuccessToast } from "../../../common/Toasts";
import { CClient } from "../../../litigation-financing/lib/common/common";
import {
  draftTableHeader,
  draftTableRow,
  styleLinkColor,
} from "../../../litigation-financing/lib/constants/style";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import QSCreateCase from "../create-case/create-case";
import { CheckBox } from "@mui/icons-material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import invoiceImg from "../../assets/invoice/invoice_ftux.svg";
import InvoiceModal from "../dashboard/invoice/invoice-modal";
const CASES_PAGE_SIZE = 100;
const InvoicesQS = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCases, setTotalCases] = useState();
  const [cases, setCases] = useState([]);
  const inputRef = useRef(null);
  const [crossIconShow, setCrossIconShow] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [showMoreOption, setShowMoreOption] = useState(true);
  const [draftCaseId, setDraftCaseId] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [status, setStatus] = useState("");
  const [roleText, setRoleText] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [isOpen, setIsOPen] = useState(false);
  const [caseID, setCaseId] = useState("");
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState([]);
  const [invoiceType, setInvoiceType] = useState("All");
  const [open, setOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const handleChange = (e) => {
    setCrossIconShow(true);
    if (e.key == "Enter") {
      if (e.target.value === "") {
        setSearchLoader(false);
        setCrossIconShow(false);
      } else {
        setCrossIconShow(true);
        setSearchLoader(true);
        setValue(e.target.value);
      }
    } else if (e.key == "Backspace") {
      if (inputRef.current.value.length <= 1) {
        setCrossIconShow(false);
        setValue("");
      }
    }
  };
  const handleCancelEdit = () => {
    setCrossIconShow(false);
    setSearchLoader(false);
    inputRef.current.value = "";
    setValue("");
  };
  const getEndPointWithParam = (url) => {
    if (value) {
      url += `&search=${value}`;
    }
    return url;
  };
  const fetchData = async (pageIndex) => {
    try {
      setSearchLoader(false);
      setIsLoading(true);
      let pageNumber = value && pageIndex > 1 ? 1 : pageIndex;
      let original_url = `${
        BusinessApiEndPoint.QS_INVOICES
      }?page=${pageNumber}&page_size=${CASES_PAGE_SIZE}&merchant_case_status=${
        invoiceType === "All" ? "" : invoiceType
      }`;
      let url = getEndPointWithParam(original_url);
      const res = await CClient(url);
      if (res.ok) {
        setIsLoading(false);
        const data = await res.json();
        const cases = data.results.map(
          ({
            id,
            invoice_date,
            invoice_id,
            //   claimant,
            due_date,
            seller_name,
            invoice_amount,
            status,
            //   claim_value,
            //   last_modified_at,
            //   respondent,
          }) => {
            const creationDate = new Date(due_date).toLocaleDateString(
              "en-GB",
              {
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              }
            );

            return {
              id: id,
              invoiceId: invoice_id,
              invoice_date: invoice_date,
              due_date: due_date,
              invoice_amount: invoice_amount,
              // claimant: claimant[0]?.firm_name,
              seller_name: seller_name,
              invoice_amount: invoice_amount,
              status: status,
            };
          }
        );
        setPageCount(Math.ceil(data.count / CASES_PAGE_SIZE));
        setCases(cases);
        setTotalCases(data.count);
      } else {
        console.error("All cases table failed to load");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const handleCaseDelete = async (id) => {
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_INVOICES}${id}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        setIsLoading(false);
        okSuccessToast("Invoice deleted successfully");
        fetchData();
      } else {
        setIsLoading(false);
        const data = await res.json();
        apiErrorToast("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBulkCaseDelete = async () => {
    try {
      const res = await CClient(
        `${BASE_URL}${BusinessApiEndPoint.QS_INVOICES}bulk_delete/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ case_id: selected }),
        }
      );

      if (res.ok) {
        setIsLoading(false);
        okSuccessToast("Invoice deleted successfully");
        setSelected([]);
        fetchData();
      } else {
        setIsLoading(false);
        const data = await res.json();
        setSelected([]);
        apiErrorToast("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const StatusFilterData = (status) => {
    setStatus(status);
  };
  const roleFilterData = (role) => {
    setRoleText(role);
  };
  const actionButton = (id) => {
    if (invoiceId) {
      if (invoiceId === id) {
        setShowMoreOption(true);
      } else {
        setShowMoreOption(false);
        setInvoiceId(id);
      }
    } else {
      setShowMoreOption(!showMoreOption);
      setInvoiceId(id);
    }
  };

  if (showMoreOption) {
    window.addEventListener("wheel", function () {
      setShowMoreOption(false);
    });
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = cases.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const isSelected = (id) => {
    return selected.indexOf(id) !== -1;
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const nextPage = () => {
    setPageIndex(pageIndex + 1);
    fetchData(pageIndex);
  };

  const previousPage = () => {
    setPageIndex(pageIndex - 1);
    fetchData(pageIndex);
  };

  useEffect(() => {
    fetchData(pageIndex);
  }, [value, invoiceType]);
  return (
    <>
      <Grid sx={{ width: "100%", p: 3 }}>
        <Grid
          sx={{
            my: 2,
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 1,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid #003B4126",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: "20px",
                fontWeight: 600,
                textAlign: "left",
                my: 1,
              }}
            >
              Track your Invoices
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              className="d-sm-none"
            >
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Filter By Status
              </Typography>
              &nbsp;
              <FormControl sx={{ width: "150px" }}>
                <Select
                  sx={{
                    textAlign: "start",
                    boxShadow: "none",
                    height: "30px",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17C0AF",
                    },
                  }}
                  id="demo-simple-select"
                  size="small"
                  value={invoiceType}
                  onChange={(e) => setInvoiceType(e.target.value)}
                >
                  <MenuItem dense value="All">
                    All
                  </MenuItem>
                  <MenuItem dense value="Approved">
                    Approved
                  </MenuItem>
                  <MenuItem dense value="Pending">
                    Pending
                  </MenuItem>
                  <MenuItem dense value="Settled">
                    Settled
                  </MenuItem>
                  <MenuItem dense value="Reject">
                    Denied
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                width: "483px",
                height: "48px",
                borderRadius: "10px",
                display: { xs: "none", sm: "flex" },
                gap: 2,
                alignItems: "center",
                p: "5px",
                color: "#003B41",
                border: "1px solid #003B4126",
              }}
            >
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: invoiceType === "All" && "#17C0AF33",
                }}
                onClick={() => setInvoiceType("All")}
                className={invoiceType === "All" && "shadow-sm"}
              >
                All
              </Box>
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: invoiceType === "Approved" && "#17C0AF33",
                }}
                onClick={() => setInvoiceType("Approved")}
                className={invoiceType === "Approved" && "shadow-sm"}
              >
                Approved
              </Box>
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: invoiceType === "Pending" && "#17C0AF33",
                }}
                className={invoiceType === "Pending" && "shadow-sm"}
                onClick={() => setInvoiceType("Pending")}
              >
                Pending
              </Box>
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: invoiceType === "Settled" && "#17C0AF33",
                }}
                className={invoiceType === "Settled" && "shadow-sm"}
                onClick={() => setInvoiceType("Settled")}
              >
                Settled
              </Box>
              <Box
                sx={{
                  width: "118px",
                  borderRadius: 2,
                  fontWeight: 600,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: invoiceType === "Reject" && "#17C0AF33",
                }}
                className={invoiceType === "Reject" && "shadow-sm"}
                onClick={() => setInvoiceType("Reject")}
              >
                Denied
              </Box>
            </Box>
          </Box>
        </Grid>
        {cases?.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Showing
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  &nbsp;
                  {`${pageIndex ? pageIndex * CASES_PAGE_SIZE + 1 : 1}-${
                    totalCases > (pageIndex + 1) * CASES_PAGE_SIZE
                      ? (pageIndex + 1) * CASES_PAGE_SIZE
                      : totalCases
                  }/${totalCases}`}
                  &nbsp;Invoices
                </span>
                {(pageIndex > 0 || totalCases === 100) && (
                  <Box sx={{ gap: 1 }}>
                    <KeyboardArrowLeftIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => pageIndex > 1 && previousPage()}
                    />
                    <KeyboardArrowRightIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => totalCases === 100 && nextPage()}
                    />
                  </Box>
                )}
              </Box>
              {(invoiceType === "Reject" || invoiceType === "Pending") && (
                <Box
                  sx={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    background: "#F6F6F6",
                  }}
                  onClick={() => {
                    selected.length > 0 && handleBulkCaseDelete();
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </Box>
              )}
            </Box>
            <Box sx={{ width: "100%", overflowX: "auto" }}>
              <Table
                sx={{ borderRadius: 10 }}
                size="small"
                aria-label="a dense table"
                className="rounded-top"
                id="caseDetail"
              >
                <Spinner loading={isLoading} circleBrown />
                <TableHead>
                  <TableRow
                    sx={{
                      background: "rgba(246, 246, 246, 1)",
                      height: "50px",
                    }}
                  >
                    <TableCell align="center" sx={{}}>
                      <Checkbox
                        checked={
                          cases.length > 0 && selected.length === cases.length
                        }
                        indeterminate={
                          selected.length > 0 && selected.length < cases.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all invoices",
                        }}
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: "#17C0AF",
                          },
                          [`&, &.MuiCheckbox-indeterminate`]: {
                            color: "#17C0AF",
                          },
                        }}
                        disableRipple
                      />
                    </TableCell>
                    <TableCell align="center" sx={draftTableHeader}>
                      INVOICE
                    </TableCell>
                    <TableCell align="center" sx={draftTableHeader}>
                      Issue Date
                    </TableCell>
                    <TableCell align="center" sx={draftTableHeader}>
                      BUYER'S NAME
                    </TableCell>
                    <TableCell align="center" sx={draftTableHeader}>
                      DUE DATE
                    </TableCell>

                    <TableCell align="center" sx={draftTableHeader}>
                      AMOUNT
                    </TableCell>
                    <TableCell align="center" sx={draftTableHeader}>
                      ACTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    maxHeight: "200px",
                    overflow: "auto",
                  }}
                >
                  {cases?.map((row, index) => (
                    <TableRow
                      sx={{
                        height: "72.8px",
                        // "&:hover": {
                        //   background: "#F8F2F4",
                        // },
                      }}
                      role="checkbox"
                      aria-checked={isSelected(row.id)}
                      tabIndex={-1}
                      key={row.id}
                      selected={isSelected(row.id)}
                    >
                      <TableCell align="center" sx={{}}>
                        <Checkbox
                          checked={isSelected(row.id)}
                          sx={{
                            [`&, &.${checkboxClasses.checked}`]: {
                              color: "#17C0AF",
                            },
                          }}
                          disableRipple
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={draftTableRow}
                        style={{ color: "#17C0AF" }}
                      >
                        {row?.invoiceId}
                      </TableCell>

                      <TableCell align="center" sx={draftTableRow}>
                        &nbsp;&nbsp;&nbsp;
                        {row?.invoice_date ? row?.invoice_date : "-"}
                      </TableCell>
                      <TableCell align="center" sx={draftTableRow}>
                        &nbsp;&nbsp;&nbsp;{row?.seller_name}
                      </TableCell>
                      <TableCell align="center" sx={draftTableRow}>
                        &nbsp;&nbsp;&nbsp;{row?.due_date}
                      </TableCell>

                      <TableCell align="center">
                        <Typography
                          sx={{
                            color: "#10B981",
                            fontSize: "20px",
                            fontWeight: 500,
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;
                          {row?.invoice_amount
                            ? row.invoice_amount?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })
                            : "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        <>
                          <Box
                            sx={{
                              position: "relative",
                            }}
                          >
                            <MoreHorizIcon
                              sx={{
                                cursor: "pointer",
                                position: "relative",
                                fontSize: "18px",
                              }}
                              onMouseOver={() => {
                                actionButton(row.id);
                              }}
                            />

                            {/* {showMoreOption && invoiceId === row.id && (
                              <List
                                sx={{
                                  width: "170px",
                                  bgcolor: "#fff",
                                  position: "absolute",
                                  zIndex: "9999",
                                  right: "50px",
                                  borderRadius: "10px",
                                  top: 10,
                                }}
                                className="shadow-sm"
                                onMouseLeave={() => {
                                  setShowMoreOption(false);
                                  setInvoiceId();
                                }}
                              >
                                <ListItem sx={{ px: 1 }}>
                                  <Typography
                                    sx={{
                                      color: "#676767",
                                      width: "100%",
                                      margin: "auto",
                                      textAlign: "center",
                                    }}
                                  >
                                    Request Payment
                                  </Typography>
                                </ListItem>
                                {(row?.status === "Pending" ||
                                  row?.status === "Reject") && (
                                  <ListItem sx={{ px: 1 }}>
                                    <Button
                                      sx={{
                                        color: "#75151E",
                                        width: "90%",
                                        margin: "auto",
                                        textAlign: "center",
                                      }}
                                      onClick={(e) => {
                                        handleCaseDelete(row.id);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </ListItem>
                                )}
                              </List>
                            )} */}
                          </Box>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </>
        ) : (
          <Box
            sx={{
               width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../../litigation-financing/assets/common/frame.png")}
              class="img-fluid" 
              height="368px"
              width="493px"
              alt="Your Invoices"
            />
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "22px" }, 
                fontWeight: 500,
                color: "#44435C",
                pt: 2,
                textAlign: "center", 
              }}
            >
              You don't have any Invoices right now.
            </Typography>
          </Box>
        )}
        {open && <QSCreateCase setOpen={setOpen} open={open} />}
        {invoiceModalOpen && (
          <InvoiceModal setOpen={setInvoiceModalOpen} open={invoiceModalOpen} />
        )}
      </Grid>
    </>
  );
};
export default InvoicesQS;
